import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import numeral from 'numeral';
import { analytics } from 'libs';
import Filter from './Filter';
import Text from './Text';

const BeaconFilter = ({ filters, isPro = false, onFilter, placeholder = 'Enter beacon score' }) => {
  const [value, setValue] = useState(filters.beacon);

  /**
   * The first render will occur before the initial filter state is ready. If
   * the filter state was ready on first render, the state sync below would
   * be unnecessary. To remove this, we would need the parent component to
   * immediately pass down the actual initial filter state.
   */
  useEffect(() => {
    setValue(filters.beacon);
  }, [filters.beacon, setValue]);

  const onDebouncedFilter = useMemo(
    () =>
      debounce((newFilters) => {
        onFilter(newFilters);
      }, 400),
    [onFilter]
  );

  const onChange = (event) => {
    event.preventDefault();

    const newValue = numeral(event.target.value).value();

    analytics.productSearch.filterDebounced({ beacon: newValue || 'cleared' });

    setValue(newValue);
    onDebouncedFilter({ beacon: newValue });
  };

  return (
    <Filter name="Beacon" isPro={isPro}>
      <Text
        isPro={isPro}
        onChange={onChange}
        placeholder={placeholder}
        testId="filter-beacon"
        value={value ? numeral(value).format() : ''}
      />
    </Filter>
  );
};

BeaconFilter.propTypes = {
  filters: PropTypes.shape({
    beacon: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }).isRequired,
  isPro: PropTypes.bool,
  onFilter: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

export default BeaconFilter;
