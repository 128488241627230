import { createSelector } from 'reselect';

/**
 * Get All Programs from state.
 * @param {Object} state
 * @return {Object}
 */
const getAllPrograms = (state) => state.programs.byId;

/**
 * Get All Programs.
 * @param {Object} state
 * @return {Array}
 */
export const getAll = createSelector([getAllPrograms], (programs) =>
  Object.keys(programs).map((programId) => programs[programId])
);

/**
 * Get All Programs sorted by name.
 * @param {Object} state
 * @return {Array}
 */
export const getAllSortedByName = createSelector([getAll], (programs) =>
  programs.sort((program1, program2) => {
    if (program1.name > program2.name) return 1;
    if (program1.name < program2.name) return -1;
    return 0;
  })
);

export default getAll;
