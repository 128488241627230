import { combineReducers } from 'redux';
import { actionTypes as filters } from './filters';

const CLEAR_RESULTS = 'lenderspotlight/lendersByProducts/CLEAR_RESULTS';
const FETCH = 'lenderspotlight/lendersByProducts/FETCH';
const FETCH_SUCCESS = 'lenderspotlight/lendersByProducts/FETCH_SUCCESS';
const FETCH_MORE_SUCCESS = 'lenderspotlight/lendersByProducts/FETCH_MORE_SUCCESS';
const FETCH_FAILED = 'lenderspotlight/lendersByProducts/FETCH_FAILED';

const INITIAL_META = {
  currentPage: 1,
  hasMore: false,
  perPage: null,
  total: null,
};

const metaReducer = (state = INITIAL_META, { type, payload }) => {
  switch (type) {
    case FETCH_SUCCESS:
    case FETCH_MORE_SUCCESS:
      return payload.meta;

    case CLEAR_RESULTS:
    case FETCH_FAILED:
      return INITIAL_META;

    default:
      return state;
  }
};

const lendersByProductsResultsReducer = (state = [], { type, payload }) => {
  switch (type) {
    case FETCH_SUCCESS:
      return [...payload.lendersByProducts];

    case FETCH_MORE_SUCCESS:
      return [...state, ...payload.lendersByProducts];

    case CLEAR_RESULTS:
    case FETCH_FAILED:
      return [];

    default:
      return state;
  }
};

const isLoadingReducer = (state = false, { type }) => {
  switch (type) {
    case FETCH:
      return true;

    case FETCH_SUCCESS:
    case FETCH_MORE_SUCCESS:
    case FETCH_FAILED:
      return false;

    default:
      return state;
  }
};

export default combineReducers({
  lendersByProductsResults: lendersByProductsResultsReducer,
  isLoading: isLoadingReducer,
  meta: metaReducer,
});

const getMeta = (headers = null) => {
  return headers === null
    ? {}
    : {
        currentPage: parseInt(headers['x-current-page'], 10),
        hasMore: headers['x-has-more'] === 'true' || false,
        perPage: parseInt(headers['x-per-page'], 10),
        total: parseInt(headers['x-total-count'], 10),
      };
};

const fetchSuccess = (response) => ({
  type: FETCH_SUCCESS,
  payload: {
    lendersByProducts: response.data,
    meta: getMeta(response.headers),
  },
});

function fetchMoreSuccess(response) {
  return {
    type: FETCH_MORE_SUCCESS,
    payload: {
      lendersByProducts: response.data,
      meta: getMeta(response.headers),
    },
  };
}

const fetchFailed = (errors) => ({ type: FETCH_FAILED, errors });

const fetch =
  (params = {}) =>
  (dispatch) => {
    dispatch({ type: FETCH });
    dispatch({ type: filters.SET_FILTER, payload: params });

    return axios
      .get('/api/lenders/products', { params })
      .then((response) => dispatch(fetchSuccess(response)))
      .catch((errors) => dispatch(fetchFailed(errors)));
  };

const fetchMore = () => {
  return (dispatch, getState) => {
    dispatch({ type: FETCH });

    const params = {
      ...getState().filters.options,
      page: getState().lendersByProducts.meta.currentPage + 1,
    };

    return axios
      .get('/api/lenders/products', { params })
      .then((response) => dispatch(fetchMoreSuccess(response)))
      .catch((errors) => dispatch(fetchFailed(errors)));
  };
};

const clearResults = () => ({ type: CLEAR_RESULTS });

export const actions = {
  clearResults,
  fetch,
  fetchMore,
};
