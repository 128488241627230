import { useEffect, useRef } from 'react';

// List of subscribers that can show/hide Intercom
let subscribers = [];

const useHideIntercom = (shouldHide) => {
  const subscriber = useRef(true);

  useEffect(() => {
    subscribers.push(subscriber);
    subscriber.current = !shouldHide;
    updateIntercomVisibility();

    return () => {
      subscribers = subscribers.filter((sub) => sub !== subscriber);
      updateIntercomVisibility();
    };
  }, [shouldHide, subscriber]);
};

const updateIntercomVisibility = () => {
  // If at least one subscriber wants to hide the launcher, hide it
  const shouldHide = subscribers.some((sub) => !sub.current);

  // Hide launcher
  document.body.classList[shouldHide ? 'add' : 'remove']('intercom-hide');

  // Hides messenger panel
  // https://developers.intercom.com/installing-intercom/docs/intercom-javascript#section-intercomhide
  const { Intercom } = window;
  if (shouldHide && Intercom) Intercom('hide');
};

export default useHideIntercom;
