import { combineReducers } from 'redux';
import { normalize } from 'normalizr';
import schema from 'state/schema';

/**
 * Action Types
 * @type {Object}
 */
export const actionTypes = {};

/**
 * Hydrate Initial State global LS object.
 * @type {Object}
 */
let initialState = {};

if (window.__INITIALSTATE__ && window.__INITIALSTATE__.lenders) {
  const data = normalize(window.__INITIALSTATE__.lenders, [schema.lender]);
  initialState = data.entities.statuses || {};
}

/**
 * ById Status Terms Reducer
 * @param {Object} state
 * @return {Object}
 */
function byIdReducer(state = initialState) {
  return state;
}

/**
 * Export Status Terms Reducer
 * @type {Object}
 */
export default combineReducers({
  byId: byIdReducer,
});
