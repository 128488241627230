import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useUser } from 'libs';

const Toggle = ({
  disabled = false,
  disabledOption = null,
  isPro = false,
  onChange,
  options,
  testId,
  variant = 'default',
  value,
}) => {
  const user = useUser();
  const disableAll = disabled || (isPro && !user.features.is_pro);

  /**
   * Handle on change event.
   * @param  {Object} event
   * @return {Void}
   */
  const handleChange = (event) => {
    const filter = event.target.getAttribute('data-value');
    onChange(filter);
  };

  /**
   * Check if the option is currently selected.
   * @param  {Mixed}  key
   * @return {Boolean}
   */
  const isSelected = (key) => {
    if (Array.isArray(value)) {
      return value.indexOf(key) > -1;
    }

    return key === value;
  };

  return (
    <div
      className={classnames(variant === 'vertical' ? 'btn-group-vertical' : 'btn-group', 'w-100')}
      role="group"
      data-testid={testId}
    >
      {options.map((option) => {
        const className = isSelected(option.key) ? 'btn-toggle' : 'btn-light';

        return (
          <button
            key={option.key}
            data-testid={testId ? `${testId}-${option.key}` : null}
            data-value={option.key}
            type="button"
            onClick={handleChange}
            className={`btn ${className}`}
            disabled={disableAll || option.key === disabledOption}
          >
            {option.value}
          </button>
        );
      })}
    </div>
  );
};

Toggle.propTypes = {
  disabled: PropTypes.bool,
  disabledOption: PropTypes.string,
  isPro: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      value: PropTypes.string,
    })
  ).isRequired,
  testId: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.bool, PropTypes.number, PropTypes.string])
    .isRequired,
  variant: PropTypes.oneOf(['default', 'vertical']),
};

export default Toggle;
