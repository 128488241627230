import React from 'react';
import PropTypes from 'prop-types';

const Hamburger = ({ onToggle }) => (
  <button
    aria-label="hamburger menu"
    className="mobile-nav__hamburger"
    data-testid="mobile-nav-hamburger"
    onClick={onToggle}
    type="button"
  >
    <span className="mobile-nav__hamburger-line" />
    <span className="mobile-nav__hamburger-line" />
    <span className="mobile-nav__hamburger-line" />
  </button>
);

Hamburger.propTypes = {
  onToggle: PropTypes.func.isRequired,
};

export default Hamburger;
