import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'components/common';
import { Text } from 'components/forms';
import { normalizePhone } from 'libs';

const ProfileForm = ({ errors, handleSubmit, isSubmitting, onSubmit, register, setValue }) => (
  <form onSubmit={handleSubmit(onSubmit)}>
    <Text
      testId="profile-name-update-field"
      error={errors.name}
      id="name"
      label="Name *"
      name="name"
      register={register('name')}
      type="text"
    />
    <Text
      testId="profile-email-update-field"
      error={errors.email}
      id="email"
      label="Email *"
      name="email"
      register={register('email')}
      type="email"
    />
    <Text
      testId="profile-phone-update-field"
      error={errors.phone_number}
      id="phone_number"
      label="Phone"
      name="phone_number"
      register={register('phone_number', {
        onChange: (event) => {
          setValue('phone_number', normalizePhone(event.target.value));
        },
      })}
      type="text"
    />
    <div className="mt-3">
      <Button disabled={isSubmitting} submit testId="profile-update-button" variant="primary-dark">
        Update
      </Button>
    </div>
  </form>
);

ProfileForm.propTypes = {
  errors: PropTypes.shape({
    name: PropTypes.shape({
      message: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
    }),
    email: PropTypes.shape({
      message: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
    }),
    phone_number: PropTypes.shape({
      message: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
    }),
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
};

export default ProfileForm;
