import { useEffect, useRef } from 'react';

/**
 * Invokes a callback when user scrolls to bottom of page.
 * @param {Function} onScrollBottom
 * @param {Number} scrollOffset
 * @returns {Ref}
 */
const useScrollBottom = (onScrollBottom, scrollOffset = 350) => {
  const elRef = useRef();

  useEffect(() => {
    const node = elRef.current;

    const onScroll = () => {
      const atBottom = node.scrollTop + node.offsetHeight >= node.scrollHeight - scrollOffset;

      if (atBottom) {
        onScrollBottom();
      }
    };
    node.addEventListener('scroll', onScroll);
    onScroll(); // Run once in case screen can hold more than one page of results

    return () => node.removeEventListener('scroll', onScroll);
  }, [onScrollBottom, scrollOffset]);

  return elRef;
};

export default useScrollBottom;
