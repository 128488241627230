import { useEffect, useLayoutEffect, useState } from 'react';
import throttle from 'lodash/throttle';
import noop from '../utils/noop';

/**
 * @param {Object} ref
 * @param {Boolean} disconnect If `true`, disconnects the observer and
 *   returns `false`. Useful when needing to disable viewport tracking during
 *   a transition period that might overwise return false positives.
 * @param {Object} options
 * @param {Number} options.delay Amount of milliseconds the target must be in
 *   view
 * @param {Number} options.threshold At what percentage of the target's
 *   bounding rectangle should it be considered in the viewport
 */
const useInViewport = (ref, disconnect = false, { delay = 500, threshold = 1 }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (disconnect) {
      setIsVisible(false);
    }
  }, [disconnect]);

  useLayoutEffect(() => {
    if (disconnect) return noop;

    const throttledSetIsVisible = throttle(setIsVisible, delay, {
      leading: false,
    });

    const { current } = ref;
    const observer = new IntersectionObserver(([entry]) => throttledSetIsVisible(entry.isIntersecting), { threshold });
    observer.observe(current);

    return () => {
      observer.disconnect();
      // In case the target is not visible for the full `delay`
      throttledSetIsVisible((currVal) => currVal);
    };
  }, [delay, disconnect, ref, threshold]);

  return isVisible;
};

export default useInViewport;
