import React from 'react';
import PropTypes from 'prop-types';
import { analytics } from 'libs';
import Dropdown from './Dropdown';
import Filter from './Filter';

const LenderTypeFilter = ({ filters, isPro = false, onFilter }) => {
  const options = [
    { key: 'all', value: 'All', filter: { lender_type: null } },
    {
      key: 'Alternate',
      value: 'Alternate (Defined Rates)',
      filter: { lender_type: 'Alternate' },
    },
    { key: 'Bank', value: 'Bank', filter: { lender_type: 'Bank' } },
    {
      key: 'Credit Union',
      value: 'Credit Union',
      filter: { lender_type: 'Credit Union' },
    },
    { key: 'Monoline', value: 'Monoline', filter: { lender_type: 'Monoline' } },
  ];

  const onChange = (selected) => {
    analytics.productSearch.filter({
      lender_type: selected.filter.lender_type,
    });
    onFilter(selected.filter);
  };

  return (
    <Filter name="Lender Type" isPro={isPro}>
      <Dropdown
        testId="filter-lender-type"
        isPro={isPro}
        onChange={onChange}
        options={options}
        value={filters.lender_type || 'all'}
      />
    </Filter>
  );
};

LenderTypeFilter.propTypes = {
  filters: PropTypes.shape({
    lender_type: PropTypes.string,
  }).isRequired,
  isPro: PropTypes.bool,
  onFilter: PropTypes.func.isRequired,
};

export default LenderTypeFilter;
