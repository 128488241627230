import React from 'react';
import PropTypes from 'prop-types';

const FormErrorAlert = ({ message = null, show = false }) => {
  if (!show) return null;
  return <div className="alert alert-danger">{message}</div>;
};

FormErrorAlert.propTypes = {
  message: PropTypes.string,
  show: PropTypes.bool,
};

export default FormErrorAlert;
