import { useEffect, useRef } from 'react';

/**
 * Inspired by https://usehooks.com/useEventListener/
 * Useful for adding event listeners to DOM nodes outside React components
 * @param {Object} element
 * @param {String} type
 * @param {Function} listener
 * @param {Object} options
 * @param {Boolean} options.useCapture
 */
const useEventListener = (element, type, listener, { useCapture = false }) => {
  const savedListener = useRef();

  // Update ref.current value if listener changes.
  // This allows our effect below to always get latest listener ...
  // ... without us needing to pass it in effect deps array ...
  // ... and potentially cause effect to re-run every render.
  useEffect(() => {
    savedListener.current = listener;
  }, [listener]);

  useEffect(() => {
    const eventListener = (e) => savedListener.current(e);
    element.addEventListener(type, eventListener, useCapture);
    return () => element.removeEventListener(type, eventListener, useCapture);
  }, [type, element, useCapture]);
};

export default useEventListener;
