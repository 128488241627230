import React from 'react';
import PropTypes from 'prop-types';

const NavbarToggler = ({ onToggle, expanded }) => {
  const active = expanded ? 'is-active' : '';

  return (
    <button
      className={`navbar-toggler hamburger hamburger--spin ${active}`}
      onClick={onToggle}
      type="button"
      aria-label="Toggle navigation"
      aria-controls="navigation"
      aria-expanded={expanded}
    >
      <span className="hamburger-box">
        <span className="hamburger-inner" />
      </span>
    </button>
  );
};

NavbarToggler.propTypes = {
  expanded: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
};

export default NavbarToggler;
