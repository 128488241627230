import PropTypes from 'prop-types';
import { combineReducers } from 'redux';
import { normalize } from 'normalizr';
import schema from 'state/schema';

const FETCH = 'lenderspotlight/lenders/FETCH';
const FETCH_BY_ID_SUCCESS = 'lenderspotlight/lenders/FETCH_BY_ID_SUCCESS';
const FETCH_FAILED = 'lenderspotlight/lenders/FETCH_FAILED';
const FETCH_SUCCESS = 'lenderspotlight/lenders/FETCH_SUCCESS';

/**
 * Action Types
 * @type {Object}
 */
export const actionTypes = {
  FETCH,
  FETCH_BY_ID_SUCCESS,
  FETCH_FAILED,
  FETCH_SUCCESS,
};

/**
 * PropTypes Validation
 * @type {Function}
 */
export const propTypes = PropTypes.shape({
  fetchById: PropTypes.func,
  fetchAll: PropTypes.func,
});

/**
 * Hydrate Initial State global LS object.
 * @type {Object}
 */
let initialState = {};

if (window.__INITIALSTATE__ && window.__INITIALSTATE__.lenders) {
  const data = normalize(window.__INITIALSTATE__.lenders, [schema.lender]);
  initialState = data.entities.lenders || {};
}

/**
 * ById Lender Reducer
 * @param {Object} state
 * @param {Object} action
 * @return {Object}
 */
function byIdReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_BY_ID_SUCCESS:
    case FETCH_SUCCESS:
      // case products.FETCH_MORE_SUCCESS:
      // case products.FETCH_SUCCESS:

      return {
        ...state,
        ...action.payload.entities.lenders,
      };

    default:
      return state;
  }
}

/**
 * IsLoading Reducer
 * @param  {Boolean} state
 * @param  {Object} action
 * @return {Boolean}
 */
function isLoadingReducer(state = false, action) {
  switch (action.type) {
    case FETCH:
      return true;

    case FETCH_BY_ID_SUCCESS:
    case FETCH_FAILED:
    case FETCH_SUCCESS:
      return false;

    default:
      return state;
  }
}

/**
 * Export Lender Reducer
 * @type {Object}
 */
export default combineReducers({
  byId: byIdReducer,
  isLoading: isLoadingReducer,
});

/**
 * Fetch BY Id Lenders Success Action
 * @param {Object} response
 * @return {Object}
 */
function fetchByIdSuccess(response) {
  const lenders = normalize(response, schema.lender);
  return { type: FETCH_SUCCESS, payload: lenders };
}

/**
 * Fetch Lenders Failed Action
 * @param {Object} errors
 * @return {Object}
 */
function fetchFailed(errors) {
  return { type: FETCH_FAILED, errors };
}

/**
 * Fetch Lenders Success Action
 * @param {Object} response
 * @return {Object}
 */
function fetchSuccess(response) {
  const lenders = normalize(response, [schema.lender]);
  return { type: FETCH_SUCCESS, payload: lenders };
}

/**
 * Fetch Lenders Thunk
 * @return {Function}
 */
export function fetchAll(params = {}) {
  return (dispatch) => {
    dispatch({ type: FETCH });
    return axios
      .get('/api/lenders', { params })
      .then((response) => dispatch(fetchSuccess(response.data)))
      .catch((error) => dispatch(fetchFailed(error)));
  };
}

/**
 * Fetch Lender By Id Thunk
 * @param  {Number} lenderId
 * @return {Function}
 */
export function fetchById(lenderId, params = {}) {
  return (dispatch) => {
    dispatch({ type: FETCH });
    return axios
      .get(`/api/lenders/${lenderId}`, { params })
      .then((response) => dispatch(fetchByIdSuccess(response.data)))
      .catch((error) => dispatch(fetchFailed(error)));
  };
}
