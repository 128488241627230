import React from 'react';
import { withRouter } from 'react-router-dom';

const accounts = ['google', 'linkedin'];

const ConnectedAccountsListView = () => (
  <ul className="list-group">
    {accounts.map((account) => (
      <li key={account} className="list-group-item">
        {account}
      </li>
    ))}
  </ul>
);

export default withRouter(ConnectedAccountsListView);
