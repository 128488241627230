import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { selectors } from 'state';
import { Button } from 'components/common';

const CurrentFilters = ({ hasFilters, onClear }) => (
  <div className="filter filter--current">
    <Button disabled={!hasFilters} onClick={onClear} testId="filter-clear" variant="primary-dark" width="block">
      Clear Filters
    </Button>
  </div>
);

CurrentFilters.propTypes = {
  hasFilters: PropTypes.bool.isRequired,
  onClear: PropTypes.func.isRequired,
};

/**
 * Map State to Props
 * @param  {Object} state
 * @return {Object}
 */
function mapState(state) {
  return {
    hasFilters: selectors.filters.hasFilters(state),
  };
}

export default connect(mapState)(CurrentFilters);
