import React from 'react';
import PropTypes from 'prop-types';
import FormInputError from './FormInputError';

/**
 * Error without input field
 */
const FormFieldError = ({ meta }) => <FormInputError error={meta.error} style={{ display: 'block' }} />;

FormFieldError.propTypes = {
  meta: PropTypes.shape({
    error: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
  }).isRequired,
};

export default FormFieldError;
