/**
 * Scraping detection library
 *
 * To hide the purpose of this lib (when it's loaded as a chunk file)
 * it's named "promo" to match the obfuscated scraping API.
 */
import api from 'libs/api';
import rot37 from './rot37';

/**
 * Register scraping headers into an axios instance. These headers
 * will make sure that API requests from the app won't get flagged
 * as scraping.
 */
function registerHeaders(axios) {
  axios.interceptors.request.use((config) => {
    /* eslint-disable no-param-reassign */
    config.headers['X-Cache-Token'] = encode(makeDateHeader());
    config.headers['X-Cache-Key'] = encode(makeUniqueKeyHeader());
    return config;
  });
}

function makeDateHeader() {
  const time = Math.floor(Date.now() / 1000);
  return encode(time);
}

function makeUniqueKeyHeader() {
  // Key must be formatted in a way the server expects it.
  // Either at index 9 or 2 we'll set a specific character (M or K).
  let key = getRandomLetters(16);
  key = setCharAt(key, Math.round(Math.random()) ? 9 : 2, Math.round(Math.random()) ? 'M' : 'K');
  return key;
}

function initScrapingDetection() {
  spyUsage('console', 'log');
  spyUsage('console', 'warn');
  spyUsage('console', 'alert');
  spyUsage('document', 'querySelector');
  spyUsage('document', 'querySelectorAll');
  reportSuspiciousBrowser();
}

/**
 * Replace a global function with a decorator that spies on usage.
 *
 * @param {String} obj
 * @param {String} method
 */
function spyUsage(obj, method) {
  const original = window[obj][method];

  window[obj][method] = function () {
    /* eslint-disable prefer-rest-params */
    // We use arguments to avoid a suspicious function preview
    // when inspected through the dev console
    const returned = original.apply(window[obj], arguments);
    reportUsage(`${obj}.${method}`, Array.from(arguments), returned, getCallStack());
    return returned;
  };
}

function getCallStack() {
  const error = new Error();
  let stack = error.stack ? error.stack.split('\n') : [];

  // Each browser formats the stack differently: normalize whitespace and newlines
  stack = stack.map((x) => x.trim()).filter((x) => x);

  // Chromium browsers will prefix error with an unwanted "Error" header
  if (stack[0] === 'Error') {
    stack.shift();
  }

  // Safari can sometimes wrap certain callbacks in one more
  // call that's emitted from native code. That's not useful to us as
  // we want the bottom of the stack to be the calling origin.
  if (stack.slice(-1)[0].endsWith?.('@[native code]')) {
    stack = stack.slice(0, -1);
  }

  return stack;
}

/**
 * @param {String} fn
 * @param {Array} args
 * @param {*} returned
 * @param {String[]} stack
 */
function reportUsage(fn, args, returned, stack) {
  if (!shouldReport(fn, args, returned, stack)) {
    return;
  }
  api.post('promo', null, {
    headers: {
      'X-Session-Id': encode('function-call'),
      'X-Cache-Id': encode(serializeFnCall(fn, args, returned, stack)),
    },
  });
}

/**
 * @param {String} fn
 * @param {Array} args
 * @param {*} returned
 * @param {String[]} stack
 */
export function shouldReport(fn, args, returned, stack) {
  // Report lack of stack trace, as this could be due to obscure or tampered browsers
  if (!stack.length) {
    return true;
  }

  // A succesful scraper will select elements, whereas most false-positives won't find anything.
  // As a way to reduce noise, we'll only report if the selector found something.
  if (
    (fn === 'document.querySelector' && returned == null) ||
    (fn === 'document.querySelectorAll' && returned?.length === 0)
  ) {
    return false;
  }

  // Ignore function calls that look like they're part of password or form
  // extensions. These are quite common sources of false positives.
  if (fn === 'document.querySelector' || fn === 'document.querySelectorAll') {
    const shouldReportBasedOnSelector = args[0].split(',').some((selector) => {
      if (typeof selector !== 'string') return true;

      // elements that don't contain scrapeable data (forms, inputs, meta)
      if (selector.match(/^(input|textarea|form|meta|script)[.# a-zA-Z"'=-_~:^>[\]]*$/)) {
        return false;
      }

      // children of <head>
      if (selector.match(/^head [.# a-zA-Z"'=-_~:^>[\]]*$/)) return false;

      // debugbar extension
      if (selector.startsWith('.phpdebugbar')) return false;

      // extension specific
      if (
        selector.match(/^(div)?#AdLock/) ||
        selector.match(/^(div)?\.twoseven-ext/) ||
        selector.match(/^(script)?\[data-bis-config\]/)
      ) {
        return false;
      }

      return true;
    });
    // Abort early if we know we shouldn't report
    if (!shouldReportBasedOnSelector) {
      return false;
    }
  }

  const origin = stack[stack.length - 1];
  const allowedOrigins = [
    // Our own source
    /webpack-internal:\/\//,

    // Expected Third-party Scripts
    'https://apis.google.com',
    'https://connect.facebook.net',
    'https://www.gstatic.com/recaptcha',
    'https://js.intercomcdn.com',
    'https://js.stripe.com/v3',
    'https://widget.intercom.io',
    /https:\/\/([a-z]+\.)?lenderspotlight\.ca/, // lenderspotlight domains

    'scr.kaspersky-labs.com', // anti-virus
    'chrome-extension://ojhegjfmbbpahdggoekcbmejnifimeca', // auto-fill extension
    'chrome-extension://nkbihfbeogaeaoehlefnkodbefgpgknn', // metamask extension
  ];
  return !allowedOrigins.find((pattern) => origin.match(pattern));
}

/**
 * @param {String} fn
 * @param {Array} args
 * @param {*} returned
 * @param {Array} stack
 */
function serializeFnCall(fn, args, returned, stack) {
  return JSON.stringify({
    function: fn,
    args: args.map(safeStringify),
    returned: safeStringify(returned),
    stack,
  });
}

/**
 * Stringifies any value, inlcuding recursive and/or
 * special objects (e.g. BigInt).
 *
 * @param {*} val
 */
function safeStringify(val) {
  if (typeof val === 'string') {
    return val;
  }
  try {
    return JSON.stringify(val);
  } catch (_) {
    return String(val);
  }
}

function determineBrowser() {
  const seleniumBrowserHints = [
    'webdriver' in window,
    '_Selenium_IDE_Recorder' in window,
    'callSelenium' in window,
    '_selenium' in window,
    '__webdriver_script_fn' in document,
    '__driver_evaluate' in document,
    '__webdriver_evaluate' in document,
    '__selenium_evaluate' in document,
    '__fxdriver_evaluate' in document,
    '__driver_unwrapped' in document,
    '__webdriver_unwrapped' in document,
    '__selenium_unwrapped' in document,
    '__fxdriver_unwrapped' in document,
    '__webdriver_script_func' in document,
    document.documentElement.getAttribute('selenium') !== null,
    document.documentElement.getAttribute('webdriver') !== null,
    document.documentElement.getAttribute('driver') !== null,
  ];
  if (seleniumBrowserHints.some((val) => val)) {
    return 'Selenium';
  }

  if (window.Cypress) {
    return 'Cypress';
  }

  return window.navigator.webdriver ? 'Chrome Webdriver' : null;
}

/**
 *  Scan if page is being loaded from a suspicious browser then report
 */
function reportSuspiciousBrowser() {
  const browserType = determineBrowser();
  if (!browserType) return;

  api.post('promo', null, {
    headers: {
      'X-Session-Id': encode('suspicious-browser'),
      'X-Cache-Id': encode(
        JSON.stringify({
          user_agent: window.navigator.userAgent,
          browser: browserType,
        })
      ),
    },
  });
}

/**
 * @param {String} input
 */
function encode(input) {
  return rot37(btoa(input));
}

/**
 * @param {Number} length
 */
function getRandomLetters(length) {
  const bytes = new Uint32Array(length);
  crypto.getRandomValues(bytes);
  const letters = Array.from(bytes).reduce((acc, val) => acc + val.toString(32), '');
  return letters.slice(0, length);
}

/**
 * @param {String} str
 * @param {Number} index
 * @param {String} chr
 */
function setCharAt(str, index, chr) {
  if (index > str.length - 1) return str;
  return str.slice(0, index) + chr + str.slice(index + 1);
}

export default { initScrapingDetection, registerHeaders };
