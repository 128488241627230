import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { RegionPropTypes } from 'components/regions';
import { selectors } from 'state';
import { analytics } from 'libs';
import Dropdown from './Dropdown';
import Filter from './Filter';

const RegionFilter = ({ filters, isPro = false, onFilter, regions }) => {
  const onChange = (selected) => {
    analytics.productSearch.filter({
      provincial_availability: selected.filter.regions || 'all',
    });
    onFilter(selected.filter);
  };

  const getOptions = () => {
    const options = [{ key: 'all', value: 'All', filter: { regions: null } }];

    regions.map((region) =>
      options.push({
        key: region.id,
        value: region.name,
        filter: {
          regions: region.id,
        },
      })
    );

    return options;
  };

  return (
    <Filter name="Provincial Availability" isPro={isPro}>
      <Dropdown
        isPro={isPro}
        onChange={onChange}
        options={getOptions()}
        testId="filter-region"
        value={filters.regions || 'all'}
      />
    </Filter>
  );
};

RegionFilter.propTypes = {
  filters: PropTypes.shape({
    regions: PropTypes.string,
  }).isRequired,
  isPro: PropTypes.bool,
  onFilter: PropTypes.func.isRequired,
  regions: PropTypes.arrayOf(RegionPropTypes).isRequired,
};

const mapState = (state) => ({
  regions: selectors.regions.getAll(state),
});

export default connect(mapState)(RegionFilter);
