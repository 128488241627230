import { createSelector } from 'reselect';

/**
 * Get All Brokerages from state.
 * @param {Object} state
 * @return {Object}
 */
const getAllBrokerages = (state) => state.brokerages.byId;

/**
 * Get a single brokerage from state by id.
 * @param {Object} state
 * @param {Number} id
 * @return {Object}
 */
export const getById = (state, id) => state.brokerages.byId[id];

/**
 * Get All Brokerages.
 * @param {Object} state
 * @return {Array}
 */
export const getAll = createSelector([getAllBrokerages], (brokerages) =>
  Object.keys(brokerages)
    .map((brokerageId) => brokerages[brokerageId])
    .sort((a, b) => {
      const aName = a.name.toUpperCase();
      const bName = b.name.toUpperCase();

      if (aName < bName) return -1;
      if (aName > bName) return 1;
      return 0;
    })
);

/**
 * Get the uploading state.
 * @param {Object} state
 * @return {Boolean}
 */
export const isUploading = (state) => state.brokerages.isUploading;
