import React from 'react';
import PropTypes from 'prop-types';
import { Upgrade as CommonUpgrade } from 'components/common';
import { useUser } from 'libs';

const Upgrade = ({ isPro = null, ...props }) => {
  const user = useUser();

  if (!isPro || user.features.is_pro) return null;

  return <CommonUpgrade className="btn btn-upgrade btn-sm" {...props} />;
};

Upgrade.propTypes = {
  isPro: PropTypes.bool,
};

export default Upgrade;
