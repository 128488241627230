import React from 'react';
import PropTypes from 'prop-types';
import FormSelectWithGroup from './FormSelectWithGroup';

const LenderSelectWithGroup = ({ lenders = [], ...props }) => {
  const passProps = {
    ...props,
    options: lenders,
  };

  return <FormSelectWithGroup {...passProps} />;
};

LenderSelectWithGroup.propTypes = {
  lenders: PropTypes.arrayOf(PropTypes.object),
};

export default LenderSelectWithGroup;
