import qs from 'query-string';

/**
 * Wrapper for the Query Sting library.
 * https://github.com/sindresorhus/query-string
 */

/**
 * Valid filter keys for rates and alt lending.
 */
const rules = {
  rates: {
    array: {
      lenders: true,
      programs: true,
      term_id: true,
    },
    scalar: {
      amortization: true,
      beacon: true,
      gds: true,
      insurable: true,
      insurance: true,
      lender_type: true,
      ltv: true,
      mortgage_amount: true,
      property_value: true,
      purchase: true,
      purpose: true,
      rate_hold: true,
      refinance: true,
      regions: true,
      sort: true,
      tds: true,
      transfer: true,
      type: true,
    },
  },
  altLending: {
    array: {
      lenders: true,
    },
    scalar: {
      amount: true,
      interest: true,
      loan_option: true,
      loan_type: true,
      location: true,
      location_other: true,
      location_rural: true,
      occupancy_type: true,
      owner_builder: true,
      owner_occupied: true,
      property_type: true,
      region: true,
      rental: true,
      serviced_by_municipality: true,
      serviced_by_well_septic: true,
      speculative: true,
      term: true,
      unserviced: true,
    },
  },
};

/**
 * Returns true if the filter value has a valid key and type.
 * @param {String} filter
 * @param {String} type
 * @param {*} value
 * @return {Boolean}
 */
const isValidFilter = (filter, type, value) =>
  (filter in rules[type].array && Array.isArray(value)) || (filter in rules[type].scalar && !Array.isArray(value));

/**
 * Query String Configuration Options
 * @type {Object}
 */
const options = {
  arrayFormat: 'bracket',
};

/**
 * Parse the query string to extract filters and whitelist valid values.
 * @param  {String} type
 * @return {Function}
 */
const parse =
  (type) =>
  (query, opts = options) => {
    const filters = qs.parse(query, opts);
    return Object.keys(filters).reduce(
      (accum, filter) =>
        isValidFilter(filter, type, filters[filter]) ? { ...accum, [filter]: filters[filter] } : accum,
      {}
    );
  };

/**
 * Convert a filters object to string.
 * @param  {Object} query
 * @param  {Object} opts
 * @return {String}
 */
const stringify = (query, opts = options) => qs.stringify(query, opts);

export const ratesFilter = {
  parse: parse('rates'),
  stringify,
};

export const altLendingFilter = {
  parse: parse('altLending'),
  stringify,
};
