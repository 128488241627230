import { combineReducers } from 'redux';
import { normalize } from 'normalizr';
import schema from 'state/schema';

/**
 * Policies initial state.
 */
const data = normalize(window.__INITIALSTATE__?.policies || [], [schema.policy]);
const initialState = data.entities.policies || {};

/**
 * Policies by ID reducer.
 */
function byIdReducer(state = initialState) {
  return state;
}

/**
 * Combined policies reducer.
 */
export default combineReducers({
  byId: byIdReducer,
});
