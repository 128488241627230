import React, { forwardRef } from 'react';
import classnames from 'classnames';
import propTypes from 'prop-types';

const MenuButton = forwardRef(({ active, testId, text, className = '', onClick = null, icon = null }, ref) => (
  <button
    className={classnames('btn btn-link btn-link__inline menu-button', className, {
      'menu-button--active': active,
    })}
    data-testid={testId}
    onClick={onClick}
    ref={ref}
    type="button"
  >
    <div className="menu-button__icon">{icon}</div>
    <span className="menu-button__label">{text}</span>
  </button>
));

MenuButton.propTypes = {
  active: propTypes.bool.isRequired,
  className: propTypes.string,
  icon: propTypes.element,
  onClick: propTypes.func,
  testId: propTypes.string,
  text: propTypes.string,
};

export default MenuButton;
