import React from 'react';
import PropTypes from 'prop-types';
import { analytics } from 'libs';
import Filter from './Filter';
import Toggle from './Toggle';

const options = [
  { key: 'Fixed', value: 'Fixed', filter: { type: 'Fixed' } },
  { key: 'Variable', value: 'Variable', filter: { type: 'Variable' } },
  { key: 'HELOC', value: 'HELOC', filter: { type: 'HELOC' } },
];

const TermTypeFilter = ({ filters, onFilter }) => {
  const onChange = (value) => {
    let selected = options.find((option) => option.key === value);

    if (selected.key === filters.type) {
      selected = { filter: { type: null } };
    }

    analytics.productSearch.filter({
      term_type: selected.filter.type || 'cleared',
    });

    onFilter(selected.filter);
  };

  return (
    <Filter name="Term Type">
      <Toggle onChange={onChange} options={options} testId="filter-term-type" value={filters.type || false} />
    </Filter>
  );
};

TermTypeFilter.propTypes = {
  filters: PropTypes.shape({
    type: PropTypes.string,
  }).isRequired,
  onFilter: PropTypes.func.isRequired,
};

export default TermTypeFilter;
