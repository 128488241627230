import React from 'react';
import PropTypes from 'prop-types';
import ContentAside from './ContentAside';
import ContentBody from './ContentBody';

const Content = ({ children, className = '', ...props }) => (
  <div className={`content ${className}`} {...props}>
    {children}
  </div>
);

Content.Aside = ContentAside;
Content.Body = ContentBody;

Content.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string,
};

export default Content;
