import React from 'react';
import PropTypes from 'prop-types';
import ComparePropertyRow from './ComparePropertyRow';
import properties from './properties';

const CompareProperties = ({ products }) => (
  <>
    <ComparePropertyRow name="Term/Type">{products.map(properties.termType)}</ComparePropertyRow>
    <ComparePropertyRow name="LTV">{products.map(properties.ltv)}</ComparePropertyRow>
    <ComparePropertyRow name="Beacon Range">{products.map(properties.beacon)}</ComparePropertyRow>
    <ComparePropertyRow name="Transaction Type">{products.map(properties.transactionType)}</ComparePropertyRow>
    <ComparePropertyRow name="Maximum Amortization">{products.map(properties.amortization)}</ComparePropertyRow>
    <ComparePropertyRow name="GDS/TDS">{products.map(properties.gdsTds)}</ComparePropertyRow>
    <ComparePropertyRow name="Rate Hold">{products.map(properties.rateHold)}</ComparePropertyRow>
    <ComparePropertyRow name="Compensation">{products.map(properties.compensation)}</ComparePropertyRow>
    <ComparePropertyRow name="Buy Down" last>
      {products.map(properties.buyDown)}
    </ComparePropertyRow>
  </>
);

CompareProperties.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    })
  ).isRequired,
};

export default CompareProperties;
