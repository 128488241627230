import React from 'react';
import PropTypes from 'prop-types';

const CompareBody = ({ children }) => <div className="compare-body">{children}</div>;

CompareBody.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CompareBody;
