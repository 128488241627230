import React from 'react';
import PropTypes from 'prop-types';

const HeroBody = ({ children }) => <div className="hero__body">{children}</div>;

HeroBody.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default HeroBody;
