import React from 'react';
import FormFieldPropTypes from './FormFieldPropTypes';
import FormGroup from './FormGroup';
import FormInputError from './FormInputError';
import FormLabel from './FormLabel';
import Radio from './Radio';

const RadioWithGroup = (props) => {
  const { label, ...passProps } = props;

  return (
    <FormGroup error={props.meta.error} row>
      <FormLabel name={label} htmlFor={props.input.name} row />
      <div className="col-md-6">
        <Radio {...passProps} />
        <FormInputError error={props.meta.error} />
      </div>
    </FormGroup>
  );
};

RadioWithGroup.propTypes = {
  ...FormFieldPropTypes,
};

export default RadioWithGroup;
