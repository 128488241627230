import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Loader as LoaderIcon } from 'react-loaders';
import { uid } from 'libs';

const Loader = ({ children = null, isLoading, message = null }) => {
  const [loadingId, setLoadingId] = useState(uid());
  const prevIsLoading = useRef(isLoading);
  useEffect(() => {
    if (isLoading && !prevIsLoading.current) {
      setLoadingId(uid());
    }
    prevIsLoading.current = isLoading;
  }, [isLoading]);

  if (!isLoading)
    return (
      <div data-testid="loader-done" data-loading-id={loadingId}>
        {children}
      </div>
    );

  return (
    <div className="z-1" data-loading-id={loadingId} data-testid="loader-loading" id="loader">
      <LoaderIcon active className="loader-dark" color="#FCC015" size="md" type="line-scale" />
      {message && <span className="loader--message">{message}</span>}
    </div>
  );
};

Loader.propTypes = {
  children: PropTypes.node,
  isLoading: PropTypes.bool.isRequired,
  message: PropTypes.string,
};

export default Loader;
