import { useEffect, useRef } from 'react';

/**
 * @param {Function} callback
 */
const useCallOnce = (callback) => {
  const wasCalled = useRef(false);
  useEffect(() => {
    if (!wasCalled.current) {
      callback();
      wasCalled.current = true;
    }
  });
};

export default useCallOnce;
