import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { analytics } from 'libs';
import { selectors } from 'state';

const trackNotification = (notification) => {
  if (notification.id === 'COMPARE_LIMIT') {
    analytics.productSearch.rateCompareLimitReached();
  }
};

const Notifications = ({ notifications }) => {
  useEffect(() => {
    const lastNotification = notifications[notifications.length - 1];
    if (lastNotification) {
      trackNotification(lastNotification);
      // eslint-disable-next-line no-alert
      alert(lastNotification.message);
    }
  }, [notifications]);
  return null;
};

Notifications.propTypes = {
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      message: PropTypes.string,
    })
  ),
};

const mapState = (state) => ({
  notifications: selectors.notifications.getNotifications(state),
});

export default connect(mapState)(Notifications);
