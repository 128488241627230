import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';

export default class InvisibleErrorBoundary extends Component {
  /**
   * PropTypes Validation
   * @type {Object}
   */
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  };

  /**
   * Constructor
   * @param  {Object} props
   * @return {Void}
   */
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  /**
   * ComponentDidCatch
   * @param {Object} error
   * @param {String} info
   * @return {Void}
   */
  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    Sentry.captureException(error, { extra: info });
  }

  /**
   * Render Component
   * @return {Object}
   */
  render() {
    if (this.state.hasError) {
      return <div />;
    }

    return this.props.children;
  }
}
