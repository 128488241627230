import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { analytics } from 'libs';
import { selectors } from 'state';
import Filter from './Filter';
import Toggle from './Toggle';

const {
  filters: { INSURANCE_TO_FILTERS_MAP },
} = selectors;

const FILTERS_NO_SELECTION = {
  insurance: undefined,
  insurable: undefined,
};

const insuranceOptions = [
  { key: 'Conventional Insurable', value: 'Conventional Insurable' },
  { key: 'Conventional Uninsurable', value: 'Conventional Uninsurable' },
  { key: 'Insured', value: 'Insured' },
];

/**
 * Get insurance option by filters
 * @param {Object} filters
 * @param {String} filters.insurance
 * @param {String} filters.insurable
 * @return {(Object|null)}
 */
const getInsuranceOption = ({ insurance, insurable }) => {
  const entry =
    Object.entries(INSURANCE_TO_FILTERS_MAP).find(
      ([, filters]) => filters.insurance === insurance && filters.insurable === insurable
    ) ?? [];
  const [key] = entry;
  return insuranceOptions.find((option) => option.key === key) ?? null;
};

/**
 * Get insurance filters by option key
 * @param {String} optionKey
 * @return {Object}
 */
const getInsuranceFilters = (optionKey) => {
  return INSURANCE_TO_FILTERS_MAP[optionKey] ?? FILTERS_NO_SELECTION;
};

/**
 * Clear invalid insurance filters hook
 *
 * It's possible for filters to contain an invalid combination of insurance values,
 * e.g.,
 *
 * ```
 * {
 *   insurance: 'Insured',
 *   insurable: 'No',
 * }
 * ```
 *
 * NOTE: If there are multiple potential invalid filters, it might be better to
 * handle them in a single location. That way the app will make at most a single
 * extra request when there's one or more invalid filters
 *
 * @param {Object} args
 * @param {Object} args.filters
 * @param {Function} args.onFilter
 * @param {String} [args.option]
 */
const useClearInvalidInsuranceFilters = ({ filters, onFilter, option }) => {
  useEffect(() => {
    const hasInsuranceFilter = Boolean(filters.insurance || filters.insurable);
    if (!hasInsuranceFilter || option) return;

    onFilter(FILTERS_NO_SELECTION);
  }, [filters, option, onFilter]);
};

const InsuranceFilter = ({ filters, isPro = false, onFilter }) => {
  const option = getInsuranceOption(filters);
  const currKey = option ? option.key : false;

  useClearInvalidInsuranceFilters({ filters, onFilter, option });

  const onChange = (key) => {
    const isDeselection = key === currKey;
    const newFilters = isDeselection ? FILTERS_NO_SELECTION : getInsuranceFilters(key);

    onFilter(newFilters);

    analytics.productSearch.filter({
      insurance: isDeselection ? 'cleared' : key,
    });
  };

  return (
    <Filter name="Insurance" isPro={isPro}>
      <Toggle
        isPro={isPro}
        onChange={onChange}
        options={insuranceOptions}
        testId="insurance-filter"
        value={currKey}
        variant="vertical"
      />
    </Filter>
  );
};

InsuranceFilter.propTypes = {
  filters: PropTypes.shape({
    insurance: PropTypes.string,
    insurable: PropTypes.string,
  }).isRequired,
  isPro: PropTypes.bool,
  onFilter: PropTypes.func.isRequired,
};

export default InsuranceFilter;
