import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Overlay from 'react-bootstrap/Overlay';
import BootstrapTooltip from 'react-bootstrap/Tooltip';
import { noop } from 'libs';
import { QuestionCircleIcon } from 'icons';

const Tooltip = ({
  children,
  className = 'mx-1 width-4',
  Icon = QuestionCircleIcon,
  onVisibilityChange = noop,
  placement = 'right',
}) => {
  const [persist, setPersist] = useState(false);
  const [show, setShow] = useState(false);
  const target = useRef(null);
  const isVisible = persist || show;

  useEffect(() => {
    onVisibilityChange(isVisible);
  }, [isVisible, onVisibilityChange]);

  return (
    <div className={className}>
      <Icon
        className="cursor-pointer"
        onClick={() => setPersist(true)}
        onMouseEnter={() => setShow(true)}
        onMouseLeave={() => setShow(false)}
        ref={target}
      />
      <Overlay
        onHide={() => setPersist(false)}
        placement={placement}
        rootClose
        rootCloseEvent="mousedown"
        show={isVisible}
        target={target.current}
      >
        {(props) => <BootstrapTooltip {...props}>{children}</BootstrapTooltip>}
      </Overlay>
    </div>
  );
};

Tooltip.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  className: PropTypes.string,
  Icon: PropTypes.object,
  onVisibilityChange: PropTypes.func,
  placement: PropTypes.oneOf(['left', 'right']),
};

export default Tooltip;
