import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import { noop } from 'libs';

const FormHelpModal = ({ children, onHide = noop, show = false, title }) => (
  <Modal show={show} onHide={onHide}>
    <Modal.Header closeButton>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
    <Modal.Body>{children}</Modal.Body>
  </Modal>
);

FormHelpModal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  onHide: PropTypes.func,
  show: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

export default FormHelpModal;
