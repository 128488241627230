import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { analytics } from 'libs';

const SideNavItem = ({
  icon,
  isActive = false,
  isExternalRoute = false,
  label,
  name,
  shouldOpenNewWindow = false,
  to,
}) => (
  <div className="side-navbar__navlink-container">
    {isExternalRoute ? (
      <a
        aria-label={label}
        className="side-navbar__navlink"
        data-testid={`side-navbar-navlink-${name}`}
        href={to}
        onClick={analytics.navigation.onDesktop(name)}
        rel={shouldOpenNewWindow && 'noopener noreferrer'}
        target={shouldOpenNewWindow && '_blank'}
      >
        <span className="d-inline-block w-6 leading-none text-white">{icon}</span>
      </a>
    ) : (
      <Link
        aria-label={label}
        className={classnames('side-navbar__navlink', {
          active: isActive,
        })}
        data-testid={`side-navbar-navlink-${name}`}
        to={to}
        onClick={analytics.navigation.onDesktop(name)}
      >
        <span className="d-inline-block w-6 leading-none text-white">{icon}</span>
      </Link>
    )}
    <div className="side-navbar__label">{label}</div>
  </div>
);

SideNavItem.propTypes = {
  icon: PropTypes.element.isRequired,
  isActive: PropTypes.bool,
  isExternalRoute: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  shouldOpenNewWindow: PropTypes.bool,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};

export default SideNavItem;
