import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import numeral from 'numeral';
import { analytics } from 'libs';
import Filter from './Filter';
import Text from './Text';

const GdsTdsFilter = ({ filters, isPro = false, onFilter }) => {
  const [gds, setGds] = useState(filters.gds);
  const [tds, setTds] = useState(filters.tds);

  /**
   * The first render will occur before the initial filter state is ready. If
   * the filter state was ready on first render, the state sync below would
   * be unnecessary. To remove this, we would need the parent component to
   * immediately pass down the actual initial filter state.
   */
  useEffect(() => {
    setGds(filters.gds);
    setTds(filters.tds);
  }, [filters.gds, filters.tds, setGds, setTds]);

  const onDebouncedFilter = useMemo(
    () =>
      debounce((newFilters) => {
        onFilter(newFilters);
      }, 400),
    [onFilter]
  );

  const onChange = (event) => {
    event.preventDefault();
    const field = event.target.name;
    const setField = field === 'gds' ? setGds : setTds;
    const newValue = numeral(event.target.value).value();

    analytics.productSearch.filterDebounced({ [field]: newValue || 'cleared' });

    setField(newValue);

    onDebouncedFilter({ [field]: newValue });
  };

  return (
    <div className="d-flex">
      <div className="me-3">
        <Filter
          name="GDS"
          isPro={isPro}
          tooltip={
            <span>
              Filter by GDS and TDS individually or together.
              <br />
              <a
                href="https://support.lenderspotlight.ca/en/articles/6209696-filtering-by-gds-tds"
                rel="noopener noreferrer"
                target="_blank"
              >
                Click for definitions.
              </a>
            </span>
          }
        >
          <Text
            isPro={isPro}
            onChange={onChange}
            name="gds"
            placeholder="Enter GDS"
            testId="filter-gds"
            value={gds ? numeral(gds).format() : ''}
          />
        </Filter>
      </div>
      <div>
        <Filter name="TDS" isPro={isPro}>
          <Text
            isPro={isPro}
            onChange={onChange}
            name="tds"
            placeholder="Enter TDS"
            testId="filter-tds"
            value={tds ? numeral(tds).format() : ''}
          />
        </Filter>
      </div>
    </div>
  );
};

GdsTdsFilter.propTypes = {
  filters: PropTypes.shape({
    gds: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    tds: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }).isRequired,
  isPro: PropTypes.bool,
  onFilter: PropTypes.func.isRequired,
};

export default GdsTdsFilter;
