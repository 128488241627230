import React from 'react';
import PropTypes from 'prop-types';
import { noop, useUser } from 'libs';

const Text = ({
  className = '',
  disabled = false,
  isPro = false,
  name = '',
  onBlur = noop,
  onChange,
  onFocus = noop,
  placeholder = '',
  testId,
  value,
}) => {
  const user = useUser();
  return (
    <input
      type="text"
      className={`form-control ${className}`}
      data-testid={testId}
      disabled={disabled}
      name={name}
      value={value}
      placeholder={placeholder}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
      readOnly={isPro && !user.features.is_pro}
    />
  );
};

Text.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  isPro: PropTypes.bool,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  placeholder: PropTypes.string,
  testId: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};

export default Text;
