import { createSelector } from 'reselect';

const RATE_TYPES = ['Standard Products', 'Limited Feature', 'Promotions'];

export const INSURANCE_TO_FILTERS_MAP = {
  'Conventional Insurable': {
    insurance: 'Conventional',
    insurable: 'Yes',
  },
  'Conventional Uninsurable': {
    insurance: 'Conventional',
    insurable: 'No',
  },
  Insured: {
    insurance: 'Insured',
    insurable: undefined,
  },
};

/**
 * Get the current filters to apply to search.
 * @param  {Object} state
 * @return {Object}
 */
export const getAll = (state) => state.filters.options;

const getAllWithoutSort = createSelector(getAll, (filters) => {
  const clone = { ...filters };
  delete clone.sort;
  return clone;
});

/**
 * Check if the state has any filters.
 * @param  {Object} state
 * @return {Boolean}
 */
export const hasFilters = (state) =>
  Object.keys(state.filters.options).reduce((accum, value) => {
    if (value !== 'sort') {
      accum.push(value);
    }

    return accum;
  }, []).length > 0;

/**
 * @param {Object} state
 * @return {(String|undefined)}
 */
const getRateType = createSelector(getAllWithoutSort, ({ programs = [] }) =>
  programs.find((program) => RATE_TYPES.includes(program))
);

/**
 * @param {Object} state
 * @return {Array}
 */
const getPrograms = createSelector(getAllWithoutSort, ({ programs = [] }) =>
  programs.filter((program) => !RATE_TYPES.includes(program))
);

/**
 * @param {Object} state
 * @return {(String|undefined)}
 */
const getInsurance = createSelector(getAllWithoutSort, ({ insurance, insurable }) => {
  const entry =
    Object.entries(INSURANCE_TO_FILTERS_MAP).find(
      ([, filters]) => filters.insurance === insurance && filters.insurable === insurable
    ) ?? [];

  const [key] = entry;
  return key;
});

/**
 * @param {Object} state
 * @return {Object}
 */
const getAllForUI = createSelector(
  getAllWithoutSort,
  getRateType,
  getPrograms,
  getInsurance,
  (filters, rateType, programs, insurance) => {
    const clone = { ...filters };

    // The UI splits the original `programs` API payload into two separate filters
    delete clone.programs;

    // The UI joins the original `insurance` and `insurable` API payloads into one filter
    delete clone.insurance;
    delete clone.insurable;

    return {
      ...clone,
      ...(rateType && { rateType }),
      ...(programs.length && { programs }),
      ...(insurance && { insurance }),
    };
  }
);

/**
 * @param {Object} state
 * @return {Number}
 */
export const getCountForUI = createSelector(getAllForUI, (filters) => Object.keys(filters).length);
