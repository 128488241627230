import api from './api';

class Insights {
  // AltSolution Insight Events
  altSolution = {
    /**
     * Track alt-solution contact click insight event.
     * @param {Number} solutionId
     * @param {Number} loanOptionId
     * @param {Number} propertyTypeId
     */
    cardClick: (solutionId, loanType, loanOptionId, propertyTypeId) =>
      trackAltSolution('card-click', solutionId, loanType, loanOptionId, propertyTypeId),

    /**
     * Track alt-solution contact click insight event.
     * @param {Number} solutionId
     * @param {Number} loanOptionId
     * @param {Number} propertyTypeId
     */
    cardView: (solutionId, loanType, loanOptionId, propertyTypeId) =>
      trackAltSolution('card-view', solutionId, loanType, loanOptionId, propertyTypeId),

    /**
     * Track alt-solution contact click insight event.
     * @param {Number} solutionId
     * @param {Number} loanOptionId
     * @param {Number} propertyTypeId
     */
    contactClick: (solutionId, loanType, loanOptionId, propertyTypeId) =>
      trackAltSolution('contact-click', solutionId, loanType, loanOptionId, propertyTypeId),

    /**
     * Track alt-solution Finmo CTA click insight event.
     * @param {Number} solutionId
     * @param {Number} loanOptionId
     * @param {Number} propertyTypeId
     */
    finmoCTAClick: (solutionId, loanType, loanOptionId, propertyTypeId) =>
      trackAltSolution('finmo-cta-click', solutionId, loanType, loanOptionId, propertyTypeId),
  };
}

/**
 * Helper function for tracking insight event.
 * @param {String} endpoint
 * @param {*} params
 */
const track = (endpoint, params) => {
  api.post(`insights/${endpoint}`, params);
};

// Make function public so it can be stubbed in Cypress tests
window['insights.track'] = track;

/**
 * Helper function for tracking alt-solution insight event.
 * @param {String} eventName
 * @param {Number} solutionId
 * @param {Number} loanOptionId
 * @param {Number} propertyTypeId
 */
const trackAltSolution = (eventName, solutionId, loanType, loanOptionId, propertyTypeId) =>
  window['insights.track'](`alt-solution-${eventName}`, {
    alt_solution_id: solutionId,
    loan_type: loanType,
    loan_option_id: loanOptionId,
    property_type_id: propertyTypeId,
  });

export default new Insights();
