import { createSelector } from 'reselect';

/**
 * Comparison function to facilitate the sorting
 * of settings by name.
 * @param  {Object} a
 * @param  {Object} b
 * @return {Number}
 */
const sortByName = (a, b) => {
  if (a.name < b.name) {
    return -1;
  }

  if (a.name > b.name) {
    return 1;
  }

  return 0;
};

/**
 * Get all the settings for the current user.
 * @param  {Object} state
 * @return {Object}
 */
export const forUser = (state) => state.settings.forUser;

/**
 * Get all the setting for a brokerage.
 * @param  {Object} state
 * @param  {String} brokerageId
 * @return {Object}
 */
export const getAllByBrokerageId = (state, brokerageId) => state.settings.byBrokerageId[brokerageId] || {};

/**
 * Get All Settings from state.
 * @param  {Object} state
 * @return {Object}
 */
export const getAllSettings = (state) => state.settings.byId;

/**
 * Get the setting type passed to the selectors.
 * NOTE: This is a work around to pass the value to the
 * memorized selector created with reselect.
 * @param  {Object} state
 * @param  {String} type
 * @return {String}
 */
const getSettingType = (state, type = 'brokerage') => type;

export const getActiveByBrokerageId = createSelector([getAllByBrokerageId], (settings) =>
  Object.keys(settings)
    .map((settingId) => settings[settingId])
    .filter((setting) => setting.value >= 1)
    .map((setting) => setting.key)
);

/**
 * Get All settings from state.
 * @param {Object} state
 * @param {String} type
 * @return {Array}
 */
export const getAll = createSelector([getAllSettings, getSettingType], (settings, type) =>
  Object.keys(settings)
    .map((settingId) => settings[settingId])
    .filter((setting) => setting.key.includes(type))
    .sort(sortByName)
);

/**
 * Get All Active Settings by BrokerageId.
 * @param  {Object} state
 * @param  {String} brokerageId
 * @return {Array}
 */
export const getByBrokerageId = createSelector([getAllByBrokerageId], (settings) =>
  Object.keys(settings).map((settingId) => settings[settingId])
);

/**
 * Get the isLoading state for settings.
 * @param {Object} state
 */
export const isLoading = (state) => state.settings.pendingRequestsCount !== 0;
