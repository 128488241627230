import CompareBody from './CompareBody';
import CompareGuidelines from './CompareGuidelines';
import CompareHeader from './CompareHeader';
import CompareHeadingRow from './CompareHeadingRow';
import CompareProperties from './CompareProperties';
import CompareReportForm from './CompareReportForm';
import CompareReportModal from './CompareReportModal';

const Compare = {
  Body: CompareBody,
  Guidelines: CompareGuidelines,
  Header: CompareHeader,
  HeadingRow: CompareHeadingRow,
  Properties: CompareProperties,
  ReportForm: CompareReportForm,
  ReportModal: CompareReportModal,
};

export default Compare;
