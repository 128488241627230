import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Loader } from 'react-loaders';
import FormFieldPropTypes from './FormFieldPropTypes';
import FormGroup from './FormGroup';
import FormInputError from './FormInputError';
import FormLabel from './FormLabel';

const FormSelectWithGroup = ({
  forwardRef,
  input,
  isLoading = false,
  label,
  meta,
  options,
  variant = 'row',
  ...props
}) => (
  <FormGroup row={variant === 'row'}>
    <FormLabel name={label} htmlFor={input.name} row={variant === 'row'} />
    <div className={classnames({ 'col-md-6': variant === 'row' })}>
      <select
        {...input}
        {...props}
        ref={forwardRef}
        className={classnames('form-control', { 'is-invalid': meta.error })}
      >
        {options.map((option) => (
          <option key={option.id} value={option.id}>
            {option.name}
          </option>
        ))}
      </select>
      <FormInputError error={meta.error} />
      <Loader type="line-scale" active={isLoading} size="xs" className="loader-dark form-select-loader" />
    </div>
  </FormGroup>
);

FormSelectWithGroup.propTypes = {
  ...FormFieldPropTypes,
  isLoading: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    })
  ).isRequired,
  variant: PropTypes.oneOf(['column', 'row']),
};

export default FormSelectWithGroup;
