import api from './api';

const parseSetupIntent = (setupIntent) => ({
  payment_method_id: setupIntent.payment_method,
});

const getSetupIntentToken = async () => {
  return (await fetchSetupIntentToken())?.client_secret;
};

const fetchSetupIntentToken = async () => api.post('users/payment-session');

export default { parseStripeToken: parseSetupIntent, getSetupIntentToken };
