import React from 'react';
import PropTypes from 'prop-types';
import { analytics } from 'libs';
import Dropdown from './Dropdown';
import Toggle from './Toggle';
import Filter from './Filter';

const PurposeAndRateTypeFilter = ({ isPro = false, filters, onFilter }) => {
  const options = {
    purpose: [
      { key: 'All', value: 'All', filter: null },
      {
        key: 'Owner Occupied',
        value: 'Owner Occupied',
        filter: 'Owner Occupied',
      },
      {
        key: 'Rental: 1 Unit',
        value: 'Rental: 1 Unit',
        filter: 'Rental: 1 Unit',
      },
      {
        key: 'Rental: 2-4 Units',
        value: 'Rental: 2-4 Units',
        filter: 'Rental: 2-4 Units',
      },
    ],
    rateType: [
      { key: 'standard', value: 'Standard', filter: 'Standard Products' },
      { key: 'limited', value: 'Limited', filter: 'Limited Feature' },
      { key: 'promo', value: 'Promo', filter: 'Promotions' },
    ],
  };

  /**
   * @param {*} selected
   */
  const onPurposeChange = (selected) => {
    const { programs = [] } = filters;

    analytics.productSearch.filter({ purpose: selected.filter || 'all' });

    const rateTypeFilters = options.rateType.map((option) => option.filter);
    const shouldRemoveRateTypeFilters = !isRateTypeAllowed(selected.filter);
    const updatedPrograms = shouldRemoveRateTypeFilters
      ? programs.filter((program) => !rateTypeFilters.includes(program))
      : programs;

    onFilter({
      purpose: selected.filter,
      ...(shouldRemoveRateTypeFilters && { programs: updatedPrograms }),
    });
  };

  /**
   * @param {String} key
   */
  const onRateTypeChange = (key) => {
    const { rateType: rateTypeOptions } = options;
    const newFilters = filters.programs || [];

    const pressed = rateTypeOptions.find((option) => option.key === key);

    const isSelected = newFilters.includes(pressed.filter);

    rateTypeOptions.forEach((option) => {
      const index = newFilters.findIndex((filter) => filter === option.filter);
      if (index !== -1) newFilters.splice(index, 1);
    });

    if (!isSelected) {
      newFilters.push(pressed.filter);
    }

    analytics.productSearch.filter({
      rate_type: isSelected ? 'cleared' : pressed.filter,
    });

    onFilter({ programs: newFilters });
  };

  return (
    <>
      <Filter name="Purpose">
        <Dropdown
          onChange={onPurposeChange}
          options={options.purpose}
          testId="filter-purpose"
          value={filters.purpose || 'All'}
        />
      </Filter>
      <RateTypeToggle
        disabled={!isRateTypeAllowed(filters.purpose)}
        filters={filters}
        isPro={isPro}
        onChange={onRateTypeChange}
        options={options.rateType}
      />
    </>
  );
};

PurposeAndRateTypeFilter.propTypes = {
  isPro: PropTypes.bool,
  onFilter: PropTypes.func.isRequired,
  filters: PropTypes.shape({
    purpose: PropTypes.string,
    programs: PropTypes.array,
  }).isRequired,
};

const RateTypeToggle = ({ disabled = false, filters: { programs = [] }, isPro = false, onChange, options }) => {
  const selectedOptions = options.filter((option) => programs.includes(option.filter));
  const value = selectedOptions.map((option) => option.key);

  return (
    <Filter
      name="Rate Type"
      isPro={isPro}
      tooltip={
        <span>
          Narrow your search for owner occupied rates.
          <br />
          <a
            href="https://support.lenderspotlight.ca/en/articles/4939832-rate-type-definitions"
            rel="noopener noreferrer"
            target="_blank"
          >
            Click for definitions.
          </a>
        </span>
      }
    >
      <Toggle
        isPro={isPro}
        onChange={onChange}
        options={options}
        testId="filter-rate-type"
        value={value}
        disabled={disabled}
      />
    </Filter>
  );
};

RateTypeToggle.propTypes = {
  disabled: PropTypes.bool,
  filters: PropTypes.shape({
    programs: PropTypes.array,
  }).isRequired,
  isPro: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      filter: PropTypes.string.isRequired,
    })
  ).isRequired,
};

/**
 * @param {String} [purpose]
 * @returns {Boolean}
 */
const isRateTypeAllowed = (purpose) => [null, undefined, 'Owner Occupied'].includes(purpose);

export default PurposeAndRateTypeFilter;
