import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';

export default class ErrorBoundary extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    showDialog: PropTypes.bool,
  };

  static defaultProps = {
    showDialog: true,
  };

  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  /**
   * We can fix certain errors by reloading. Passes reloaded flag to avoid infinite reload loops.
   * @param {*} error
   * @return {Boolean}
   */
  shouldRecoverByReload = (error) => error.name === 'ChunkLoadError' && !window.location.search.includes('reloaded');

  reloadOnce = () => {
    const { location } = window;
    location.href = `${location.origin}${location.pathname}${location.search || '?'}&reloaded`;
  };

  /**
   * @param {*} error
   * @param {String} errorInfo
   */
  componentDidCatch(error, errorInfo) {
    if (this.shouldRecoverByReload(error)) {
      // Since we're reloading immediately we won't be in time to capture the exception
      this.reloadOnce();
    } else {
      Sentry.captureException(error, { extra: errorInfo });
      this.setState({ error });
    }
  }

  render() {
    if (!this.state.error) {
      return this.props.children;
    }

    if (this.props.showDialog) {
      Sentry.showReportDialog();
    }

    return (
      <div style={{ padding: 20, textAlign: 'center' }}>
        <p>We&apos;re sorry — something&apos;s gone wrong. Our team has been notified.</p>
      </div>
    );
  }
}
