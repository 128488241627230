import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import HeroBody from './HeroBody';
import HeroImage from './HeroImage';
import HeroBacklink from './HeroBacklink';

const Hero = ({ children, fixed = false, hasBorderBottom = false }) => (
  <div className={classnames('hero', { 'hero-fixed': fixed, 'border-bottom': hasBorderBottom })}>
    <div className="hero__container">{children}</div>
  </div>
);

Hero.Body = HeroBody;
Hero.Image = HeroImage;
Hero.Backlink = HeroBacklink;

/**
 * PropTypes Validation
 * @type {Object}
 */
Hero.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  fixed: PropTypes.bool,
  hasBorderBottom: PropTypes.bool,
};

export default Hero;
