import React from 'react';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions, selectors } from 'state';
import { ExclamationCircleIcon } from 'icons';
import { Icon } from 'components/common';

const StaleComparedNotification = ({ removedCount, dispatch }) => {
  if (!removedCount) return null;

  return (
    <div className="border-top d-flex mb-0 mx-5 py-5">
      <ExclamationCircleIcon className="flex-shrink-0 text-red" height="24" width="24" />
      <span className="ps-2 pe-3 fw-bold text-0_85">
        {pluralize('product', removedCount, true)} you selected {pluralize('was', removedCount)} updated and removed
        from the list.
        <span> Reselect to compare.</span>
      </span>
      <button
        onClick={dispatch.products.resetStaleCompared}
        type="button"
        className="close text-1_25"
        aria-label="Close"
      >
        <Icon name="xmark" />
      </button>
    </div>
  );
};

StaleComparedNotification.propTypes = {
  dispatch: PropTypes.shape({
    products: PropTypes.shape({
      resetStaleCompared: PropTypes.func.isRequired,
    }),
  }).isRequired,
  removedCount: PropTypes.number.isRequired,
};

const mapState = (state) => ({
  removedCount: selectors.products.getStaleComparedProductsCount(state),
});

const mapDispatch = (dispatch) => ({
  dispatch: {
    products: bindActionCreators(actions.products, dispatch),
  },
});

export default connect(mapState, mapDispatch)(StaleComparedNotification);
