import React from 'react';
import PropTypes from 'prop-types';
import { analytics } from 'libs';
import Filter from './Filter';
import Toggle from './Toggle';

const options = [
  { key: 'purchase', value: 'Purchase', filter: { purchase: 1 } },
  { key: 'refinance', value: 'Refinance', filter: { refinance: 1 } },
  { key: 'transfer', value: 'Transfer', filter: { transfer: 1 } },
];

const TransactionTypeFilter = ({ filters, onFilter }) => {
  const onChange = (filter) => {
    analytics.productSearch.filter({
      transaction_type: filters[filter] ? 'cleared' : filter,
    });
    onFilter({
      purchase: null,
      refinance: null,
      transfer: null,
      [filter]: filters[filter] ? null : 1,
    });
  };

  const getValue = () => {
    return options.reduce((accum, option) => {
      if (filters[option.key]) {
        accum.push(option.key);
      }
      return accum;
    }, []);
  };

  return (
    <Filter name="Transaction Type">
      <Toggle testId="filter-transaction-type" onChange={onChange} options={options} value={getValue()} />
    </Filter>
  );
};

TransactionTypeFilter.propTypes = {
  filters: PropTypes.shape({
    purchase: PropTypes.oneOf([0, 1, '0', '1']),
    refinance: PropTypes.oneOf([0, 1, '0', '1']),
    transfer: PropTypes.oneOf([0, 1, '0', '1']),
  }).isRequired,
  onFilter: PropTypes.func.isRequired,
};

export default TransactionTypeFilter;
