import React from 'react';
import PropTypes from 'prop-types';
import { analytics } from 'libs';
import Filter from './Filter';
import Picklist from './Picklist';

const options = [
  { key: '12', value: '6 Month', filter: { term_id: 12 } },
  { key: '5', value: '5 Year', filter: { term_id: 5 } },
  { key: '1', value: '1 Year', filter: { term_id: 1 } },
  { key: '6', value: '6 Year', filter: { term_id: 6 } },
  { key: '2', value: '2 Year', filter: { term_id: 2 } },
  { key: '7', value: '7 Year', filter: { term_id: 7 } },
  { key: '3', value: '3 Year', filter: { term_id: 3 } },
  { key: '10', value: '10 Year', filter: { term_id: 10 } },
  { key: '4', value: '4 Year', filter: { term_id: 4 } },
];

const TermFilter = ({ filters, onFilter, size }) => {
  const fields = [];

  const onChange = () => {
    const checked = fields.reduce((acc, input) => (input && input.checked ? [...acc, input.value] : acc), []);

    analytics.productSearch.filter({ term: checked || 'none' });

    onFilter({ term_id: checked.length ? checked : null });
  };

  const setRef = (ref) => {
    fields.push(ref);
  };

  return (
    <Filter name="Term">
      <Picklist
        keyPrefix="term"
        onChange={onChange}
        options={options}
        setRef={setRef}
        size={size}
        twoColumn
        value={filters.term_id || []}
      />
    </Filter>
  );
};

TermFilter.propTypes = {
  filters: PropTypes.shape({
    term_id: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  }).isRequired,
  onFilter: PropTypes.func.isRequired,
  size: PropTypes.oneOf(['default', 'lg']).isRequired,
};

export default TermFilter;
