import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'components/common';

const Banner = ({ captionText, children, headerText, image, onHide = null }) => (
  <div className="card banner">
    <div className="banner__img-holder">{image}</div>
    <div className="banner__content-holder">
      <div className="banner__text-holder">
        <p>{headerText}</p>
        <p>{captionText}</p>
      </div>
      <div className="banner__btn-holder">{children}</div>
    </div>
    {onHide && (
      <div className="banner__close-holder">
        <button aria-label="Close banner" className="banner__close" onClick={onHide} type="button">
          <Icon name="xmark" />
        </button>
      </div>
    )}
  </div>
);

Banner.propTypes = {
  image: PropTypes.node.isRequired,
  headerText: PropTypes.string.isRequired,
  captionText: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onHide: PropTypes.func,
};

export default Banner;
