/**
 * @param {Object} state
 * @return {Boolean}
 */
export const isLoading = (state) => state.lendersByProducts.isLoading;

/**
 * @param {Object} state
 * @return {Array}
 */
export const getResults = (state) => state.lendersByProducts.lendersByProductsResults;

export const getMeta = (state) => state.lendersByProducts.meta;
