import PropTypes from 'prop-types';
import { combineReducers } from 'redux';
import { normalize } from 'normalizr';
import schema from 'state/schema';

const FETCH = 'lenderspotlight/brokerages/FETCH';
const FETCH_FAILED = 'lenderspotlight/brokerages/FETCH_FAILED';
const FETCH_SUCCESS = 'lenderspotlight/brokerages/FETCH_SUCCESS';
const INVITE = 'lenderspotlight/brokerages/INVITE';
const INVITE_FAILED = 'lenderspotlight/brokerages/INVITE_FAILED';
const INVITE_SUCCESS = 'lenderspotlight/brokerages/INVITE_SUCCESS';
const REMOVE_STATUS = 'lenderspotlight/brokerages/REMOVE_STATUS';
const REMOVE_STATUS_FAILED = 'lenderspotlight/brokerages/REMOVE_STATUS_FAILED';
const REMOVE_USER = 'lenderspotlight/brokerages/REMOVE_USER';
const REMOVE_USER_FAILED = 'lenderspotlight/brokerages/REMOVE_USER_FAILED';
const REMOVE_USER_SUCCESS = 'lenderspotlight/brokerages/REMOVE_USER_SUCCESS';
const UPDATE = 'lenderspotlight/brokerages/UPDATE';
const UPDATE_COMPENSATION = 'lenderspotlight/brokerages/UPDATE_COMPENSATION';
const UPDATE_COMPENSATION_FAILED = 'lenderspotlight/brokerages/UPDATE_COMPENSATION_FAILED';
const UPDATE_FAILED = 'lenderspotlight/brokerages/UPDATE_FAILED';
const UPDATE_SUCCESS = 'lenderspotlight/brokerages/UPDATE_SUCCESS';
const UPDATE_STATUS = 'lenderspotlight/brokerages/UPDATE_STATUS';
const UPDATE_STATUS_FAILED = 'lenderspotlight/brokerages/UPDATE_STATUS_FAILED';
const UPLOAD = 'lenderspotlight/brokerages/UPLOAD';
const UPLOAD_FAILED = 'lenderspotlight/brokerages/UPLOAD_FAILED';
const UPLOAD_SUCCESS = 'lenderspotlight/brokerages/UPLOAD_SUCCESS';

/**
 * Hydrate Initial State global LS object.
 * @type {Object}
 */
let initialState = {};

if (window.__INITIALSTATE__ && window.__INITIALSTATE__.user) {
  const user = normalize(window.__INITIALSTATE__.user, schema.user);

  initialState = {
    ...initialState,
    ...user.entities.brokerages,
  };
}

if (window.__INITIALSTATE__ && window.__INITIALSTATE__.brokerages) {
  const brokerages = normalize(window.__INITIALSTATE__.brokerages, [schema.brokerage]);

  initialState = {
    ...initialState,
    ...brokerages.entities.brokerages,
  };
}

/**
 * Action Types
 * @type {Object}
 */
export const actionTypes = {
  FETCH,
  FETCH_FAILED,
  FETCH_SUCCESS,
  INVITE,
  INVITE_FAILED,
  INVITE_SUCCESS,
  REMOVE_STATUS,
  REMOVE_STATUS_FAILED,
  REMOVE_USER,
  REMOVE_USER_FAILED,
  REMOVE_USER_SUCCESS,
  UPDATE,
  UPDATE_COMPENSATION,
  UPDATE_COMPENSATION_FAILED,
  UPDATE_FAILED,
  UPDATE_SUCCESS,
  UPDATE_STATUS,
  UPDATE_STATUS_FAILED,
  UPLOAD,
  UPLOAD_FAILED,
  UPLOAD_SUCCESS,
};

/**
 * PropTypes Validation
 * @type {Function}
 */
export const propTypes = PropTypes.shape({
  fetchAll: PropTypes.func,
});

/**
 * ById Brokerage Reducer
 * @param {Object} state
 * @param {Object} action
 * @return {Object}
 */
function byIdReducer(state = initialState, action) {
  const clone = { ...state };
  let brokerage = null;
  switch (action.type) {
    case FETCH_SUCCESS:
    case UPDATE_SUCCESS:
      return {
        ...state,
        ...action.payload.entities.brokerages,
      };

    case UPDATE_COMPENSATION:
      brokerage = clone[action.payload.brokerage_id];

      brokerage.compensations = brokerage.compensations.filter(
        (compensation) => action.payload.lender_id !== compensation.lender_id
      );

      brokerage.compensations.push(action.payload);

      return {
        ...state,
        [action.payload.brokerage_id]: brokerage,
      };

    case UPDATE_STATUS:
      brokerage = clone[action.payload.brokerageId];

      brokerage.statuses = brokerage.statuses.filter((status) => action.payload.status.lender_id !== status.lender_id);

      brokerage.statuses.push(action.payload.status);

      return {
        ...state,
        [action.payload.brokerageId]: brokerage,
      };

    case REMOVE_STATUS:
      brokerage = clone[action.payload.brokerageId];

      brokerage.statuses = brokerage.statuses.filter((status) => action.payload.lender.id !== status.lender_id);

      return {
        ...state,
        [action.payload.brokerageId]: brokerage,
      };

    case UPLOAD:
      return {
        ...state,
        [action.payload.id]: action.payload,
      };

    default:
      return state;
  }
}

/**
 * IsLoading Reducer
 * @param {Boolean} state
 * @param {Object} action
 * @return {Boolean}
 */
function isLoadingReducer(state = false, action) {
  switch (action.type) {
    case FETCH:
    case UPDATE:
      return true;

    case FETCH_FAILED:
    case FETCH_SUCCESS:
    case UPDATE_FAILED:
    case UPDATE_SUCCESS:
      return false;

    default:
      return state;
  }
}

/**
 * IsUploading Reducer
 * @param {Boolean} state
 * @param {Object} action
 * @return {Boolean}
 */
function isUploadingReducer(state = false, action) {
  switch (action.type) {
    case UPLOAD:
      return true;

    case UPLOAD_FAILED:
    case UPLOAD_SUCCESS:
      return false;

    default:
      return state;
  }
}

/**
 * Export Brokerage Reducer
 * @type {Object}
 */
export default combineReducers({
  byId: byIdReducer,
  isLoading: isLoadingReducer,
  isUploading: isUploadingReducer,
});

/**
 * Invite Success Action
 * @param {Object} response
 * @return {Object}
 */
function inviteSuccess(response) {
  const payload = normalize(response.data, schema.invitation);
  return { type: INVITE_SUCCESS, payload };
}

/**
 * Update Failed Action
 * @param {Error} error
 * @return {Object}
 */
function updateFailed(error) {
  const payload = error.response.data.errors;
  return { type: UPDATE_FAILED, error, payload };
}

/**
 * Update Success Action
 * @param {*} response
 * @return {*}
 */
function updateSuccess(response) {
  return {
    type: UPDATE_SUCCESS,
    payload: {
      brokerageName: response.data.name,
      ...normalize(response.data, schema.brokerage),
    },
  };
}

/**
 * Invite User to Brokerage Think
 * @param {Number} brokerageId
 * @param {Object} data
 * @return {Function}
 */
export function invite(brokerageId, data) {
  return (dispatch) => {
    dispatch({ type: INVITE });
    return axios
      .post(`/api/brokerages/${brokerageId}/invitations`, data)
      .then((response) => dispatch(inviteSuccess(response)))
      .catch((error) => dispatch({ type: INVITE_FAILED, error }));
  };
}

/**
 * Remove Status By Brokerage Thunk
 * @param {Number} brokerageId
 * @param {Object} payload
 * @return {Function}
 */
export function removeStatus(brokerageId, lender) {
  return (dispatch) => {
    const payload = {
      lender,
      brokerageId,
    };

    const data = {
      lender_id: lender.id,
    };

    dispatch({ type: REMOVE_STATUS, payload });
    return axios
      .delete(`/api/brokerages/${brokerageId}/statuses`, { data })
      .catch((error) => dispatch({ type: REMOVE_STATUS_FAILED, error }));
  };
}

/**
 * Remove user from brokerage thunk.
 * @param {Nummber} brokerageId
 * @param {Object} user
 * @return {Function}
 */
export function removeUser(brokerageId, user) {
  return (dispatch) => {
    dispatch({ type: REMOVE_USER, payload: user.id });
    return axios
      .delete(`/api/brokerages/${brokerageId}/users/${user.id}`)
      .then((response) => dispatch({ type: REMOVE_USER_SUCCESS, payload: response }))
      .catch((error) => dispatch({ type: REMOVE_USER_FAILED, error }));
  };
}

/**
 * Update Brokerage Thunk
 * @param {Number} brokerageId
 * @param {Object} data
 * @return {Function}
 */
export function update(brokerageId, data) {
  return (dispatch) => {
    dispatch({ type: UPDATE });
    return axios
      .put(`/api/brokerages/${brokerageId}`, data)
      .then((response) => dispatch(updateSuccess(response)))
      .catch((error) => dispatch(updateFailed(error)));
  };
}

/**
 * Update Brokerage Compensation Thunk
 * @param {Number} brokerageId
 * @param {Object} payload
 * @return {Function}
 */
export function updateCompensation(brokerageId, data) {
  return (dispatch) => {
    const payload = {
      brokerage_id: parseInt(brokerageId, 10),
      ...data,
    };

    dispatch({ type: UPDATE_COMPENSATION, payload });
    return axios
      .post(`/api/brokerages/${brokerageId}/compensations`, data)
      .catch((error) => dispatch({ type: UPDATE_COMPENSATION_FAILED, error }));
  };
}

/**
 * Update Status By Brokerage Thunk
 * @param {Number} brokerageId
 * @param {Object} payload
 * @return {Function}
 */
export function updateStatus(brokerageId, status) {
  return (dispatch) => {
    const payload = {
      status,
      brokerageId,
    };

    const data = {
      status_id: status.id,
      lender_id: status.lender_id,
    };

    dispatch({ type: UPDATE_STATUS, payload });
    return axios
      .patch(`/api/brokerages/${brokerageId}/statuses`, data)
      .catch((error) => dispatch({ type: UPDATE_STATUS_FAILED, error }));
  };
}

/**
 * Upload User Avatar Thnuk.
 * @param {Number} brokerageId
 * @param {Object} photo
 * @return {Function}
 */
export function uploadPhoto(brokerageId, photo) {
  return (dispatch, getState) => {
    const brokerage = getState().brokerages.byId[brokerageId];
    const reader = new FileReader();

    reader.readAsDataURL(photo);
    reader.onload = (event) =>
      dispatch({
        type: UPLOAD,
        payload: {
          ...brokerage,
          logo_path: event.target.result,
        },
      });

    const data = new FormData();
    data.append('avatar', photo);

    return axios
      .post(`/api/brokerages/${brokerageId}/avatar`, data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then((response) => dispatch({ type: UPLOAD_SUCCESS, payload: response }))
      .catch((error) => dispatch({ type: UPLOAD_FAILED, error }));
  };
}
