import { createSelector } from 'reselect';

/**
 * Get All Invitations from state.
 * @param {Object} state
 * @return {Object}
 */
const getAllInvitations = (state) => state.invitations.byId;

/**
 * Get all the invitations for a specific brokerage id.
 * @param {Object} state
 * @param {Number} brokerageId
 * @return {Object}
 */
const getInvitationsByBrokerageId = (state, brokerageId) =>
  Object.keys(state.invitations.byId).reduce((accum, invitationId) => {
    const invitation = state.invitations.byId[invitationId];
    if (invitation.brokerage_id.toString() !== brokerageId) return accum;
    return [...accum, state.invitations.byId[invitationId]];
  }, []);

/**
 * Get All Programs.
 * @param {Object} state
 * @return {Array}
 */
export const getAll = createSelector([getAllInvitations], (invitations) =>
  Object.keys(invitations).map((invitationId) => invitations[invitationId])
);

/**
 * Get By Brokerage Id
 * @param {Object} state
 * @return {Array}
 */
export const getByBrokerageId = createSelector([getInvitationsByBrokerageId], (invitations) => invitations);

/**
 * Get the loading state.
 * @param {Object} state
 * @return {Boolean}
 */
export const isLoading = (state) => state.invitations.isLoading;
