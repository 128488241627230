import { createSelector } from 'reselect';

/**
 * Get All Regions from state.
 * @param {Object} state
 * @return {Object}
 */
const getAllRegions = (state) => state.regions.byId;

/**
 * Get All Regions.
 * @param {Object} state
 * @return {Array}
 */
export const getAll = createSelector([getAllRegions], (regions) =>
  Object.keys(regions).map((regionId) => regions[regionId])
);

export default getAll;
