import React from 'react';
import PropTypes from 'prop-types';

const FormInputError = ({ error = null, ...props }) => {
  if (!error) return null;

  return (
    <span className="help-block invalid-feedback" {...props}>
      {error}
    </span>
  );
};

FormInputError.propTypes = {
  error: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
};

export default FormInputError;
