import { useState } from 'react';

const usePersistedState = (key, initialValue = {}) => {
  const [persistedState, _setPersistedState] = useState(() => {
    if (localStorage.getItem(key)) {
      return JSON.parse(localStorage.getItem(key));
    }
    localStorage.setItem(key, JSON.stringify(initialValue));
    return initialValue;
  });

  const setPersistedStateWrapper = (newState) => {
    localStorage.setItem(key, JSON.stringify(newState));
    _setPersistedState(newState);
  };

  return [persistedState, setPersistedStateWrapper];
};

export default usePersistedState;
