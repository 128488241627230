import { useEffect, useState } from 'react';

const useResponsiveWidth = () => {
  const [breakpoints, setBreakpoints] = useState(getBreakpointsMap());

  useEffect(() => {
    const onResize = () => {
      setBreakpoints(getBreakpointsMap());
    };

    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  return breakpoints;
};

export default useResponsiveWidth;

const getBreakpointsMap = () => {
  const {
    body: { clientWidth },
  } = document;
  return {
    isMinSm: clientWidth >= 576,
    isMinMd: clientWidth >= 768,
    isMinLg: clientWidth >= 992,
    isMinXl: clientWidth >= 1200,
    isMinXxl: clientWidth >= 1500,
  };
};
