import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Icon = ({ className = '', name, solid = false }) => (
  <FontAwesomeIcon className={className} icon={[solid ? 'fas' : 'far', name]} />
);

Icon.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  solid: PropTypes.bool,
};

export default Icon;
