import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import schema from 'state/schema';

/**
 * Comparison function to facilitate the sorting
 * of lenders by name.
 * @param  {Object} a
 * @param  {Object} b
 * @return {Number}
 */
const sortByName = (a, b) => {
  if (a.name < b.name) return -1;
  if (a.name > b.name) return 1;
  return 0;
};

/**
 * Get All Lenders from state.
 * @param {Object} state
 * @return {Object}
 */
const getAllLenders = (state) => state.lenders.byId;

/**
 * Get all the state required to denormalize the lender.
 * @param {Object} state
 */
const getState = (state) => ({
  contacts: state.contacts.byId,
  statuses: state.statuses.byId,
  terms: state.terms.byId,
});

/**
 * Get Lender By Id from state.
 * @param {Object} state
 * @param {Number} lenderId
 * @return {Object}
 */
const getLenderById = (state, lenderId) => state.lenders.byId[lenderId] || {};

/**
 * Get Lender By Id.
 * @param {Object} state
 * @return {Object}
 */
export const getById = createSelector([getLenderById, getState], (lender, state) =>
  denormalize(lender, schema.lender, state)
);

/**
 * Get All Lenders.
 * @param {Object} state
 * @return {Array}
 */
export const getAll = createSelector([getAllLenders, getState], (lenders, state) =>
  Object.keys(lenders)
    .map((lenderId) => denormalize(lenders[lenderId], schema.lender, state))
    .sort(sortByName)
);

/**
 * Get loading status from state.
 * @param {Object} state
 * @return {Boolean}
 */
export const isLoading = (state) => state.lenders.isLoading;
