import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { analytics, useOnClickOutside } from 'libs';
import { Button, Panel } from 'components/common';

const FilterPanel = ({
  category = 'rates',
  children,
  footerButtonText = 'Show Results',
  headerButton,
  openButtonContent,
  openButtonTestId,
  panelTestId = null,
  shouldCloseOnOutsideClick = true,
}) => {
  const buttonRef = useRef();
  const panelRef = useRef();

  const [isOpen, setIsOpen] = useState(false);

  const handleClickOutside = () => {
    if (!shouldCloseOnOutsideClick || !isOpen) return;
    onClose();
  };

  useOnClickOutside(handleClickOutside, [buttonRef, panelRef]);

  const onClose = () => {
    analytics.productSearch.filterPanelClose(category);
    setIsOpen(false);
  };

  const onFooterButtonClick = () => {
    analytics.productSearch.filterPanelShowResultsClick(category);
    setIsOpen(false);
  };

  const onOpen = () => {
    analytics.productSearch.filterPanelOpen(category);
    setIsOpen(true);
  };

  const toggleOpen = () => (isOpen ? onClose() : onOpen());

  return (
    <>
      <div className="panel__button bg-athens-gray position-sticky mb-3 pt-2 z-1">
        <Button onClick={toggleOpen} ref={buttonRef} testId={openButtonTestId} variant="primary-dark" width="block">
          {openButtonContent}
        </Button>
      </div>
      <Panel isOpen={isOpen} ref={panelRef} testId={panelTestId}>
        <Panel.Header onClose={onClose}>
          <div className="w-2/5 text-nowrap">{headerButton}</div>
          <div className="w-1/4 text-1_1875 text-center">Filters</div>
        </Panel.Header>
        <Panel.Body>{children}</Panel.Body>
        <Panel.FooterButton onClick={onFooterButtonClick}>{footerButtonText}</Panel.FooterButton>
      </Panel>
    </>
  );
};

FilterPanel.propTypes = {
  category: PropTypes.string,
  children: PropTypes.node.isRequired,
  footerButtonText: PropTypes.string,
  headerButton: PropTypes.node.isRequired,
  openButtonContent: PropTypes.node.isRequired,
  openButtonTestId: PropTypes.string,
  panelTestId: PropTypes.string,
  shouldCloseOnOutsideClick: PropTypes.bool,
};

export default FilterPanel;
