import { createSelector } from 'reselect';

/**
 * Get All Networks from state.
 * @param {Object} state
 * @return {Object}
 */
const getAllNetworks = (state) => state.networks.byId;

/**
 * Get All Networks.
 * @param {Object} state
 * @return {Array}
 */
export const getAll = createSelector([getAllNetworks], (networks) =>
  Object.keys(networks)
    .map((networkId) => networks[networkId])
    .sort((a, b) => {
      const aName = a.name.toUpperCase();
      const bName = b.name.toUpperCase();

      if (aName < bName) return -1;
      if (aName > bName) return 1;
      return 0;
    })
);

export default getAll;
