import axios from 'axios';
import * as Sentry from '@sentry/react';
import './helpers';
import { scraping } from 'libs';
import history from './state/history';

// Destructuring multiple values throws a 'process is not defined' error.
const { APP_ENV } = process.env;
const { SENTRY_DSN_CLIENT } = process.env;
const { VERSION_HASH } = process.env;

/**
 * Configure Sentry
 */
Sentry.init({
  dsn: SENTRY_DSN_CLIENT,
  environment: APP_ENV,
  release: VERSION_HASH,
  ignoreErrors: [
    // Random plugins/extensions
    'top.GLOBALS',
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    'originalCreateNotification',
    'canvas.contentDocument',
    'MyApp_RemoveAllHighlights',
    'http://tt.epicplay.com',
    "Can't find variable: ZiteReader",
    'jigsaw is not defined',
    'ComboSearch is not defined',
    'http://loading.retry.widdit.com/',
    'atomicFindClose',
    // Facebook borked
    'fb_xd_fragment',
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
    // reduce this.
    // See http://stackoverflow.com/questions/4113268
    'bmi_SafeAddOnload',
    'EBCallBackMessageReceived',
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    'conduitPage',
    // Network errors such as going offline or being blocked by a proxy
    'Failed to fetch',
    // Caused by extension: https://stackoverflow.com/questions/50272371/what-is-betterjspop-error-on-chrome
    'Cannot redefine property: BetterJsPop',
  ],
  denyUrls: [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Safari extensions
    /^safari-extension:\/\//i,
    // Firefox extensions
    /^resource:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    // Ignore Google flakiness
    /\/(gtm|ga|analytics)\.js/i,
  ],
});

/**
 * Retrieve CSRF token from template meta tag.
 */
const meta = document.head.querySelector('meta[name="csrf-token"]');
const token = meta.content;

/**
 * Attach CSRF token to global window object.
 */
window.CSRF_TOKEN = token;

/**
 * Attach Google reCAPTCHA V3 site key to window object.
 */
const { RECAPTCHA_SITE_KEY } = process.env;
window.RECAPTCHA_SITE_KEY = RECAPTCHA_SITE_KEY;

/**
 * Configure and enable Axios async library.
 */
window.axios = axios;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token;
window.axios.defaults.headers.common['X-App-Version'] = VERSION_HASH;

window.axios.interceptors.response.use(
  (response) => response,
  (error) => {
    // If the API returns unauthenticated then our API token expired.
    // By refreshing we hit the backend and let it handle the authentication error
    if (error.response?.status === 401) {
      window.location.reload();
      return error;
    }
    return Promise.reject(error);
  }
);

// Setup scraping detection
scraping.initScrapingDetection();
scraping.registerHeaders(axios);

const isCypressEnv = window.location.host === 'localhost:8019';
if (isCypressEnv && window.Intercom && window.intercomSettings) {
  window.Intercom('boot', window.intercomSettings);
}

// Refresh Intercom when the location changes
// Note: Calling update without arguments was ported from previous implementation, but it might actually not be
// the how Intercom recommends us to update URLs in a SPA.
// For more info see: https://www.intercom.com/help/en/articles/170-integrate-intercom-in-a-single-page-app
history.listen(() => {
  if (window.Intercom) {
    window.Intercom('update');
  }
});
