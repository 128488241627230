import { createSelector } from 'reselect';

/**
 * Get All Regions from state.
 * @param {Object} state
 * @return {Object}
 */
const getAllPrices = (state) => state.prices.byId;

/**
 * Get All Prices for Users.
 * @param {Object} state
 * @return {Array}
 */
export const getForUsers = createSelector([getAllPrices], (prices) =>
  Object.keys(prices).reduce((accum, priceId) => {
    const clone = [...accum];
    if (prices[priceId].for === 'users') {
      clone.push(prices[priceId]);
    }
    return clone;
  }, [])
);

/**
 * Get All Prices for Pro Users.
 * @param {Object} state
 * @return {Array}
 */
export const getForProUsers = createSelector([getAllPrices], (prices) =>
  Object.keys(prices).reduce((accum, priceId) => {
    const clone = [...accum];
    if (prices[priceId].for === 'pro-users') {
      clone.push(prices[priceId]);
    }
    return clone;
  }, [])
);

/**
 * Get All Prices for Brokerages.
 * @param {Object} state
 * @return {Array}
 */
export const getForBrokerages = createSelector([getAllPrices], (prices) =>
  Object.keys(prices).reduce((accum, priceId) => {
    const clone = [...accum];
    if (prices[priceId].for === 'brokerages') {
      clone.push(prices[priceId]);
    }
    return clone;
  }, [])
);

/**
 * Get All Prices.
 * @param {Object} state
 * @return {Array}
 */
export const getAll = createSelector([getAllPrices], (prices) => Object.keys(prices).map((priceId) => prices[priceId]));
