import PropTypes from 'prop-types';
import { combineReducers } from 'redux';
import { normalize } from 'normalizr';
import schema from 'state/schema';
import { actionTypes as lenders } from './lenders';

const ADD = 'lenderspotlight/contacts/ADD';
const ADD_FAILED = 'lenderspotlight/contacts/ADD_FAILED';
const FETCH = 'lenderspotlight/contacts/FETCH';
const FETCH_FAILED = 'lenderspotlight/contacts/FETCH_FAILED';
const FETCH_SUCCESS = 'lenderspotlight/contacts/FETCH_SUCCESS';
const REMOVE = 'lenderspotlight/contacts/REMOVE';
const REMOVE_FAILED = 'lenderspotlight/contacts/REMOVE_FAILED';

/**
 * Action Types
 * @type {Object}
 */
export const actionTypes = {
  ADD,
  ADD_FAILED,
  FETCH,
  FETCH_FAILED,
  FETCH_SUCCESS,
  REMOVE,
  REMOVE_FAILED,
};

/**
 * PropTypes Validation
 * @type {Function}
 */
export const propTypes = PropTypes.shape({
  fetchAll: PropTypes.func,
});

/**
 * Hydrate initial state object
 * @type {Object}
 */
let initialState = {};

if (window.__INITIALSTATE__ && window.__INITIALSTATE__.contacts) {
  const data = normalize(window.__INITIALSTATE__.contacts, [schema.contact]);
  initialState = data.entities.contacts || {};
}

/**
 * ById Contact Reducer
 * @param {Object} state
 * @param {Object} action
 * @return {Object}
 */
function byIdReducer(state = initialState, action) {
  switch (action.type) {
    case lenders.FETCH_SUCCESS:
    case FETCH_SUCCESS:
      return {
        ...state,
        ...action.payload.entities.contacts,
      };

    default:
      return state;
  }
}

function activeReducer(state = [], action) {
  const clone = [...state];
  switch (action.type) {
    case FETCH_SUCCESS:
      return [...state, ...action.payload.result];

    case ADD_FAILED:
    case REMOVE:
      if (clone.indexOf(action.payload) > -1) {
        clone.splice(clone.indexOf(action.payload), 1);
      }
      return clone;

    case ADD:
    case REMOVE_FAILED:
      return [...state, action.payload];

    default:
      return state;
  }
}

/**
 * Export Lender Reducer
 * @type {Object}
 */
export default combineReducers({
  active: activeReducer,
  byId: byIdReducer,
});

/**
 * Fetch Contacts Success Action
 * @param {Object} response
 * @return {Object}
 */
function fetchSuccess(response) {
  const contacts = normalize(response, [schema.contact]);
  return { type: FETCH_SUCCESS, payload: contacts };
}

/**
 * Fetch Contacts Failed Action
 * @param {Object} errors
 * @return {Object}
 */
function fetchFailed(errors) {
  return { type: FETCH_FAILED, errors };
}

/**
 * Fetch Contacts Thunk
 * @return {Function}
 */
export function fetchAll() {
  return (dispatch) => {
    dispatch({ type: FETCH });
    return axios
      .get('/api/contacts')
      .then((response) => dispatch(fetchSuccess(response.data)))
      .catch((error) => dispatch(fetchFailed(error)));
  };
}

/**
 * Fetch Contacts Thunk
 * @return {Function}
 */
export function fetchByBrokerageId(brokerageId) {
  return (dispatch) => {
    dispatch({ type: FETCH });
    return axios
      .get(`/api/brokerages/${brokerageId}/contacts`)
      .then((response) => dispatch(fetchSuccess(response.data)))
      .catch((error) => dispatch(fetchFailed(error)));
  };
}

/**
 * Add contact to brokerage
 * @param {Integer} brokerageId
 * @param {Integer} contactId
 * @return {Function}
 */
function addById(brokerageId, contactId) {
  return (dispatch) => {
    dispatch({ type: ADD, payload: contactId });
    return axios
      .post(`/api/brokerages/${brokerageId}/contacts/${contactId}`)
      .catch((error) => dispatch({ type: ADD_FAILED, error, payload: contactId }));
  };
}

/**
 * Add contact to brokerage
 * @param {Integer} brokerageId
 * @param {Integer} contactId
 * @return {Function}
 */
function removeById(brokerageId, contactId) {
  return (dispatch) => {
    dispatch({ type: REMOVE, payload: contactId });
    return axios
      .delete(`/api/brokerages/${brokerageId}/contacts/${contactId}`)
      .catch((error) => dispatch({ type: REMOVE_FAILED, error, payload: contactId }));
  };
}

/**
 * Adds or removes contact from brokerage depending on current active state
 * @param {Integer} brokerageId
 * @param {Integer} contactId
 * @return {Function}
 */
export function toggleContact(brokerageId, contactId) {
  return (dispatch, getState) => {
    const { active } = getState().contacts;
    return active.includes(contactId)
      ? removeById(brokerageId, contactId)(dispatch)
      : addById(brokerageId, contactId)(dispatch);
  };
}
