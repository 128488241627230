import { createSelector } from 'reselect';

const getUserById = (state, userId) => state.users.byId[userId];

export const denormalizeUser = (user) => user;

export const getById = createSelector([getUserById], (user) => denormalizeUser(user));

/**
 * Get all the users for a specific brokerage id.
 * @param {Object} state
 * @param {Number} brokerageId
 * @return {Object}
 */
export const getByBrokerageId = (state, brokerageId) =>
  Object.keys(state.users.byId).reduce((accum, userId) => {
    const user = state.users.byId[userId];
    if (user.brokerage_id.toString() !== brokerageId.toString()) return accum;
    return [...accum, state.users.byId[userId]];
  }, []);

export const getCurrentUser = (state) => state.users.currentUser;

export const isLoading = (state) => state.users.isLoading;

export const isUploading = (state) => state.users.isUploading;
