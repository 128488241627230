import useEventListener from './useEventListener';

/**
 * @param {Function} callback
 * @param {(Object|Array)} elRefs Either a single element ref or an array of them
 */
const useOnClickOutside = (callback, elRefs) => {
  const handleOutsideClick = (e) => {
    const innerElRefs = Array.isArray(elRefs) ? elRefs : [elRefs];
    const isClickInside = innerElRefs.some((ref) => ref.current?.contains(e.target));

    if (!isClickInside) callback(e);
  };

  // The `useCapture` arg is set to `true` to ensure the event will dispatch
  // even if the target stops the event from bubbling.
  useEventListener(document, 'click', handleOutsideClick, {
    useCapture: true,
  });
};

export default useOnClickOutside;
