import React from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import kebabCase from 'lodash/kebabCase';
import classnames from 'classnames';
import { analytics } from 'libs';
import { Tooltip } from 'components/common';
import Upgrade from './Upgrade';

const Filter = ({ children, isPro, name, tooltip = null }) => {
  const trackTooltipVisibility = debounce(
    (isVisible) => (isVisible ? analytics.productSearch.tooltipDisplay(name) : null),
    1500
  );

  return (
    <div
      className={classnames(`filter filter--${kebabCase(name)}`, {
        'filter--pro': isPro,
      })}
    >
      <h3 className="d-flex align-items-center">
        {name}
        {tooltip && <Tooltip onVisibilityChange={trackTooltipVisibility}>{tooltip}</Tooltip>}
        <Upgrade isPro={isPro} onClick={analytics.upgrade.click(`Rate Filter: ${name}`)} />
      </h3>
      {children}
    </div>
  );
};

Filter.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  isPro: PropTypes.bool,
  name: PropTypes.string.isRequired,
  tooltip: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
};

export default Filter;
