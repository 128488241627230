import { createSelector } from 'reselect';

const getPolicyIdsParam = (state, policyIds) => policyIds;

const getAllPolicies = (state) => state.policies.byId;

/**
 * Retrieves a policy by ID.
 * @param {*} state
 */
/* eslint-disable-next-line import/prefer-default-export */
export const getByIds = createSelector([getAllPolicies, getPolicyIdsParam], (policies, policyIds) =>
  policyIds.reduce((byId, id) => byId.concat(policies[id] ?? []), [])
);
