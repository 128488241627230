import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Modal from 'react-bootstrap/Modal';
import pick from 'lodash/pick';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { actions, selectors } from 'state';
import { ProfessionalDetailsForm, ProfileDetailsForm } from 'components/users';

const PageIndicators = ({ page }) => (
  <div className="d-flex justify-content-between mt-4 px-4 w-100">
    <div className="d-flex align-items-center">
      <div
        className={classnames('modal-page-indicator', {
          'bg-primary': page === 1,
          'bg-silver': page === 2,
        })}
      />
      <div
        className={classnames('modal-page-indicator', {
          'bg-primary': page === 2,
          'bg-silver': page === 1,
        })}
      />
    </div>
    <div className="fw-bold text-silver">{`${page}/2`}</div>
  </div>
);

PageIndicators.propTypes = {
  page: PropTypes.number.isRequired,
};

const ProfileDetails = ({ errors, handleSubmit, isSubmitting, onSubmit, register, setValue }) => (
  <div className="mx-4">
    <img alt="" className="my-4" src="/images/lender-spotlight-logo-horizontal.svg" width="175" />
    <Modal.Title className="fw-bold py-3 text-2">Profile details</Modal.Title>
    <div>Please complete the following details. You can edit this anytime in Settings.</div>
    <ProfileDetailsForm
      errors={errors}
      handleSubmit={handleSubmit}
      isSubmitting={isSubmitting}
      onSubmit={onSubmit}
      register={register}
      setValue={setValue}
    />
  </div>
);

ProfileDetails.propTypes = {
  errors: PropTypes.shape({
    email: PropTypes.shape({
      message: PropTypes.string,
    }),
    name: PropTypes.shape({
      message: PropTypes.string,
    }),
    phone_number: PropTypes.shape({
      message: PropTypes.array,
    }),
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
};

const ProfessionalDetails = ({ onBackClick, onSubmit }) => (
  <div className="mx-4">
    <img alt="" className="my-4" src="/images/lender-spotlight-logo-horizontal.svg" width="175" />
    <Modal.Title className="fw-bold py-3 text-2">Almost There!</Modal.Title>
    <div>Please tell us a bit more about yourself to help tailor your experience.</div>
    <ProfessionalDetailsForm onBackClick={onBackClick} onSubmit={onSubmit} />
  </div>
);

ProfessionalDetails.propTypes = {
  onBackClick: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

const UserDetailsModal = ({ dispatch, user }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [page, setPage] = useState(1);

  const schema = yup.object({
    name: yup.string().required('The name field is required.'),
    email: yup.string().email('Email format is invalid').required('The email field is required.'),
  });

  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    register,
    setError,
    setValue,
  } = useForm({
    defaultValues: pick(user, ['email', 'name', 'phone_number']),
    resolver: yupResolver(schema),
  });

  const ineligibleUser = !user.features.can_subscribe_to_platinum || user.answered_questionnaire;

  useEffect(() => {
    if (ineligibleUser) setIsOpen(false);
  }, [ineligibleUser]);

  const onProfileDetailsSubmit = (formValues) =>
    dispatch.users.update(user.id, formValues).then((action) => {
      if (action.type.includes('FAILED')) {
        if (action.payload.phone_number) {
          setError('phone_number', {
            type: 'custom',
            message: action.payload.phone_number,
          });
        }
      }

      if (action.type.includes('SUCCESS')) {
        setPage(2);
      }
    });

  const onProfessionalDetailsSubmit = (formValues) =>
    dispatch.users
      .answerQuestionnaire(user.id, formValues)
      .then(() => {
        toast('Thank you, your details have been updated.', {
          type: 'success',
          toastId: 'toast-success',
        });
      })
      .finally(() => setIsOpen(false));

  return (
    <Modal backdrop="static" centered className="modal__single-section" scrollable show={isOpen}>
      <Modal.Header>
        <PageIndicators page={page} />
      </Modal.Header>
      <Modal.Body>
        {page === 1 ? (
          <ProfileDetails
            errors={errors}
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
            onSubmit={onProfileDetailsSubmit}
            register={register}
            setValue={setValue}
          />
        ) : (
          <ProfessionalDetails onBackClick={() => setPage(1)} onSubmit={onProfessionalDetailsSubmit} />
        )}
      </Modal.Body>
    </Modal>
  );
};

UserDetailsModal.propTypes = {
  dispatch: PropTypes.shape({
    users: actions.users.propTypes.isRequired,
  }).isRequired,
  user: PropTypes.shape({
    answered_questionnaire: PropTypes.bool,
    features: PropTypes.shape({
      can_subscribe_to_platinum: PropTypes.bool,
    }),
    id: PropTypes.number.isRequired,
  }).isRequired,
};

const mapState = (state) => ({
  user: selectors.users.getCurrentUser(state),
});

const mapDispatch = (dispatch) => ({
  dispatch: {
    users: bindActionCreators(actions.users, dispatch),
  },
});

export default withRouter(connect(mapState, mapDispatch)(UserDetailsModal));
