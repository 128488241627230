import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'components/common';
import { Select } from 'components/forms';
import { useForm } from 'react-hook-form';

const digitalMortgagePlatformOptions = [
  { label: 'Finmo', value: 'Finmo' },
  { label: 'Filogix Expert', value: 'Filogix Expert' },
  { label: 'Filogix Expert Pro', value: 'Filogix Expert Pro' },
  { label: 'Velocity', value: 'Velocity' },
  { label: 'Boss', value: 'Boss' },
  { label: 'Other', value: 'Other' },
  {
    label: "I don't use a digital mortgage platform",
    value: "I don't use a digital mortgage platform",
  },
];

const jobFunctionOptions = [
  { label: 'Broker', value: 'Broker' },
  { label: 'Broker Owner', value: 'Broker Owner' },
  { label: 'Executive', value: 'Executive' },
  {
    label: 'Management at Financial Institution',
    value: 'Management at Financial Institution',
  },
  { label: 'Management at Alt Lender', value: 'Management at Alt Lender' },
  {
    label: 'Management at Technology Company',
    value: 'Management at Technology Company',
  },
  { label: 'Mortgage Specialist', value: 'Mortgage Specialist' },
  { label: 'Underwriter', value: 'Underwriter' },
  { label: 'Sales', value: 'Sales' },
  { label: 'Other', value: 'Other' },
];

const brokerageNetworkOptions = [
  { label: "I don't belong to a network", value: "I don't belong to a network" },
  { label: 'BrokerOne', value: 'BrokerOne' },
  { label: 'Centum', value: 'Centum' },
  { label: 'Dominion Lending', value: 'Dominion Lending' },
  { label: 'Independent', value: 'Independent' },
  { label: 'TMAC/Mortgage Alliance', value: 'TMAC/Mortgage Alliance' },
  { label: 'Verico', value: 'Verico' },
  { label: 'Verico - Tango', value: 'Verico - Tango' },
  { label: 'CIMBC', value: 'CIMBC' },
  { label: 'Unknown', value: 'Unknown' },
  { label: 'Other', value: 'Other' },
];

const ProfessionalDetailsForm = ({ onBackClick, onSubmit }) => {
  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
  } = useForm({
    defaultValues: {
      digital_mortgage_platform: '',
      job_function: '',
      brokerage_network: '',
    },
  });

  return (
    <form className="py-4" onSubmit={handleSubmit(onSubmit)}>
      <div className="py-4">
        <Select
          control={control}
          name="digital_mortgage_platform"
          label="What digital mortgage platform do you use?"
          options={digitalMortgagePlatformOptions}
          overflowContainer
        />
        <Select
          control={control}
          name="job_function"
          label="What is your job function?"
          options={jobFunctionOptions}
          overflowContainer
        />
        <Select
          control={control}
          name="brokerage_network"
          label="Do you belong to a brokerage network?"
          options={brokerageNetworkOptions}
          overflowContainer
        />
      </div>
      <Button disabled={isSubmitting} submit variant="primary-dark" width="block">
        Continue to Lender Spotlight
      </Button>
      <Button onClick={onBackClick} variant="link-inline-strong" width="block">
        Back
      </Button>
    </form>
  );
};

ProfessionalDetailsForm.propTypes = {
  onBackClick: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ProfessionalDetailsForm;
