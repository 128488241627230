import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Markdown from 'react-markdown';
import { analytics } from 'libs';
import CompareGuidelineRow from './CompareGuidelineRow';

const CompareGuidelines = ({ policies, products }) => {
  const [expandedRows, setExpandedRows] = useState([]);

  /**
   * Displays a guideline for a product by policy name.
   * @param {*} product
   * @param {*} policy
   * @param {String} name
   */
  const displayGuidelines = (product, policy) => {
    const guidelines = product.guidelines[policy.name]?.description;
    return guidelines ? (
      <Markdown key={product.id}>
        {expandedRows.includes(policy.name) ? guidelines : `${guidelines.substring(0, 75).replace(/\*\*/g, '')}...`}
      </Markdown>
    ) : (
      <div key={product.id} className="compare-guideline-row__empty">
        --
      </div>
    );
  };

  /**
   * Toggles expanded or collapsed state for a row.
   * @param {String} policy
   */
  const toggleExpanded = (policy) => {
    if (expandedRows.includes(policy)) {
      setExpandedRows(expandedRows.filter((row) => row !== policy));
      analytics.compare.policyCollapse(policy);
    } else {
      setExpandedRows((rows) => [...rows, policy]);
      analytics.compare.policyExpand(policy);
    }
  };

  return (
    <>
      {policies.map((policy) => (
        <CompareGuidelineRow
          expanded={expandedRows.includes(policy.name)}
          key={policy.id}
          name={policy.name}
          onClick={toggleExpanded}
        >
          {products.map((product) => displayGuidelines(product, policy))}
        </CompareGuidelineRow>
      ))}
    </>
  );
};

CompareGuidelines.propTypes = {
  policies: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ).isRequired,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    })
  ).isRequired,
};

export default CompareGuidelines;
