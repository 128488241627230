import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const CompareGuidelineRow = ({ children, expanded = false, name, onClick }) => (
  <div className={classNames('compare-guideline-row', { expanded })}>
    <div className="compare-guideline-row__policy">
      <button type="button" className="compare-guideline-row__holder" onClick={() => onClick(name)}>
        <span className="compare-guideline-row__name">{name}</span>
        <img className="compare-guideline-row__icon" src="/images/caret.svg" alt="collapse indicator arrow" />
      </button>
    </div>
    {children.map((value, index) => (
      /* eslint-disable-next-line react/no-array-index-key */
      <div key={index} className="compare-guideline-row__values">
        {value}
      </div>
    ))}
  </div>
);

CompareGuidelineRow.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  expanded: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default CompareGuidelineRow;
