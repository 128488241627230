import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-bootstrap/Modal';

const VARIANT_DEFAULT = 'default';
const VARIANT_SINGLE_SECTION = 'single-section';

/**
 * @param {String} variant
 * @return {(String|Null)} String is returned if a special variant, otherwise `null`
 */
const getVariantClassName = (variant) => (variant === VARIANT_SINGLE_SECTION ? 'modal__single-section' : null);

const Modal = ({
  backdrop = true,
  centered = false,
  children,
  footer = null,
  heading = '',
  onHide,
  show,
  variant = VARIANT_DEFAULT,
}) => (
  <ReactModal
    backdrop={backdrop}
    centered={centered}
    className={getVariantClassName(variant)}
    onHide={onHide}
    scrollable
    show={show}
  >
    <ReactModal.Header closeButton>{heading && <ReactModal.Title>{heading}</ReactModal.Title>}</ReactModal.Header>
    <ReactModal.Body>{children}</ReactModal.Body>
    {footer && <ReactModal.Footer>{footer}</ReactModal.Footer>}
  </ReactModal>
);

Modal.propTypes = {
  backdrop: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  centered: PropTypes.bool,
  children: PropTypes.node.isRequired,
  footer: PropTypes.node,
  heading: PropTypes.string,
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  variant: PropTypes.oneOf([VARIANT_DEFAULT, VARIANT_SINGLE_SECTION]),
};

export default Modal;
