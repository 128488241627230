import React from 'react';
import PropTypes from 'prop-types';
import { useController } from 'react-hook-form';
import { Select } from 'components/common';

const HookSelect = ({ control, label, name, options, overflowContainer = false }) => {
  const { field } = useController({ control, name });

  const handleSelected = (opts, value) => opts.find((item) => item.value === value);

  return (
    <div className="my-3">
      <label htmlFor={field.name} className="fw-bold">
        {label}
      </label>
      <Select
        clearValue=""
        onChange={(selectedOption) => field.onChange(selectedOption.value)}
        options={options}
        overflowContainer={overflowContainer}
        placeholder="Select an option"
        selected={handleSelected(options, field.value)}
      />
    </div>
  );
};

HookSelect.propTypes = {
  control: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  overflowContainer: PropTypes.bool,
};

export default HookSelect;
