const NOTIFICATION_ADD = 'lenderspotlight/notifications/NOTIFICATION_ADD';

export default function notificationReducer(state = [], action) {
  if (action.type === NOTIFICATION_ADD) {
    return [...state, action.payload];
  }
  return state;
}

/**
 * Send global notification to the app.
 * In the future can attach additional data to notifications (e.g. time-out, status).
 * @param {{id: String, message: String}} notification
 */
export const notify = (notification) => ({
  payload: { id: notification.id, message: notification.message },
  type: NOTIFICATION_ADD,
});
