import React from 'react';
import PropTypes from 'prop-types';

const CompareHeadingRow = ({ products }) => (
  <div className="compare-heading-row">
    <div className="compare-heading-row__property" />
    {products.map((product) => {
      const logoPath = product.lender.logo_path || '/images/spotlight-logo-200x200.png';
      return (
        <div key={product.id} className="compare-heading-row__values">
          <img className="compare-heading-row__logo" src={logoPath} alt={product.lender.name} />
          <div>
            <div className="compare-heading-row__heading">{product.lender.name}</div>
            <div className="compare-heading-row__sub-heading">{product.name}</div>
            <div className="compare-heading-row__rate">{`${parseFloat(product.rate.rate).toFixed(2)}%`}</div>
          </div>
        </div>
      );
    })}
  </div>
);

CompareHeadingRow.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({
      lender: PropTypes.shape({
        logo_path: PropTypes.string,
        name: PropTypes.string,
      }),
      name: PropTypes.string,
      rate: PropTypes.shape({
        rate: PropTypes.number,
      }),
    })
  ).isRequired,
};

export default CompareHeadingRow;
