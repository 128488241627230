import algoliasearch from 'algoliasearch';
import moment from 'moment';
import api from './api';

const algoliaAppId = window.__INITIALSTATE__?.config?.algolia?.app_id;
const guidelinesIndex = window.__INITIALSTATE__?.config?.algolia?.index_guidelines;

/**
 * Returns a promise containing the policy filter results.
 * @param {String[]} lenders
 * @param {String[]} policies
 * @return {Promise}
 */
const filterByLendersAndPolicies = async (lenders, policies) => {
  const lenderFilter = lenders.map((lender) => `lender:"${lender}"`).join(' OR ');
  const policyFilter = policies.map((policy) => `policy:"${policy}"`).join(' OR ');
  const filters = `${lenderFilter} AND ${policyFilter}`;
  const client = await getClient();

  return client.search('', { filters, hitsPerPage: 10000 }).then(({ hits }) => hits);
};

const getClient = async () => {
  const token = await getToken();

  return algoliasearch(algoliaAppId, token).initIndex(guidelinesIndex);
};

const getToken = (() => {
  let cachedToken = '';
  let validUntil = 0;

  return async () => {
    const expiryBuffer = 30;
    if (moment().unix() > validUntil - expiryBuffer) {
      const response = await fetchToken();
      cachedToken = response?.token;
      validUntil = response?.valid_until;
    }
    return cachedToken;
  };
})();

const fetchToken = async () => api.post('guidelines/tokens');

export default {
  algoliaAppId,
  filterByLendersAndPolicies,
  getToken,
  guidelinesIndex,
};
