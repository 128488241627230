import PropTypes from 'prop-types';
import { combineReducers } from 'redux';
import { normalize } from 'normalizr';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import schema from 'state/schema';
import { getComparedProductsCount } from 'state/selectors/products';
import { actionTypes as filters } from './filters';
import { notify } from './notifications';

const ADD_COMPARED = 'lenderspotlight/products/ADD_COMPARED';
const ADD_EXCLUSIVE = 'lenderspotlight/products/ADD_EXCLUSIVE';
const ADD_EXCLUSIVE_FAILED = 'lenderspotlight/products/ADD_EXCLUSIVE_FAILED';
const ADD_EXCLUSIVE_SUCCESS = 'lenderspotlight/products/ADD_EXCLUSIVE_SUCCESS';
const CLEAR_COMPARED = 'lenderspotlight/products/CLEAR_COMPARED';
const CLEAR_RESULTS = 'lenderspotlight/products/CLEAR_RESULTS';
const FETCH = 'lenderspotlight/products/FETCH';
const FETCH_BY_BROKERAGE_ID_SUCCESS = 'lenderspotlight/products/FETCH_BY_BROKERAGE_ID_SUCCESS';
const FETCH_BY_COMPARED_SUCCESS = 'lenderspotlight/products/FETCH_BY_COMPARED_SUCCESS';
const FETCH_BY_ID_SUCCESS = 'lenderspotlight/products/FETCH_BY_ID_SUCCESS';
const FETCH_BY_LENDER_ID_SUCCESS = 'lenderspotlight/products/FETCH_BY_LENDER_ID_SUCCESS';
const FETCH_FAILED = 'lenderspotlight/products/FETCH_FAILED';
const FETCH_MORE_SUCCESS = 'lenderspotlight/products/FETCH_MORE_SUCCESS';
const FETCH_SUCCESS = 'lenderspotlight/products/FETCH_SUCCESS';
const HIDE_COMPARE = 'lenderspotlight/products/HIDE_COMPARE';
const REFRESH = 'lenderspotlight/products/FETCH';
const REMOVE_BY_ID = 'lenderspotlight/products/REMOVE_BY_ID';
const REMOVE_BY_ID_FAILED = 'lenderspotlight/products/REMOVE_BY_ID_FAILED';
const REMOVE_COMPARED = 'lenderspotlight/products/REMOVE_COMPARED';
const RESET_COMPARED = 'lenderspotlight/products/RESET_COMPARED';
const RESET_STALE_COMPARED = 'lenderspotlight/products/RESET_STALE_COMPARED';
const SET_STALE_COMPARED = 'lenderspotlight/products/SET_STALE_COMPARED';
const SHOW_COMPARE = 'lenderspotlight/products/SHOW_COMPARE';
const TOGGLE_COMPARED = 'lenderspotlight/products/TOGGLE_COMPARED';
const TOGGLE_COMPARE_VISIBLE = 'lenderspotlight/products/TOGGLE_COMPARE_VISIBLE';
const UPDATE_EXCLUSIVE = 'lenderspotlight/products/UPDATE_EXCLUSIVE';
const UPDATE_EXCLUSIVE_FAILED = 'lenderspotlight/products/UPDATE_EXCLUSIVE_FAILED';
const UPDATE_EXCLUSIVE_SUCCESS = 'lenderspotlight/products/UPDATE_EXCLUSIVE_SUCCESS';

const COMPARE_LIMIT = 10;

/**
 * Action Types
 * @type {Object}
 */
export const actionTypes = {
  ADD_COMPARED,
  ADD_EXCLUSIVE,
  ADD_EXCLUSIVE_FAILED,
  ADD_EXCLUSIVE_SUCCESS,
  CLEAR_COMPARED,
  FETCH,
  FETCH_BY_BROKERAGE_ID_SUCCESS,
  FETCH_BY_ID_SUCCESS,
  FETCH_BY_LENDER_ID_SUCCESS,
  FETCH_FAILED,
  FETCH_MORE_SUCCESS,
  FETCH_SUCCESS,
  REFRESH,
  REMOVE_BY_ID,
  REMOVE_BY_ID_FAILED,
  REMOVE_COMPARED,
  TOGGLE_COMPARED,
  TOGGLE_COMPARE_VISIBLE,
  UPDATE_EXCLUSIVE,
  UPDATE_EXCLUSIVE_FAILED,
  UPDATE_EXCLUSIVE_SUCCESS,
};

/**
 * Initial State.
 * @type {Object}
 */
const initialState = {
  meta: {
    currentPage: 1,
    hasMore: false,
    perPage: null,
    total: null,
  },
};

/**
 * PropTypes Validation
 * @type {Function}
 */
export const propTypes = PropTypes.shape({
  addExclusive: PropTypes.func,
  fetchAll: PropTypes.func,
  fetch: PropTypes.func,
  fetchById: PropTypes.func,
  refresh: PropTypes.func,
  removeExclusiveById: PropTypes.func,
  updateExclusiveById: PropTypes.func,
  upsertExclusiveById: PropTypes.func,
});

/**
 * ById Products Reducer
 * @param {Object} state
 * @param {Object} action
 * @return {Object}
 */
function byIdReducer(state = {}, action) {
  let omit; // eslint-disable-line no-unused-vars
  let newState;

  switch (action.type) {
    case ADD_EXCLUSIVE_SUCCESS:
    case FETCH_BY_BROKERAGE_ID_SUCCESS:
    case FETCH_BY_COMPARED_SUCCESS:
    case FETCH_BY_ID_SUCCESS:
    case FETCH_BY_LENDER_ID_SUCCESS:
    case FETCH_MORE_SUCCESS:
    case FETCH_SUCCESS:
    case UPDATE_EXCLUSIVE_SUCCESS:
      return {
        ...state,
        ...action.payload.entities.products,
      };

    case FETCH_FAILED:
      return {};

    case REMOVE_BY_ID:
      ({ [action.productId]: omit, ...newState } = state);

      return newState;

    default:
      return state;
  }
}

/**
 * Products associated with Brokerage reducer.
 * @param  {Array}  state
 * @param  {Object} action
 * @return {Array}
 */
function forBrokerageReducer(state = [], action) {
  switch (action.type) {
    case FETCH_BY_BROKERAGE_ID_SUCCESS:
      return action.payload.result;

    case REMOVE_BY_ID:
      return state.filter((productId) => productId !== action.payload);

    default:
      return state;
  }
}

/**
 * Products fetch meta reducer.
 * @param  {Array}  state
 * @param  {Object} action
 * @return {Array}
 */
function metaReducer(state = initialState.meta, action) {
  switch (action.type) {
    case FETCH_BY_LENDER_ID_SUCCESS:
    case FETCH_MORE_SUCCESS:
    case FETCH_SUCCESS:
      return action.payload.meta;

    case CLEAR_RESULTS:
    case FETCH_FAILED:
      return initialState.meta;

    default:
      return state;
  }
}

/**
 * The reason for counting pending requests and using an `isLoading`
 * selector to determine load state is to handle the following cases:
 *
 * - A single action is called multiple times in quick succession
 * - Calling multiple different actions at the same time
 *
 * Previously the above situations would result in the first API action
 * response changing the load state to `false`, even though there are
 * pending API actions.
 */
const pendingRequestsCountReducer = (state = 0, { type }) => {
  switch (type) {
    case FETCH:
    case ADD_EXCLUSIVE:
    case UPDATE_EXCLUSIVE:
      return state + 1;

    case ADD_EXCLUSIVE_FAILED:
    case ADD_EXCLUSIVE_SUCCESS:
    case FETCH_BY_BROKERAGE_ID_SUCCESS:
    case FETCH_BY_COMPARED_SUCCESS:
    case FETCH_BY_ID_SUCCESS:
    case FETCH_BY_LENDER_ID_SUCCESS:
    case FETCH_FAILED:
    case FETCH_MORE_SUCCESS:
    case FETCH_SUCCESS:
    case UPDATE_EXCLUSIVE_FAILED:
    case UPDATE_EXCLUSIVE_SUCCESS:
      return state - 1;

    default:
      return state;
  }
};

/**
 * Products filtering results reducer.
 * @param  {Array}  state
 * @param  {Object} action
 * @return {Array}
 */
function resultsReducer(state = [], action) {
  let newState;

  switch (action.type) {
    case FETCH_MORE_SUCCESS:
      return [...state, ...action.payload.result];

    case FETCH_BY_LENDER_ID_SUCCESS:
    case FETCH_SUCCESS:
      return action.payload.result;

    case CLEAR_RESULTS:
    case FETCH_FAILED:
      return [];

    case REMOVE_BY_ID:
      newState = [...state];
      newState.splice(newState.indexOf(action.payload), 1);
      return newState;

    default:
      return state;
  }
}

/**
 * Reduce compared product actions
 * @param {Number[]} state
 * @param {Object} action
 * @return {Number[]}
 */
function comparedReducer(state = [], action) {
  const remove = (ids, id) => ids.filter((x) => x !== id);
  const prepend = (ids, id) => [id, ...ids];

  switch (action.type) {
    case REMOVE_COMPARED:
      return remove(state, action.id);

    case ADD_COMPARED:
      return prepend(state, action.id);

    case CLEAR_COMPARED:
      return [];

    case RESET_COMPARED:
      return action.payload;

    default:
      return state;
  }
}

/**
 * Tracks number of cached product IDs that have been removed.
 * @param {Number} [state=0]
 * @param {Object} action
 * @return {Number}
 */
function staleComparedReducer(state = 0, action) {
  switch (action.type) {
    case SET_STALE_COMPARED:
      return action.payload;

    case RESET_STALE_COMPARED:
      return 0;

    default:
      return state;
  }
}

/**
 * Reduce compare visible actions.
 * @param {Boolean} [state=false]
 * @param {Object} action
 * @return {Boolean}
 */
function compareVisibleReducer(state = false, action) {
  switch (action.type) {
    case SHOW_COMPARE:
      return true;
    case HIDE_COMPARE:
      return false;
    case TOGGLE_COMPARE_VISIBLE:
      return !state;
    case CLEAR_COMPARED:
      return false;
    default:
      return state;
  }
}

/**
 * Product Reducer
 * @type {Object}
 */
export default persistReducer(
  { key: 'products', whitelist: 'compared', storage },
  combineReducers({
    byId: byIdReducer,
    staleCompared: staleComparedReducer,
    compareVisible: compareVisibleReducer,
    compared: comparedReducer,
    forBrokerage: forBrokerageReducer,
    meta: metaReducer,
    pendingRequestsCount: pendingRequestsCountReducer,
    results: resultsReducer,
  })
);

/**
 * Add Exclusive Success Action
 * @param {Object} response
 * @return {Object}
 */
function addExclusiveSuccess(response) {
  const payload = normalize(response.data, schema.product);
  return { type: ADD_EXCLUSIVE_SUCCESS, payload };
}

/**
 * Get the meta values from the response.
 * @param  {Object} headers
 * @return {Object}
 */
function getMeta(headers = null) {
  return headers === null
    ? {}
    : {
        currentPage: parseInt(headers['x-current-page'], 10),
        hasMore: headers['x-has-more'] === 'true' || false,
        perPage: parseInt(headers['x-per-page'], 10),
        total: parseInt(headers['x-total-count'], 10),
      };
}

/**
 * Fetch By Id Success Action
 * @param {Object} response
 * @return {Object}
 */
function fetchByIdSuccess(response) {
  const payload = normalize(response.data, schema.product);
  return { type: FETCH_BY_ID_SUCCESS, payload };
}

/**
 * Fetch Products by Brokerage Success Action
 * @param {Object} response
 * @return {Object}
 */
function fetchByBrokerageIdSuccess(response) {
  const payload = normalize(response.data, [schema.product]);
  return { type: FETCH_BY_BROKERAGE_ID_SUCCESS, payload };
}

/**
 * Fetch Products by Lender Success Action
 * @param {Object} response
 * @return {Object}
 */
function fetchByLenderIdSuccess(response) {
  const payload = {
    ...normalize(response.data, [schema.product]),
    meta: getMeta(response.headers),
  };

  return { type: FETCH_BY_LENDER_ID_SUCCESS, payload };
}

/**
 * Fetch More Products Success Action
 * @param {Object} response
 * @return {Object}
 */
function fetchMoreSuccess(response) {
  const payload = {
    ...normalize(response.data, [schema.product]),
    meta: getMeta(response.headers),
  };

  return { type: FETCH_MORE_SUCCESS, payload };
}

/**
 * Fetch Products Success Action
 * @param {Object} response
 * @return {Object}
 */
function fetchSuccess(response) {
  const payload = {
    ...normalize(response.data, [schema.product]),
    meta: getMeta(response.headers),
  };

  return { type: FETCH_SUCCESS, payload };
}

export function clearResults() {
  return { type: CLEAR_RESULTS };
}

/**
 * Update Exclusive Success Action
 * @param {Object} response
 * @return {Object}
 */
function updateExclusiveByIdSuccess(response) {
  const payload = normalize(response.data, schema.product);
  return { type: UPDATE_EXCLUSIVE_SUCCESS, payload };
}

/**
 * Add Exclusive Product Thunk
 * @param {Number} brokerageId
 * @param {Object} payload
 * @return {Promise}
 */
export function addExclusive(brokerageId, payload) {
  return (dispatch) => {
    dispatch({ type: ADD_EXCLUSIVE });
    return axios
      .post(`/api/brokerages/${brokerageId}/products`, payload)
      .then((response) => dispatch(addExclusiveSuccess(response)))
      .catch((errors) => dispatch({ type: ADD_EXCLUSIVE_FAILED, errors }));
  };
}

/**
 * Fetch Products Thunk
 * @return {Promise}
 */
export function fetch(params = {}) {
  return (dispatch) => {
    dispatch({ type: FETCH });
    dispatch({ type: filters.SET_FILTER, payload: params });
    return axios
      .get('/api/products', { params })
      .then((response) => dispatch(fetchSuccess(response)))
      .catch((errors) => dispatch({ type: FETCH_FAILED, errors }));
  };
}

/**
 * Fetch by Id Products Thunk
 * @param {Number} productId
 * @return {Promise}
 */
export function fetchById(productId) {
  return (dispatch) => {
    dispatch({ type: FETCH });
    return axios
      .get(`/api/products/${productId}`)
      .then((response) => dispatch(fetchByIdSuccess(response)))
      .catch((errors) => dispatch({ type: FETCH_FAILED, errors }));
  };
}

/**
 * Fetch Exclusive Rate by Id Products Thunk
 * @param {Number} productId
 * @return {Promise}
 */
export function fetchExclusiveById(brokerageId, productId) {
  return (dispatch) => {
    dispatch({ type: FETCH });
    return axios
      .get(`/api/brokerages/${brokerageId}/products/${productId}`)
      .then((response) => dispatch(fetchByIdSuccess(response)))
      .catch((errors) => dispatch({ type: FETCH_FAILED, errors }));
  };
}

/**
 * Fetch Products by Brokerage Id Thunk
 * @return {Promise}
 */
export function fetchByBrokerageId(brokerageId) {
  return (dispatch) => {
    dispatch({ type: FETCH });
    return axios
      .get(`/api/brokerages/${brokerageId}/products`)
      .then((response) => dispatch(fetchByBrokerageIdSuccess(response)))
      .catch((errors) => dispatch({ type: FETCH_FAILED, errors }));
  };
}

/**
 * Fetch Products by Lender Id Thunk
 * @return {Promise}
 */
export function fetchByLenderId(lenderId) {
  return (dispatch) => {
    dispatch({ type: FETCH });
    return axios
      .get('/api/products', {
        params: { lender_id: lenderId, sort: '-sponsored,rate' },
      })
      .then((response) => dispatch(fetchByLenderIdSuccess(response)))
      .catch((errors) => dispatch({ type: FETCH_FAILED, errors }));
  };
}

/**
 * Fetch Products that are currently compared
 */
export function fetchByCompared() {
  return (dispatch, getState) => {
    const id = getState().products.compared;
    if (!id.length) return null;
    dispatch({ type: FETCH });
    return axios
      .get('/api/products', { params: { id } })
      .then((response) => {
        dispatch({
          type: FETCH_BY_COMPARED_SUCCESS,
          payload: normalize(response.data, [schema.product]),
        });
        dispatch({
          type: SET_STALE_COMPARED,
          payload: id.length - response.data.length,
        });
        dispatch({
          type: RESET_COMPARED,
          payload: response.data.map((p) => p.id),
        });
      })
      .catch((errors) => dispatch({ type: FETCH_FAILED, errors }));
  };
}

/**
 * Toggles visibility of compared sidebar.
 */
export function toggleCompareVisible() {
  return (dispatch) => {
    dispatch({ type: TOGGLE_COMPARE_VISIBLE });
  };
}

/**
 * Fetch More Rates based on the current meta data.
 * @return {Function}
 */
export function fetchMore() {
  return (dispatch, getState) => {
    dispatch({ type: FETCH });

    const params = {
      ...getState().filters.options,
      page: getState().products.meta.currentPage + 1,
    };

    return axios
      .get('/api/products', { params })
      .then((response) => dispatch(fetchMoreSuccess(response)))
      .catch((errors) => dispatch({ type: FETCH_FAILED, errors }));
  };
}

/**
 * Refresh Products Thunk
 * @return {Promise}
 */
export function refresh() {
  return (dispatch) => {
    dispatch({ type: REFRESH });
    return axios
      .get('/api/products')
      .then((response) => dispatch(fetchSuccess(response)))
      .catch((errors) => dispatch({ type: FETCH_FAILED, errors }));
  };
}

/**
 * @param {String} type
 */
const wrapComparedAction = (action) => (id) => (dispatch, getState) => {
  const prevCount = getComparedProductsCount(getState());
  dispatch(action(id));
  const nextCount = getComparedProductsCount(getState());

  if (nextCount === 0) {
    dispatch({ type: HIDE_COMPARE });
  } else if (prevCount === 0) {
    dispatch({ type: SHOW_COMPARE });
  }
};

export const addCompared = wrapComparedAction(
  (id) => (dispatch, getState) =>
    getComparedProductsCount(getState()) < COMPARE_LIMIT
      ? dispatch({ type: ADD_COMPARED, id })
      : dispatch(
          notify({
            id: 'COMPARE_LIMIT',
            message: `Comparing is limited to ${COMPARE_LIMIT} products.`,
          })
        )
);

export const clearCompared = () => (dispatch) => dispatch({ type: CLEAR_COMPARED });

export const resetStaleCompared = () => (dispatch) => {
  dispatch({ type: RESET_STALE_COMPARED });
};

export const removeCompared = wrapComparedAction((id) => ({
  type: REMOVE_COMPARED,
  id,
}));

export const toggleCompared = (id) => (dispatch, getState) =>
  getState().products.compared.includes(id) ? dispatch(removeCompared(id)) : dispatch(addCompared(id));

/**
 * Remove Product by ID Thunk.
 * @return {Promise}
 */
export function removeExclusiveById(brokerageId, productId) {
  return (dispatch) => {
    dispatch({ type: REMOVE_BY_ID, payload: productId });
    return axios
      .delete(`/api/brokerages/${brokerageId}/products/${productId}`)
      .catch((errors) => dispatch({ type: REMOVE_BY_ID_FAILED, errors }));
  };
}

/**
 * Update Exclusive Product Thunk.
 * @param {Number} productId
 * @param {Object} payload
 * @return {Promise}
 */
export function updateExclusiveById(brokerageId, productId, payload) {
  return (dispatch) => {
    dispatch({ type: UPDATE_EXCLUSIVE });
    return axios
      .put(`/api/brokerages/${brokerageId}/products/${productId}`, payload)
      .then((response) => dispatch(updateExclusiveByIdSuccess(response)))
      .catch((errors) => dispatch({ type: UPDATE_EXCLUSIVE_FAILED, errors }));
  };
}

export function upsertExclusiveById(brokerageId, productId = null, data) {
  return productId ? updateExclusiveById(brokerageId, productId, data) : addExclusive(brokerageId, data);
}
