import React from 'react';
import PropTypes from 'prop-types';
import { useUser } from 'libs';

const PhoneNumberBadge = ({ children, isMobile = false }) => {
  const user = useUser();
  return (
    <div className="position-relative">
      {!user.phone_number && (
        <div
          className="notification-badge bg-red"
          data-testid="navlink-settings-profile-indicator"
          style={isMobile ? { left: '88px' } : {}}
        >
          <span>1</span>
        </div>
      )}
      {children}
    </div>
  );
};

PhoneNumberBadge.propTypes = {
  children: PropTypes.node.isRequired,
  isMobile: PropTypes.bool,
};

export default PhoneNumberBadge;
