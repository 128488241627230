import React from 'react';
import PropTypes from 'prop-types';
import { Loader } from 'react-loaders';

const ProfileImageForm = ({ onChange, submitting = false, userImage = null }) => (
  <>
    <div className="col-md-12 text-center">
      <span role="img" className="profile-photo-preview" style={{ backgroundImage: `url(${userImage})` }} />
    </div>
    <div className="col-md-12 text-center">
      <form>
        <label htmlFor="file" className="btn btn-primary btn-upload">
          <Loader type="line-scale" active={submitting} size="xs" />
          {submitting ? 'Uploading' : 'Select New Photo'}
          <input type="file" className="form-control" name="photo" accept="image/*" onChange={onChange} />
        </label>
      </form>
    </div>
  </>
);

ProfileImageForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  userImage: PropTypes.string,
  submitting: PropTypes.bool,
};

export default ProfileImageForm;
