import React from 'react';
import { Field } from 'redux-form';
import FormFieldPropTypes from './FormFieldPropTypes';

const Checkbox = ({ input, label, meta }) => {
  const styles = ['form-check-label'];

  if (meta.error) {
    styles.push('is-invalid');
  }

  return (
    <div className="form-check checkbox">
      <Field {...input} component="input" type="checkbox" className="form-check-input" />
      <label className={styles.join(' ')} htmlFor={input.name}>
        {label}
      </label>
    </div>
  );
};

Checkbox.propTypes = {
  ...FormFieldPropTypes,
};

export default Checkbox;
