import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { analytics } from 'libs';
import { selectors } from 'state';
import Filter from './Filter';
import Picklist from './Picklist';

const exclude = ['Limited Feature', 'Promotions', 'Standard Products'];

const getOptions = (programs) =>
  programs
    .filter((program) => !exclude.includes(program.name))
    .map((program) => ({
      key: program.name,
      value: program.name,
      filter: {
        programs: program.name,
      },
    }))
    .sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });

const ProgramFilter = ({ filters, isPro = false, onFilter, programs, size }) => {
  const fields = [];

  const onChange = () => {
    const checked = fields.reduce((acc, input) => (input && input.checked ? [...acc, input.value] : acc), []);

    const other = exclude.filter((type) => filters.programs?.includes(type));
    const value = [...other, ...checked];

    analytics.productSearch.filter({ programs: checked });

    onFilter({ programs: value.length ? value : null });
  };

  const setRef = (ref) => {
    fields.push(ref);
  };

  return (
    <Filter
      name="Programs"
      isPro={isPro}
      tooltip={
        <span>
          Each Lender Program groups lender rate cards that address a variety of client situations.
          <br />
          <a
            href="https://support.lenderspotlight.ca/en/articles/4950534-program-definitions"
            rel="noopener noreferrer"
            target="_blank"
          >
            Click for definitions.
          </a>
        </span>
      }
    >
      <Picklist
        isPro={isPro}
        keyPrefix="program"
        onChange={onChange}
        options={getOptions(programs)}
        setRef={setRef}
        size={size}
        value={filters.programs || []}
      />
    </Filter>
  );
};

ProgramFilter.propTypes = {
  filters: PropTypes.shape({
    programs: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  isPro: PropTypes.bool,
  onFilter: PropTypes.func.isRequired,
  programs: PropTypes.arrayOf(PropTypes.object).isRequired,
  size: PropTypes.oneOf(['default', 'lg']).isRequired,
};

const mapState = (state) => ({
  programs: selectors.programs.getAllSortedByName(state),
});

export default connect(mapState)(ProgramFilter);
