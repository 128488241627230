import React from 'react';
import PropTypes from 'prop-types';

const NavBarBrand = ({ image, name = null }) => (
  <div className="navbar-brand">
    <span className="navbar-brand__image">
      <img className="d-inline-block align-top" data-testid="navigation-brand-image" src={image} alt={name} />
    </span>
    {name && <span className="navbar-brand__name">{name}</span>}
  </div>
);

/**
 * PropTypes Validation
 * @type {Object}
 */
NavBarBrand.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string,
};

export default NavBarBrand;
