import React from 'react';
import PropTypes from 'prop-types';
import { useUser } from 'libs';

const Dropdown = ({ isPro = false, onChange, options, testId, value }) => {
  const user = useUser();
  const disabled = isPro && !user.features.is_pro;

  /**
   * Handle select change event.
   * @param {Object} event
   * @return {Void}
   */
  const handleChange = (event) => {
    const selected = options.find((option) => option.key === event.target.value);

    onChange(selected);
  };

  return (
    <select className="form-control" value={value} onChange={handleChange} disabled={disabled} data-testid={testId}>
      {options.map((option) => (
        <option key={option.key} value={option.key}>
          {option.value}
        </option>
      ))}
    </select>
  );
};

Dropdown.propTypes = {
  isPro: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  testId: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.bool, PropTypes.number, PropTypes.string])
    .isRequired,
};

export default Dropdown;
