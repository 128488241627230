import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import Checkbox from './Checkbox';
import FormGroup from './FormGroup';
import FormLabel from './FormLabel';

const CheckboxWithGroup = ({ emptyString = 'No Options', label, name, options, ...props }) => (
  <FormGroup row>
    <FormLabel name={label} htmlFor={name} row />
    <div className="col-md-6">
      {options.map((option) => (
        <Field {...props} key={option} label={option} name={`${name}.${option}`} component={Checkbox} value={option} />
      ))}
      {options.length < 1 && <span>{emptyString}</span>}
    </div>
  </FormGroup>
);

CheckboxWithGroup.propTypes = {
  emptyString: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default CheckboxWithGroup;
