import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'components/common';
import { noop } from 'libs';
import Loader from './Loader';

const Loading = ({ error = false, pastDelay, retry = noop }) => {
  if (error) {
    return (
      <div>
        <span className="me-2">Error!</span>
        <Button onClick={retry} variant="primary-dark">
          Retry
        </Button>
      </div>
    );
  }

  if (pastDelay) {
    return <Loader isLoading />;
  }

  return null;
};

Loading.propTypes = {
  error: PropTypes.bool,
  pastDelay: PropTypes.bool.isRequired,
  retry: PropTypes.func,
};

export default Loading;
