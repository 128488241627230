const LOGOUT = 'lenderspotlight/auth/LOGOUT';

/**
 * PropTypes
 * @type {Object}
 */
export const propTypes = {};

/**
 * Action Types
 * @type {Object}
 */
export const actionTypes = {
  LOGOUT,
};
