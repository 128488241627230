import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';

const NavListItem = ({
  children,
  className = 'list-group-item',
  disabled = false,
  exact = false,
  except = [],
  location,
  to,
}) => {
  const classes = [className];

  const exclude = Array.isArray(except) ? except : [except];

  const excluded = exclude.reduce((accum, path) => {
    if (location.pathname.includes(path)) return true;
    return accum;
  }, false);

  if (exact && location.pathname === to && !excluded) {
    classes.push('active');
  } else if (!exact && location.pathname.includes(to) && !excluded) {
    classes.push('active');
  }

  return (
    <li className={classes.join(' ')}>
      <Link to={to} disabled={disabled}>
        {children}
      </Link>
    </li>
  );
};

/**
 * PropTypes Validation
 * @type {Object}
 */
NavListItem.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  exact: PropTypes.bool,
  except: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  to: PropTypes.string.isRequired,
};

export default withRouter(NavListItem);
