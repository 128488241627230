/**
 * Data for product attributes.
 * @type {Array}
 */
export const PRODUCTS = [
  {
    id: 'product.lender',
    label: 'Lender',
    value: 'lender',
  },
  {
    id: 'product.name',
    label: 'Product Name',
    value: 'name',
  },
  {
    id: 'product.rate',
    label: 'Rate',
    value: 'rate',
  },
  {
    id: 'product.term-type',
    label: 'Term/Type',
    value: 'term_type',
  },
  {
    id: 'product.ltv',
    label: 'LTV',
    value: 'ltv',
  },
  {
    id: 'product.beacon',
    label: 'Beacon Range',
    value: 'beacon',
  },
  {
    id: 'product.transaction-type',
    label: 'Transaction Type',
    value: 'transaction_type',
  },
  {
    id: 'product.amortization',
    label: 'Maximum Amortization',
    value: 'amortization',
  },
  {
    id: 'product.tds-gds',
    label: 'GDS/TDS',
    value: 'tds_gds',
  },
  {
    id: 'product.rate-hold',
    label: 'Rate Hold',
    value: 'rate_hold',
  },
  {
    id: 'product.compensation',
    label: 'Compensation',
    value: 'compensation',
  },
  {
    id: 'product.buy-down',
    label: 'Buy Down',
    value: 'buy_down',
  },
];

export default PRODUCTS;
