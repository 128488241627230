import React, { Component } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import numeral from 'numeral';
import { analytics, calculate } from 'libs';
import { LockIcon } from 'icons';
import Filter from './Filter';
import Text from './Text';

/**
 * Separate debounce trackers are required so each field change is tracked.
 * If the same debounce tracker is used for all fields, then earlier events
 * that belong to one field may be ignored when another field changes
 * within the debounce period.
 *
 * The debounce duration matches `analytics.productSearch.filterDebounced`.
 */
const trackLTVChange = debounce((value) => analytics.productSearch.filter({ ltv: value || 'cleared' }), 400);
const trackMortgageAmountChange = debounce(
  (value) => analytics.productSearch.filter({ mortgage_amount: value || 'cleared' }),
  400
);
const trackPropertyValueChange = debounce(
  (value) => analytics.productSearch.filter({ property_value: value || 'cleared' }),
  400
);

export class LTVCalculationFilter extends Component {
  static propTypes = {
    filters: PropTypes.shape({
      ltv: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      mortgage_amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      property_value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }).isRequired,
    onFilter: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const { ltv, mortgage_amount, property_value } = props.filters;
    this.state = {
      ltv,
      mortgage_amount,
      property_value,
    };
  }

  onChange = (event) => {
    event.preventDefault();
    let { ltv, mortgage_amount, property_value } = this.state;

    switch (event.target.name) {
      case 'ltv':
        ltv = numeral(event.target.value).value();
        trackLTVChange(ltv);
        break;
      case 'mortgage_amount':
        mortgage_amount = numeral(event.target.value).value();
        trackMortgageAmountChange(mortgage_amount);
        break;
      case 'property_value':
        property_value = numeral(event.target.value).value();
        trackPropertyValueChange(property_value);
        break;
      default:
        break;
    }

    if (this.isLTVAutoCalculated(mortgage_amount, property_value)) {
      ltv = calculate.ltv(mortgage_amount, property_value);
      trackLTVChange(ltv);
    }

    this.setState(
      {
        ltv,
        mortgage_amount,
        property_value,
      },
      () =>
        this.onFilter({
          ltv,
          mortgage_amount,
          property_value,
        })
    );
  };

  onFilter = debounce((filters) => {
    this.props.onFilter(filters);
  }, 900);

  /**
   * @param {Number} [mortgageAmount]
   * @param {Number} [propertyValue]
   */
  isLTVAutoCalculated = (mortgageAmount, propertyValue) => Boolean(mortgageAmount && propertyValue);

  /**
   * Formats and returns current filter values from local state.
   * @return {Object}
   */
  formatValues = () => {
    const { ltv, mortgage_amount, property_value } = this.state;

    return {
      ltv: ltv ? numeral(ltv).format() : '',
      mortgage_amount: mortgage_amount ? numeral(mortgage_amount).format('$0,0') : '',
      property_value: property_value ? numeral(property_value).format('$0,0') : '',
    };
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { filters } = nextProps;
    const hasNewFilters = Object.entries(filters).some(([key, value]) => this.props.filters[key] !== value);

    // Filter values may be hydrated from:
    // - Initial page load with filter URL params
    // - Loading a saved filter
    if (hasNewFilters) {
      this.setState({
        ...filters,
      });
    }
  }

  render() {
    const { ltv, mortgage_amount, property_value } = this.formatValues();
    const isLTVAutoCalculated = this.isLTVAutoCalculated(this.state.mortgage_amount, this.state.property_value);
    return (
      <>
        <Filter name="Mortgage Amount">
          <Text
            testId="filter-mortgage-amount"
            name="mortgage_amount"
            onChange={this.onChange}
            placeholder="Enter mortgage amount"
            value={mortgage_amount}
          />
        </Filter>
        <Filter name="Property Value">
          <Text
            testId="filter-property-value"
            name="property_value"
            onChange={this.onChange}
            placeholder="Enter property value"
            value={property_value}
          />
        </Filter>
        <Filter name={`LTV${isLTVAutoCalculated ? ' (calculated)' : ''}`}>
          <div className="position-relative">
            <Text
              testId="filter-ltv"
              disabled={isLTVAutoCalculated}
              name="ltv"
              onChange={this.onChange}
              placeholder="Enter LTV"
              value={ltv}
            />
            {isLTVAutoCalculated && (
              <div className="position-absolute inset-y-0 right-0 d-flex flex-column justify-content-center px-2">
                <LockIcon className="w-6 opacity-80" />
              </div>
            )}
          </div>
        </Filter>
      </>
    );
  }
}

export default LTVCalculationFilter;
