import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const MobileNavGroup = ({ children, icon, name, startExpanded }) => {
  const [isExpanded, setIsExpanded] = useState(startExpanded);

  return (
    <div className={`mobile-nav__navlink-group navlink-group-${name}`} data-testid={`mobile-nav-navlink-group-${name}`}>
      <button className="mobile-nav__navlink-group-btn" onClick={() => setIsExpanded(!isExpanded)} type="button">
        <span className="w-6 leading-none">{icon}</span>
        <div className="mobile-nav__navlink-group-label">{name}</div>
        <div
          className={classnames('mobile-nav__navlink-group-indicator', {
            expanded: isExpanded,
          })}
        >
          <span className="mobile-nav__navlink-group-indicator-line" />
          <span className="mobile-nav__navlink-group-indicator-line" />
        </div>
      </button>
      {isExpanded && children}
    </div>
  );
};

MobileNavGroup.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.element.isRequired,
  name: PropTypes.string.isRequired,
  startExpanded: PropTypes.bool.isRequired,
};

export default MobileNavGroup;
