import React from 'react';
import Form from 'react-bootstrap/Form';
import PropTypes from 'prop-types';
import { PRODUCTS } from './CompareData';

const CompareReportForm = ({ formData, policies, handleChange, id, productIds, trackSubmit, user }) => {
  /**
   * Updates form data when a text field changes.
   */
  const handleTextFieldChange = ({ target: { name, value } }) => {
    handleChange(name, value);
  };

  /**
   * Updates form data when a checkbox changes.
   */
  const handleCheckboxChange = ({ target }) => {
    handleChange(target.id, !formData[target.id]);
  };

  return (
    <Form
      id={id}
      data-testid="compare-report-form-modal"
      className="compare-report-form"
      action="/reports/compare"
      method="post"
      target="_blank"
      onSubmit={trackSubmit}
    >
      {user.features.has_brokerage && user.brokerage.logo_path && (
        <>
          <h5 className="compare-report-form__subheading">Logo</h5>
          <img className="compare-report-form__brokerage-image" src={user.brokerage.logo_path} alt="brokerage logo" />
          <Form.Group className="compare-report-form__checkbox" controlId="brokerage-logo">
            <Form.Check
              checked={formData['brokerage-logo']}
              className="form-check-inline"
              custom
              label="Include Logo"
              name="brokerage_logo"
              onChange={handleCheckboxChange}
              value="1"
            />
          </Form.Group>
        </>
      )}
      <h5 className="compare-report-form__subheading">Contact Information</h5>
      <Form.Group className="compare-report-form__text-group" controlId="name">
        <Form.Label>Name</Form.Label>
        <Form.Control
          data-testid="compare-report-form-field-name"
          name="name"
          onChange={handleTextFieldChange}
          type="text"
          value={formData.name}
        />
      </Form.Group>
      <Form.Group className="compare-report-form__text-group" controlId="address">
        <Form.Label>Address</Form.Label>
        <Form.Control
          data-testid="compare-report-form-field-address"
          name="address"
          onChange={handleTextFieldChange}
          type="text"
          value={formData.address}
        />
      </Form.Group>
      <Form.Group className="compare-report-form__text-group" controlId="email">
        <Form.Label>Email</Form.Label>
        <Form.Control
          data-testid="compare-report-form-field-email"
          name="email"
          onChange={handleTextFieldChange}
          type="email"
          value={formData.email}
        />
      </Form.Group>
      <Form.Group className="compare-report-form__text-group" controlId="phone">
        <Form.Label>Phone Number</Form.Label>
        <Form.Control
          data-testid="compare-report-form-field-phone"
          name="phone"
          onChange={handleTextFieldChange}
          type="text"
          value={formData.phone}
        />
      </Form.Group>
      <h5 className="compare-report-form__subheading">Personal Greeting</h5>
      <Form.Group className="compare-report-form__text-group" controlId="message">
        <Form.Label>Message</Form.Label>
        <Form.Control
          as="textarea"
          data-testid="compare-report-form-field-message"
          maxLength={330}
          name="message"
          onChange={handleTextFieldChange}
          rows={4}
          value={formData.message}
        />
      </Form.Group>
      <Form.Text className="compare-report-form__character-counter" muted>
        {formData.message.length}
        /330 Characters
      </Form.Text>
      <h5 className="compare-report-form__subheading">Select Product Attributes</h5>
      <div className="compare-report-form__checkbox-group">
        {PRODUCTS.map((product) => (
          <Form.Group className="compare-report-form__checkbox" controlId={product.id} key={product.id}>
            <Form.Check
              checked={formData[product.id]}
              className="form-check-inline"
              custom
              label={product.label}
              name="product_fields[]"
              onChange={handleCheckboxChange}
              value={product.value}
            />
          </Form.Group>
        ))}
      </div>
      <h5 className="compare-report-form__subheading">Select Policies</h5>
      <div className="compare-report-form__checkbox-group">
        {policies.map((policy) => (
          <Form.Group className="compare-report-form__checkbox" controlId={policy.id} key={policy.id}>
            <Form.Check
              checked={formData[policy.id]}
              className="form-check-inline"
              custom
              label={policy.name}
              name="policies[]"
              onChange={handleCheckboxChange}
              value={policy.id}
            />
          </Form.Group>
        ))}
      </div>
      {productIds.map((productId) => (
        <Form.Control key={productId} name="products[]" type="hidden" value={productId} />
      ))}
      <Form.Control name="_token" type="hidden" value={window.CSRF_TOKEN} />
    </Form>
  );
};

CompareReportForm.propTypes = {
  formData: PropTypes.shape({
    address: PropTypes.string,
    'brokerage-logo': PropTypes.bool,
    email: PropTypes.string,
    message: PropTypes.string,
    name: PropTypes.string,
    phone: PropTypes.string,
  }).isRequired,
  policies: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ).isRequired,
  handleChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  productIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  trackSubmit: PropTypes.func.isRequired,
  user: PropTypes.shape({
    brokerage: PropTypes.shape({
      logo_path: PropTypes.string,
    }),
    email: PropTypes.string,
    features: PropTypes.shape({
      has_brokerage: PropTypes.bool,
    }),
    name: PropTypes.string,
  }).isRequired,
};

export default CompareReportForm;
