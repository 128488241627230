// Function is seperated in own file so that its importable from Cypress

/**
 * Simple character subsitituion cypher. Based on https://www.dcode.fr/rot-47-cipher
 *
 * The peculiar 37 char rotation starting from char 48 has been chosen due it working well
 * with base64 encoded values:
 *
 * Consider `MTYyODYzODg3Nw==` which encodes to `ry4Tti4UtiBXsRbb`. Subjectively this
 * looks like a regular random token.
 *
 * In contrast rot47 would return `|%*J~s*K~s8b}Hll` might seem more suspicious? Either
 * way, rot47 is much more well known so it's less likely scraper would identify our
 * homebrew rot37-like implementation.
 *
 * @param {String} input
 */
export default function rot37(input) {
  let output = '';
  const lowerBound = 49;
  const upperBound = 122;
  const charCount = upperBound - lowerBound + 1; // +1 because range is inclusive

  for (let i = 0; i < input.length; i++) {
    const charCode = input.charCodeAt(i);
    if (charCode >= lowerBound && charCode <= upperBound) {
      // Rotate the printable characters in reversible way
      // Note: more consise notation is possible, but costs of clarity
      let shifted = charCode - lowerBound; // Our rotation starts at char lower bound
      shifted = (shifted + charCount / 2) % charCount; // Shift by half of available chars
      shifted += lowerBound; // Put back the lower bound removed at start to get the final code
      output += String.fromCharCode(shifted);
    } else {
      output += String.fromCharCode(charCode);
    }
  }
  return output;
}
