import { schema } from 'normalizr';

const brokerage = new schema.Entity('brokerages');
const compensation = new schema.Entity('compensations');
const contact = new schema.Entity('contacts');
const expense = new schema.Entity('expenses');
const policy = new schema.Entity('policies');
const invitation = new schema.Entity('invitations');
const lender = new schema.Entity('lenders');
const mortgage = new schema.Entity('mortgages', {}, { idAttribute: 'name' });
const network = new schema.Entity('networks');
const paystub = new schema.Entity('paystubs');
const price = new schema.Entity('prices');
const product = new schema.Entity('products');
const program = new schema.Entity('programs');
const rate = new schema.Entity('rates');
const region = new schema.Entity('regions');
const search = new schema.Entity('searches');
const setting = new schema.Entity('settings', {}, { idAttribute: 'key' });
const status = new schema.Entity('statuses');
const term = new schema.Entity('terms');
const user = new schema.Entity('users');

brokerage.define({
  invitations: [invitation],
  settings: [setting],
  users: [user],
});

lender.define({
  contacts: [contact],
  statuses: [status],
  terms: [term],
});

paystub.define({
  additional: [expense],
  commissions: [expense],
  compensations: [compensation],
  expenses: [expense],
  pending: [expense],
});

product.define({
  lender,
  programs: [program],
  regions: [region],
});

user.define({
  brokerage,
  invitations: [invitation],
  pending_invitations: [invitation],
  saved_searches: [search],
});

export default {
  brokerage,
  compensation,
  contact,
  expense,
  invitation,
  lender,
  mortgage,
  network,
  paystub,
  price,
  policy,
  product,
  program,
  rate,
  region,
  search,
  setting,
  status,
  user,
};
