import PropTypes from 'prop-types';
import { combineReducers } from 'redux';
import { normalize } from 'normalizr';
import schema from 'state/schema';
import { actionTypes as brokerages } from './brokerages';

const ACCEPT = 'lenderspotlight/invitations/ACCEPT';
const ACCEPT_FAILED = 'lenderspotlight/invitations/ACCEPT_FAILED';
const ACCEPT_SUCCESS = 'lenderspotlight/invitations/ACCEPT_SUCCESS';
const DECLINE = 'lenderspotlight/invitations/DECLINE';
const DECLINE_FAILED = 'lenderspotlight/invitations/DECLINE_FAILED';
const DECLINE_SUCCESS = 'lenderspotlight/invitations/DECLINE_SUCCESS';
const REVOKE = 'lenderspotlight/invitations/REVOKE';
const REVOKE_FAILED = 'lenderspotlight/invitations/REVOKE_FAILED';
const REVOKE_SUCCESS = 'lenderspotlight/invitations/REVOKE_SUCCESS';
const FETCH = 'lenderspotlight/invitations/FETCH';
const FETCH_BY_BROKERAGE_SUCCESS = 'lenderspotlight/invitations/FETCH_BY_BROKERAGE_SUCCESS';
const FETCH_FAILED = 'lenderspotlight/invitations/FETCH_FAILED';
const FETCH_SUCCESS = 'lenderspotlight/invitations/FETCH_SUCCESS';

/**
 * Action Types
 * @type {Object}
 */
export const actionTypes = {
  ACCEPT,
  ACCEPT_FAILED,
  ACCEPT_SUCCESS,
  DECLINE,
  DECLINE_FAILED,
  DECLINE_SUCCESS,
  REVOKE,
  REVOKE_FAILED,
  REVOKE_SUCCESS,
  FETCH,
  FETCH_BY_BROKERAGE_SUCCESS,
  FETCH_FAILED,
  FETCH_SUCCESS,
};

/**
 * Prop Types
 * @type {Object}
 */
export const propTypes = PropTypes.shape({
  accept: PropTypes.func,
  decline: PropTypes.func,
  fetch: PropTypes.func,
  fetchByBrokerageId: PropTypes.func,
});

/**
 * Hydrate Initial State global LS object.
 * @type {Object}
 */
let initialState = {};

if (window.__INITIALSTATE__ && window.__INITIALSTATE__.user) {
  const data = normalize(window.__INITIALSTATE__.user, schema.user);

  initialState = {
    ...initialState,
    ...data.entities.invitations,
  };
}

if (window.__INITIALSTATE__ && window.__INITIALSTATE__.brokerages) {
  const data = normalize(window.__INITIALSTATE__.brokerages, [schema.brokerage]);

  initialState = {
    ...initialState,
    ...data.entities.invitations,
  };
}

/**
 * By Id Reducer
 * @param {Object} state
 * @param {Object} action
 * @return {Object}
 */
function byIdReducer(state = initialState, action) {
  switch (action.type) {
    case ACCEPT_SUCCESS:
    case DECLINE_SUCCESS:
    case REVOKE_SUCCESS:
      return {
        ...Object.keys(state).reduce((accum, id) => {
          if (action.payload.id.toString() === id.toString()) return accum;
          return { ...accum, [id]: state[id] };
        }, {}),
      };

    case brokerages.INVITE_SUCCESS:
    case FETCH_BY_BROKERAGE_SUCCESS:
    case FETCH_SUCCESS:
      return {
        ...state,
        ...action.payload.entities.invitations,
      };

    default:
      return state;
  }
}

/**
 * Is Loading Reducer
 * @param {Boolean} state
 * @param {Object}  action
 * @return {Boolean}
 */
function isLoadingReducer(state = false, action) {
  switch (action.type) {
    case ACCEPT:
    case REVOKE:
    case FETCH:
      return true;

    case ACCEPT_FAILED:
    case ACCEPT_SUCCESS:
    case REVOKE_FAILED:
    case REVOKE_SUCCESS:
    case FETCH_BY_BROKERAGE_SUCCESS:
    case FETCH_FAILED:
    case FETCH_SUCCESS:
      return false;

    default:
      return state;
  }
}

/**
 * Export Invitations Reducer
 * @type {[type]}
 */
export default combineReducers({
  byId: byIdReducer,
  isLoading: isLoadingReducer,
});

/**
 * Fetch Success action creator.
 * @param {Object} response
 * @return {Object}
 */
function fetchSuccess(response) {
  const payload = normalize(response.data, [schema.invitation]);
  return { type: FETCH_SUCCESS, payload };
}

/**
 * Fetch By Brokerage Id Success action creator.
 * @param {Object} response
 * @return {Object}
 */
function fetchByBrokerageIdSuccess(response) {
  const payload = normalize(response.data, [schema.invitation]);
  return { type: FETCH_BY_BROKERAGE_SUCCESS, payload };
}

/**
 * Accept Invitation Thunk
 * @param {Object} invitation
 * @return {Function}
 */
export function accept(invitation) {
  return (dispatch) => {
    dispatch({ type: ACCEPT, payload: invitation });
    return axios
      .post(`/api/invitations/${invitation.token}`)
      .then((response) => dispatch({ type: ACCEPT_SUCCESS, payload: response.data }))
      .catch((error) => dispatch({ type: ACCEPT_FAILED, error }));
  };
}

/**
 * Decline Invitation Thunk
 * @param {Object} invitation
 * @return {Function}
 */
export function decline(invitation) {
  return (dispatch) => {
    dispatch({ type: DECLINE, payload: invitation });
    return axios
      .delete(`/api/invitations/${invitation.id}/decline`)
      .then((response) => dispatch({ type: DECLINE_SUCCESS, payload: response.data }))
      .catch((error) => dispatch({ type: DECLINE_FAILED, error }));
  };
}

/**
 * Revoke Invitation Thunk used by brokerage owners
 * @param {Object} invitation
 * @return {Function}
 */
export function revoke(invitation) {
  return (dispatch) => {
    dispatch({ type: REVOKE, payload: invitation });
    return axios
      .delete(`/api/invitations/${invitation.id}/revoke`)
      .then(() => dispatch({ type: REVOKE_SUCCESS, payload: invitation }))
      .catch((error) => dispatch({ type: REVOKE_FAILED, error }));
  };
}

/**
 * Fetch the invitations for the current user.
 * @return {Function}
 */
export function fetch() {
  return (dispatch) => {
    dispatch({ type: FETCH });
    return axios
      .get('/api/invitations')
      .then((response) => dispatch(fetchSuccess(response)))
      .catch((error) => dispatch({ type: FETCH_FAILED, error }));
  };
}

/**
 * Fetch invitations by brokerage id.
 * @param {Number} brokerageId
 * @return {Function}
 */
export function fetchByBrokerageId(brokerageId) {
  return (dispatch) => {
    dispatch({ type: FETCH });
    return axios
      .get(`/api/brokerages/${brokerageId}/invitations`)
      .then((response) => dispatch(fetchByBrokerageIdSuccess(response)))
      .catch((error) => dispatch({ type: FETCH_FAILED, error }));
  };
}
