import React from 'react';
import FormFieldPropTypes from './FormFieldPropTypes';

const FormRadio = ({ input, options }) => (
  <div>
    {options.map((option) => (
      <div className="radio" key={option}>
        <label htmlFor={`${input.name}-${option}`}>
          <input id={`${input.name}-${option}`} type="radio" {...input} value={option} className="me-1" />
          {option}
        </label>
      </div>
    ))}
  </div>
);

FormRadio.propTypes = {
  ...FormFieldPropTypes,
};

export default FormRadio;
