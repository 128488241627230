import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const ContentAside = ({ className = null, children }) => (
  <aside className={classnames('content__aside', className)}>{children}</aside>
);

ContentAside.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default ContentAside;
