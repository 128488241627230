import PropTypes from 'prop-types';
import { Component } from 'react';
import { location } from 'react-router-prop-types';
import { withRouter } from 'react-router-dom';

class ScrollToTop extends Component {
  /**
   * Prop Types Validation
   * @type {Object}
   */
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    location: location.isRequired,
  };

  /**
   * ComponentDidUpdate
   * @param  {Object} prevProps
   * @return {Void}
   */
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  /**
   * Render Component
   * @return {Object}
   */
  render() {
    return this.props.children;
  }
}

export default withRouter(ScrollToTop);
