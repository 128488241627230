import PropTypes from 'prop-types';

export default {
  input: PropTypes.shape({
    checked: PropTypes.bool,
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onDragStart: PropTypes.func,
    onDrop: PropTypes.func,
    onFocus: PropTypes.func,
    value: PropTypes.any,
  }).isRequired,
  meta: PropTypes.shape({
    active: PropTypes.bool,
    autofilled: PropTypes.bool,
    ayncValidating: PropTypes.bool,
    dirty: PropTypes.bool,
    dispatch: PropTypes.func,
    error: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
    form: PropTypes.string,
    initial: PropTypes.any,
    invalid: PropTypes.bool,
    pristine: PropTypes.bool,
    submitting: PropTypes.bool,
    submitFailed: PropTypes.bool,
    touched: PropTypes.bool,
    valid: PropTypes.bool,
    visited: PropTypes.bool,
    warning: PropTypes.string,
  }).isRequired,
};
