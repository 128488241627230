import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Error from './Error';
import Label from './Label';

export const Text = forwardRef(({ error, label, name, register, testId, type }, ref) => (
  <div className="form-group">
    <Label name={name}>{label}</Label>
    <input
      data-testid={testId}
      className={classnames('form-control', { 'is-invalid': error })}
      id={name}
      label={label}
      name={name}
      ref={ref}
      type={type}
      {...register}
    />
    <Error>{error?.message}</Error>
  </div>
));

Text.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
  }),
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  register: PropTypes.object.isRequired,
  testId: PropTypes.string,
  type: PropTypes.string,
};

export default Text;
