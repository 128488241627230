import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const FormGroup = ({ children, className, row }) => (
  <div className={classnames('form-group', className, { row })}>{children}</div>
);

FormGroup.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string,
  row: PropTypes.bool,
};

export default FormGroup;
