import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'components/common';

const CompareReportModal = ({ children, id, onHide, show }) => (
  <Modal
    centered
    heading="Create Report"
    onHide={onHide}
    show={show}
    footer={
      <>
        <Button onClick={onHide} variant="default">
          Cancel
        </Button>
        <button
          className="modal-content__submit-button"
          data-testid="compare-report-modal-create-report-button"
          form={id}
          type="submit"
        >
          Create Report
        </button>
      </>
    }
  >
    {children}
  </Modal>
);

CompareReportModal.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

export default CompareReportModal;
