import React from 'react';
import { Field, propTypes as FormPropTypes, reduxForm } from 'redux-form';
import { Loader } from 'react-loaders';
import { FormGroup, InputWithGroup, SuccessAlert } from 'components/forms';

const PasswordChangeForm = (props) => (
  <div>
    <SuccessAlert show={props.submitSucceeded} message="Password successfully changed" />
    <form onSubmit={props.handleSubmit} className="form-horizontal">
      <Field type="hidden" name="id" component="input" />
      <Field label="Current Password" type="password" name="password_old" component={InputWithGroup} />
      <Field label="Password" type="password" name="current_password" component={InputWithGroup} />
      <Field label="Confirm Password" type="password" name="current_password_confirmation" component={InputWithGroup} />
      <FormGroup className="col-md-6 col-md-offset-4">
        <button type="submit" className="btn btn-primary" disabled={props.submitting}>
          <Loader type="line-scale" active={props.submitting} size="xs" />
          {props.submitting ? 'Changing' : 'Change Password'}
        </button>
      </FormGroup>
    </form>
  </div>
);

/**
 * PropTypes Validation
 * @type {Object}
 */
PasswordChangeForm.propTypes = {
  ...FormPropTypes,
};

export default reduxForm({
  form: 'user.password',
})(PasswordChangeForm);
