import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const ComparePropertyRow = ({ children, last = false, name }) => (
  <div className={classnames('compare-property-row', { last })}>
    <div className="compare-property-row__property">{name}</div>
    {children.map((value, index) => (
      /* eslint-disable-next-line react/no-array-index-key */
      <div key={index} className="compare-property-row__values">
        {value}
      </div>
    ))}
  </div>
);

ComparePropertyRow.propTypes = {
  children: PropTypes.arrayOf(PropTypes.string).isRequired,
  last: PropTypes.bool,
  name: PropTypes.string.isRequired,
};

export default ComparePropertyRow;
