import React from 'react';
import PropTypes from 'prop-types';
import ReactSelect, { components } from 'react-select';

const Select = ({
  autoFocus = false,
  className = '',
  clearValue = null,
  closeMenuOnSelect = true,
  defaultMenuIsOpen = false,
  disabled = false,
  filterOption,
  isClearable = true,
  isMulti = false,
  isSearchable = true,
  onChange,
  options,
  overflowContainer = false,
  placeholder = null,
  selected = null,
  testId,
}) => {
  /**
   * Handler function for internal value changes.
   * @param {Object[]} value
   * @param {String} action
   * @return {Void}
   */
  const onSelect = (selectedOptions, { action, option }) => {
    switch (action) {
      case 'clear':
        onChange(clearValue, action, option);
        break;
      case 'pop-value':
      case 'remove-value':
      case 'select-option':
        onChange(selectedOptions, action, option);
        break;
      default:
        break;
    }
  };

  return (
    <ReactSelect
      autoFocus={autoFocus}
      className={className}
      clearIndicatorTestId={testId ? `${testId}-clear-indicator` : null}
      closeMenuOnSelect={closeMenuOnSelect}
      components={{ SelectContainer, Menu, ClearIndicator }}
      defaultMenuIsOpen={defaultMenuIsOpen}
      filterOption={filterOption}
      isClearable={isClearable}
      isDisabled={disabled}
      isMulti={isMulti}
      isSearchable={isSearchable}
      menuPortalTarget={overflowContainer ? document.body : null}
      menuTestId={testId ? `${testId}-menu` : null}
      onChange={onSelect}
      options={options}
      placeholder={placeholder}
      styles={{ menuPortal: (base) => ({ ...base, zIndex: 1100 }) }}
      testId={testId}
      value={selected}
    />
  );
};

Select.propTypes = {
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  clearValue: PropTypes.string,
  closeMenuOnSelect: PropTypes.bool,
  defaultMenuIsOpen: PropTypes.bool,
  disabled: PropTypes.bool,
  filterOption: PropTypes.func,
  isClearable: PropTypes.bool,
  isMulti: PropTypes.bool,
  isSearchable: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    })
  ).isRequired,
  overflowContainer: PropTypes.bool,
  placeholder: PropTypes.string,
  selected: PropTypes.oneOfType([
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      })
    ),
  ]),
  testId: PropTypes.string,
};

export default Select;

// Extend components to help with testing
// For details, see https://react-select.com/components#replacing-components
const SelectContainer = ({ innerProps, ...props }) => (
  <components.SelectContainer
    {...props}
    innerProps={{
      ...innerProps,
      'data-testid': props.selectProps.testId,
    }}
  >
    {props.children}
  </components.SelectContainer>
);

SelectContainer.propTypes = components.SelectContainer.propTypes;

const Menu = ({ innerProps, ...props }) => (
  <components.Menu
    {...props}
    innerProps={{
      ...innerProps,
      'data-testid': props.selectProps.menuTestId,
    }}
  >
    {props.children}
  </components.Menu>
);

Menu.propTypes = components.Menu.propTypes;

const ClearIndicator = ({ innerProps, ...props }) => (
  <components.ClearIndicator
    {...props}
    innerProps={{
      ...innerProps,
      'data-testid': props.selectProps.clearIndicatorTestId,
    }}
  >
    {props.children}
  </components.ClearIndicator>
);

ClearIndicator.propTypes = components.ClearIndicator.propTypes;
