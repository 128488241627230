import { useEffect, useRef } from 'react';

/**
 * Returns an object with a stable identity that will keep its `current`
 * property synced with the passed in callback (cb).
 *
 * This is useful in situations where the cb should not cause a rerender if
 * it changes. For example, a parent component has a callback (cb) that a
 * child component should run when some other state changes. The child
 * component uses an `useEffect` hook with a dependency array to keep track
 * of state changes and runs the cb in response. This dependency array must
 * include the cb, otherwise the eslint hook rule will throw a warning. The
 * problem with this situation is if the cb changes, perhaps because it's
 * an anonymous cb, it'll cause the effect closure to rerun even though we
 * only want it to run when other state changes. To prevent this, use this
 * hook to wrap it in a stable container.
 *
 * @param {Function} cb
 */
const useCallbackContainer = (cb) => {
  const ref = useRef(cb);

  // Keep cb in sync
  useEffect(() => {
    ref.current = cb;
  }, [cb]);

  return ref;
};

export default useCallbackContainer;
