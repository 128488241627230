import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import FormFieldPropTypes from './FormFieldPropTypes';

const FormInput = ({ className = 'form-control', input, meta, type = 'text', ...props }) => (
  <input
    {...input}
    {...props}
    type={type}
    className={classnames(className, {
      'is-invalid': meta.touched && meta.error,
    })}
  />
);

FormInput.propTypes = {
  ...FormFieldPropTypes,
  className: PropTypes.string,
  type: PropTypes.string,
};

export default FormInput;
