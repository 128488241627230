import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'components/common';

const HeroBacklink = ({ name, goBack }) => (
  <button className="hero__backlink" type="button" onClick={goBack}>
    <Icon name="chevron-left" />
    {' Back to '}
    {name}
  </button>
);

HeroBacklink.propTypes = {
  goBack: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

export default HeroBacklink;
