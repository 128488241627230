import { createSelector } from 'reselect';
import uniqBy from 'lodash/uniqBy';
import { getGuidelinesByLenderIdAndPolicy } from './guidelines';

/**
 * Select all lenders from the state.
 * @param  {Object} state
 * @return {Array}
 */
const getAllLenders = (state) => state.lenders.byId || {};

/**
 * Select all the products from products.
 * @param {Object} state
 * @return {Object}
 */
const getAllProducts = (state) => state.products.byId;

/**
 * Get the list of product ids from the current search results.
 * @param  {Object} state
 * @return {Array}
 */
const getAllResults = (state) => state.products.results;

/**
 * Get compared product ids.
 * @param {*} state
 * @return {Array}
 */
const getAllCompared = (state) => state.products.compared;

/**
 * Get all the product ids for the current brokerage.
 * @param {Object} state
 */
const getForBrokerage = (state) => state.products.forBrokerage;

/**
 * Get a Product from state by Id.
 * @param  {Object} state
 * @param  {Number} productId
 * @return {Object}
 */
const getProductById = (state, productId) => state.products.byId[productId];

/**
 * Denormalize Product
 * @param {Object} product
 * @param {Array} lenders
 * @param {Array} programs
 * @return {Object}
 */
export const denormalizeProduct = (product, lenders) => {
  if (!product) return null;
  const clone = { ...product };
  clone.lender = lenders[clone.lender_id] || {};
  clone.rate_hold_int = parseInt(clone.rate_hold, 10);
  return clone;
};

/**
 * Denormalize Product with Policies
 * @param {Object} product
 * @param {Array} lenders
 * @param {Array} programs
 * @param {Array} policies
 * @return {Object}
 */
export const denormalizeProductWithGuidelines = (product, lenders, policies) => {
  if (!product) return null;
  const clone = denormalizeProduct(product, lenders);
  clone.guidelines = policies[clone.lender.id] || {};
  return clone;
};

/**
 * Get the current fetch meta.
 * @param  {Object} state
 * @return {Object}
 */
export const getMeta = (state) => state.products.meta;

/**
 * Get the current loading state.
 * @param {Object} state
 * @return {Boolean}
 */
export const isLoading = (state) => state.products.pendingRequestsCount !== 0;

/**
 * Get Product by Id.
 * @param  {Object} state
 * @param  {Number} productId
 * @return {Object}
 */
export const getById = createSelector([getProductById, getAllLenders], (product, lenders) =>
  denormalizeProduct(product, lenders)
);

export const getExclusiveByBrokerageId = createSelector(
  [getAllProducts, getAllLenders, getForBrokerage],
  (products, lenders, productIds) =>
    productIds
      .map((productId) => denormalizeProduct(products[productId], lenders))
      .sort((a, b) => {
        if (a.lender.name > b.lender.name) return 1;
        if (a.lender.name < b.lender.name) return -1;
        return 0;
      })
);

/**
 * Get all the products from the current search.
 * @return {Function}
 */
export const getProducts = createSelector([getAllResults, getAllProducts, getAllLenders], (ids, products, lenders) =>
  uniqBy(
    ids.map((productId) => denormalizeProduct(products[productId], lenders)),
    'id'
  )
);

/**
 * Get all compared products with lenders and policies.
 */
export const getComparedProducts = createSelector(
  [getAllCompared, getAllProducts, getAllLenders, getGuidelinesByLenderIdAndPolicy],
  (ids, products, lenders, policies) =>
    ids.reduce((acc, productId) => {
      const product = products[productId];
      return product ? [...acc, denormalizeProductWithGuidelines(product, lenders, policies)] : acc;
    }, [])
);

/**
 * Get the number of compared products.
 * @param {*} state
 * @return {Number}
 */
export const getComparedProductsCount = (state) => getComparedProducts(state).length;

/**
 * Gets the number of compared rates that have been removed.
 * @param {*} state
 * @return {Number}
 */
export const getStaleComparedProductsCount = (state) => state.products.staleCompared;

/**
 * Returns true if the compare sidebar is open.
 * @param {*} state
 * @return {Boolean}
 */
export const isCompareVisible = (state) => state.products.compareVisible;

/**
 * Get the product filtering results that were processed by the WebWorker.
 * @param  {Object} state
 * @return {Array}
 */
export const getResults = (state) => state.products.results;
