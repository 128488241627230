import { combineReducers } from 'redux';
import { normalize } from 'normalizr';
import schema from 'state/schema';

/**
 * Action Types
 * @type {Object}
 */
export const actionTypes = {};

/**
 * Hydrate Initial State global LS object.
 * @type {Object}
 */
let initialState = {};

if (window.__INITIALSTATE__ && window.__INITIALSTATE__.prices) {
  const data = normalize(window.__INITIALSTATE__.prices, [schema.price]);
  initialState = data.entities.prices || {};
}

/**
 * ById Program Reducer
 * @param {Object} state
 * @return {Object}
 */
function byIdReducer(state = initialState) {
  return state;
}

/**
 * Export Lender Reducer
 * @type {Object}
 */
export default combineReducers({
  byId: byIdReducer,
});
