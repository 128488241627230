import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'components/common';
import { Text } from 'components/forms';
import { normalizePhone } from 'libs';

const ProfileDetailsForm = ({ errors, handleSubmit, isSubmitting, onSubmit, register, setValue }) => (
  <form className="py-4" onSubmit={handleSubmit(onSubmit)}>
    <div className="py-4">
      <Text error={errors.name} label="Name *" name="name" register={register('name')} type="text" />
      <Text error={errors.email} label="Email *" name="email" register={register('email')} type="email" />
      <Text
        error={errors.phone_number}
        label="Phone"
        name="phone_number"
        register={register('phone_number', {
          onChange: (event) => {
            setValue('phone_number', normalizePhone(event.target.value));
          },
        })}
        type="text"
      />
    </div>
    <Button disabled={isSubmitting} submit variant="primary-dark" width="block">
      Continue
    </Button>
  </form>
);

ProfileDetailsForm.propTypes = {
  errors: PropTypes.shape({
    email: PropTypes.shape({
      message: PropTypes.string,
    }),
    name: PropTypes.shape({
      message: PropTypes.string,
    }),
    phone_number: PropTypes.shape({
      message: PropTypes.array,
    }),
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
};

export default ProfileDetailsForm;
