import { compose, createStore, applyMiddleware } from 'redux';
import { persistStore } from 'redux-persist';
import { rootReducer } from './modules';
import middleware from './middleware';
import sentry from './middleware/sentry';

let composeEnhancers = compose;
// Disable redux devtools during testing and in development.
const envs = ['production', 'test'];
if (!envs.includes(process.env.NODE_ENV)) {
  /* eslint-disable-next-line no-underscore-dangle */
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
}

const enhancers = composeEnhancers(applyMiddleware(...middleware), sentry);

export default function configureStore(state = {}) {
  const store = createStore(rootReducer, state, enhancers);
  const persistor = persistStore(store);
  return { store, persistor };
}
