import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions, selectors } from 'state';
import { analytics } from 'libs';
import { MenuButton, useHideIntercom } from 'components/common';

const CompareButton = ({ comparedCount, isCompareVisible, products }) => {
  const handleClick = () => {
    analytics.compare.menuIconClick(!isCompareVisible);
    products.toggleCompareVisible();
  };

  useHideIntercom(isCompareVisible);

  return (
    <MenuButton
      active={isCompareVisible}
      className="compare-button"
      icon={
        <div className="compare-button__icon-container">
          {comparedCount > 0 && (
            <div className="compare-button__indicator">
              <span>{comparedCount}</span>
            </div>
          )}
          <CompareIcon
            className={classnames('compare-button__icon', {
              active: isCompareVisible,
            })}
          />
        </div>
      }
      onClick={handleClick}
      testId="compare-button"
      text="Compare"
    />
  );
};

CompareButton.propTypes = {
  comparedCount: PropTypes.number.isRequired,
  isCompareVisible: PropTypes.bool.isRequired,
  products: PropTypes.shape({
    toggleCompareVisible: PropTypes.func.isRequired,
  }).isRequired,
};

const CompareIcon = ({ className }) => (
  <svg className={className} width={26} height={26} viewBox="0 0 26 26">
    <title>Compare Products</title>
    <g transform="translate(1.196 1.637)" fill="none" fillRule="evenodd">
      <path
        d="M9.97 22.886h12.124a1 1 0 001-1V1a1 1 0 00-1-1H9.042a1 1 0 00-1 1v3.577h0"
        stroke="#757575"
        fill="#FFF"
      />
      <rect stroke="#757575" fill="#FFF" y={4.577} width={12.23} height={18.309} rx={1} />
      <g fill="#757575">
        <path d="M3.392 11.786h6v1h-6z" />
        <path d="M7.952 9.451l2.828 2.829-.707.707-2.828-2.829z" />
        <path d="M7.952 15.121l2.828-2.828-.707-.708-2.828 2.829z" />
      </g>
      <g fill="#757575">
        <path d="M21.062 11.786h-6v1h6z" />
        <path d="M16.503 9.451l-2.828 2.829.707.707 2.828-2.829z" />
        <path d="M16.503 15.121l-2.828-2.828.707-.708 2.828 2.829z" />
      </g>
    </g>
  </svg>
);

CompareIcon.propTypes = {
  className: PropTypes.string,
};

/**
 * Map state to props.
 * @param {*} state
 */
const mapState = (state) => ({
  comparedCount: selectors.products.getComparedProductsCount(state),
  isCompareVisible: selectors.products.isCompareVisible(state),
});

/**
 * Map dispatch to props.
 * @param {Function} dispatch
 */
const mapDispatch = (dispatch) => ({
  products: bindActionCreators(actions.products, dispatch),
});

export default connect(mapState, mapDispatch)(CompareButton);
