import capitalize from 'lodash/capitalize';
import moment from 'moment';
import numeral from 'numeral';

export const formatCardBrand = (cardBrand) => {
  switch (cardBrand) {
    case 'amex':
      return 'American Express';
    case 'mastercard':
      return 'Master Card';
    default:
      return capitalize(cardBrand);
  }
};

export const formatPrice = (price) => `${numeral(price.price / 100).format('$0,0')} / ${capitalize(price.frequency)}`;

export const hasAddon = (subscriptions, addon) =>
  Boolean(
    subscriptions.filter(
      (subscription) => subscription.stripe_status !== 'canceled' && subscription.stripe_price === addon.stripe_price
    ).length
  );

export const isActivePlatinumSubscription = (subscription) =>
  subscription.name === 'platinum' && (!subscription.ends_at || moment.utc() < moment.utc(subscription.ends_at));

export const isAddonActive = (subscriptions, addon) =>
  Boolean(subscriptions.find((subscription) => subscription.stripe_price === addon.stripe_price).ends_at === null);

export default {
  formatCardBrand,
  formatPrice,
  hasAddon,
  isActivePlatinumSubscription,
  isAddonActive,
};
