import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { noop } from 'libs';

const MobileNavItem = ({
  icon,
  isActive = false,
  isExternalRoute = false,
  label,
  onClick = noop,
  shouldOpenNewWindow = false,
  testId = null,
  to,
}) => {
  const className = classnames('mobile-nav__navlink', {
    active: isActive,
  });

  return (
    <div className="mobile-nav__navlink-container">
      {isExternalRoute ? (
        <a
          className={className}
          data-testid={testId}
          href={to}
          onClick={onClick}
          rel={shouldOpenNewWindow ? 'noopener noreferrer' : null}
          target={shouldOpenNewWindow ? '_blank' : null}
        >
          <MobileNavItemContent icon={icon} label={label} />
        </a>
      ) : (
        <Link className={className} data-testid={testId} to={to} onClick={onClick}>
          <MobileNavItemContent icon={icon} label={label} />
        </Link>
      )}
    </div>
  );
};

MobileNavItem.propTypes = {
  icon: PropTypes.element.isRequired,
  label: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  isExternalRoute: PropTypes.bool,
  onClick: PropTypes.func,
  shouldOpenNewWindow: PropTypes.bool,
  testId: PropTypes.string,
  to: PropTypes.string.isRequired,
};

export default MobileNavItem;

const MobileNavItemContent = ({ icon, label }) => (
  <>
    <span className="w-6 leading-none">{icon}</span>
    <div className="mobile-nav__label">{label}</div>
  </>
);

MobileNavItemContent.propTypes = {
  icon: MobileNavItem.propTypes.icon,
  label: MobileNavItem.propTypes.label,
};
