import PropTypes from 'prop-types';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import { normalize } from 'normalizr';
import { persistReducer } from 'redux-persist';
import schema from 'state/schema';
import { analytics } from 'libs';

const ADD_FOR_BROKERAGE = 'lenderspotlight/settings/ADD_FOR_BROKERAGE';
const ADD_FOR_BROKERAGE_FAILED = 'lenderspotlight/settings/ADD_FOR_BROKERAGE_FAILED';
const FETCH = 'lenderspotlight/settings/FETCH';
const FETCH_FOR_BROKERAGE = 'lenderspotlight/settings/FETCH_FOR_BROKERAGE';
const FETCH_FOR_BROKERAGE_FAILED = 'lenderspotlight/settings/FETCH_FOR_BROKERAGE_FAILED';
const FETCH_FOR_BROKERAGE_SUCCESS = 'lenderspotlight/settings/FETCH_FOR_BROKERAGE_SUCCESS';
const FETCH_FAILED = 'lenderspotlight/settings/FETCH_FAILED';
const FETCH_SUCCESS = 'lenderspotlight/settings/FETCH_SUCCESS';
const REMOVE_FOR_BROKERAGE = 'lenderspotlight/settings/REMOVE_FOR_BROKERAGE';
const REMOVE_FOR_BROKERAGE_FAILED = 'lenderspotlight/settings/REMOVE_FOR_BROKERAGE_FAILED';
const UPDATE_SETTING = 'lenderspotlight/settings/UDPATE_SETTING';

/**
 * Action Types
 * @type {Object}
 */
export const actionTypes = {
  ADD_FOR_BROKERAGE,
  ADD_FOR_BROKERAGE_FAILED,
  FETCH,
  FETCH_FOR_BROKERAGE,
  FETCH_FOR_BROKERAGE_FAILED,
  FETCH_FOR_BROKERAGE_SUCCESS,
  FETCH_FAILED,
  FETCH_SUCCESS,
  REMOVE_FOR_BROKERAGE,
  REMOVE_FOR_BROKERAGE_FAILED,
  UPDATE_SETTING,
};

/**
 * PropTypes Validation
 * @type {Function}
 */
export const propTypes = PropTypes.shape({
  fetchAll: PropTypes.func,
  update: PropTypes.func,
  toggleBrokerageSetting: PropTypes.func,
});

/**
 * Hydrate Initial State global LS object.
 * @type {Object}
 */
let initialState = {
  byBrokerageId: {},
  byId: {},
  forUser: {
    useTableView: false,
  },
};

if (window.__INITIALSTATE__ && window.__INITIALSTATE__.settings) {
  const data = normalize(window.__INITIALSTATE__.settings, [schema.setting]);

  initialState = {
    ...initialState,
    byId: data.entities.settings,
  };
}

if (window.__INITIALSTATE__ && window.__INITIALSTATE__.brokerages) {
  const byBrokerageId = window.__INITIALSTATE__.brokerages.reduce((accum, brokerage) => {
    const payload = normalize(brokerage, schema.brokerage);
    return {
      ...accum,
      [brokerage.id]: payload.entities.settings || {},
    };
  }, {});

  initialState = {
    ...initialState,
    byBrokerageId,
  };
}

/**
 * Settings by Id reducer.
 * @param  {Object} state
 * @param  {Object} action
 * @return {Object}
 */
function byIdReducer(state = initialState.byId, action) {
  switch (action.type) {
    case FETCH_SUCCESS:
      return {
        ...state,
        ...action.payload.entities.settings,
      };

    default:
      return state;
  }
}

/**
 * Active settings by Brokerage Id reducer.
 * @param  {Object} state
 * @param  {Object} action
 * @return {Object}
 */
function byBrokerageIdReducer(state = initialState.byBrokerageId, action) {
  switch (action.type) {
    case FETCH_FOR_BROKERAGE_SUCCESS:
      return {
        ...state,
        [action.brokerageId]: action.payload.entities.settings,
      };

    case ADD_FOR_BROKERAGE:
    case REMOVE_FOR_BROKERAGE:
      return {
        ...state,
        [action.payload.brokerageId]: {
          ...state[action.payload.brokerageId],
          [action.payload.key]: {
            key: action.payload.key,
            value: action.payload.value,
          },
        },
      };

    case ADD_FOR_BROKERAGE_FAILED:
    case REMOVE_FOR_BROKERAGE_FAILED:
      return {
        ...state,
        [action.payload.brokerageId]: {
          ...state[action.payload.brokerageId],
          [action.payload.key]: action.payload.setting,
        },
      };

    default:
      return state;
  }
}

/**
 * For User settings reducer.
 * @param  {Object} state
 * @param  {Object} action
 * @return {Object}
 */
function forUserReducer(state = initialState.forUser, action) {
  switch (action.type) {
    case UPDATE_SETTING:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };

    default:
      return state;
  }
}

/**
 * pendingRequestsCount Reducer.
 * @param  {Number} state
 * @param  {Object}  action
 * @return {Number}
 */
function pendingRequestsCountReducer(state = 0, action) {
  switch (action.type) {
    case FETCH:
    case FETCH_FOR_BROKERAGE:
      return state + 1;
    case FETCH_FAILED:
    case FETCH_SUCCESS:
    case FETCH_FOR_BROKERAGE_FAILED:
    case FETCH_FOR_BROKERAGE_SUCCESS:
      return state - 1;
    default:
      return state;
  }
}

/**
 * Persistor Configuration.
 * @type {Object}
 */
const persistConfig = {
  key: 'forUser',
  storage,
};

/**
 * Export Lender Reducer
 * @type {Object}
 */
export default combineReducers({
  byId: byIdReducer,
  byBrokerageId: byBrokerageIdReducer,
  forUser: persistReducer(persistConfig, forUserReducer),
  pendingRequestsCount: pendingRequestsCountReducer,
});

/**
 * Fetch all settings success action creator.
 * @param  {Object} response
 * @return {Object}
 */
function fetchAllSuccess(response) {
  const payload = normalize(response.data, [schema.setting]);
  return { type: FETCH_SUCCESS, payload };
}

/**
 * Fetch brokerage success action creator.
 * @param  {Object} response
 * @param  {String} brokerageId
 * @return {Object}
 */
function fetchByBrokerageSuccess(response, brokerageId) {
  const payload = normalize(response.data, [schema.setting]);
  return { type: FETCH_FOR_BROKERAGE_SUCCESS, brokerageId, payload };
}

/**
 * Fetch all settings
 * @return {Function}
 */
export function fetchAll() {
  return (dispatch) => {
    dispatch({ type: FETCH });
    return axios
      .get('/api/settings')
      .then((response) => dispatch(fetchAllSuccess(response)))
      .catch((errors) => dispatch({ type: FETCH_FAILED, errors }));
  };
}

/**
 * Fetch the settings for the current brokerage.
 * @param {Number} brokerageId
 * @return {Function}
 */
export function fetchByBrokerageId(brokerageId) {
  return (dispatch) => {
    dispatch({ type: FETCH_FOR_BROKERAGE });
    return axios
      .get(`/api/brokerages/${brokerageId}/settings`)
      .then((response) => dispatch(fetchByBrokerageSuccess(response, brokerageId)))
      .catch((errors) => dispatch({ type: FETCH_FOR_BROKERAGE_FAILED, errors }));
  };
}

/**
 * Update the persisted user setting.
 * @param  {String} key
 * @param  {String} value
 * @return {Function}
 */
export function update(key, value) {
  if (key === 'useTableView') {
    analytics.productSearch.layoutView(value ? 'table' : 'card');
  }

  return (dispatch) =>
    dispatch({
      type: UPDATE_SETTING,
      payload: {
        key,
        value,
      },
    });
}

/**
 * Add a setting by key
 * @param {Integer} brokerageId
 * @param {String} key
 * @return {Function}
 */
function addBrokerageSetting(brokerageId, key, value = 1) {
  return (dispatch, getState) => {
    const setting = getState().settings.byBrokerageId[brokerageId] || {};
    const payload = { brokerageId, key, setting, value };
    dispatch({ type: ADD_FOR_BROKERAGE, payload });
    return axios
      .post(`/api/brokerages/${brokerageId}/settings`, { key })
      .catch((errors) => dispatch({ type: ADD_FOR_BROKERAGE_FAILED, errors, payload }));
  };
}

/**
 * Remove a setting by key
 * @param {Integer} brokerageId
 * @param {String} key
 * @return {Function}
 */
function removeBrokerageSetting(brokerageId, key, value = 0) {
  return (dispatch, getState) => {
    const setting = getState().settings.byBrokerageId[brokerageId] || {};
    const payload = { brokerageId, key, setting, value };
    dispatch({ type: REMOVE_FOR_BROKERAGE, payload });
    return axios
      .delete(`/api/brokerages/${brokerageId}/settings`, { data: { key } })
      .catch((errors) => dispatch({ type: REMOVE_FOR_BROKERAGE_FAILED, errors, payload }));
  };
}

/**
 * Toggle a setting by key
 * @param {Integer} brokerageId
 * @param {String} key
 * @return {Function}
 */
export function toggleBrokerageSetting(brokerageId, key) {
  return (dispatch, getState) => {
    const settings = getState().settings.byBrokerageId[brokerageId] || {};
    const isActive = Object.keys(settings)
      .map((settingId) => settings[settingId])
      .reduce((accum, setting) => (setting.key === key && setting.value >= 1) || accum, false);

    return isActive
      ? removeBrokerageSetting(brokerageId, key)(dispatch, getState)
      : addBrokerageSetting(brokerageId, key)(dispatch, getState);
  };
}
