/**
 * Format a number/string as currency
 *
 * @param {Number|String} value
 * @return {String}
 */
export const formatCurrency = (currency) =>
  new Intl.NumberFormat('en-CA', {
    style: 'currency',
    currency: 'CAD',
  }).format(currency);

/**
 * Format a number/string as a percentage
 *
 * @param {Number|String} value
 * @param {Number} [minimumFractionDigits]
 * @param {Number} [maximumFractionDigits]
 * @return {String}
 */
export const formatPercentage = (value, minimumFractionDigits = 0, maximumFractionDigits = 2) =>
  new Intl.NumberFormat('en-CA', {
    style: 'percent',
    maximumFractionDigits,
    minimumFractionDigits,
  }).format(value / 100);

/**
 * Format phone number
 *
 * @param {String} phone
 * @param {String} [phoneExt]
 * @return {String}
 */
export const formatPhone = (phone, phoneExt) => {
  const formattedPhoneExt = phoneExt ? ` ext. ${phoneExt}` : '';
  return `${phone}${formattedPhoneExt}`;
};

/**
 * Format phone number for use in anchor href
 *
 * The `,` adds a 2 second pause and any numbers that follow will be dialed.
 * For more info, see https://www.lifewire.com/automatically-dialing-extensions-on-android-577619
 *
 * @param {String} phone
 * @param {String} [phoneExt]
 * @return {String}
 */
export const formatPhoneHref = (phone, phoneExt) => {
  const formattedPhoneExt = phoneExt ? `,${phoneExt}` : '';
  return `tel:${phone}${formattedPhoneExt}`;
};

/**
 * Normalize a phone number input by adding hyphens, restricting non-digit characters, and
 * limiting the length to 12 characters.
 *
 * @param {String} phone - The input string triggered by the user.
 * @return {String} The normalized phone number as a string.
 */
export const normalizePhone = (phone) =>
  phone
    .replace(/[^-\d]/g, '')
    .replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
    .substring(0, 12) || '';
