import React from 'react';
import FormFieldPropTypes from './FormFieldPropTypes';
import FormGroup from './FormGroup';
import FormInputError from './FormInputError';
import FormLabel from './FormLabel';

const FormTextareaWithGroup = (props) => {
  const { label, input, meta, showHelp, ...passProps } = props;

  return (
    <FormGroup error={meta.error} row>
      <FormLabel name={label} htmlFor={input.name} row showHelp={showHelp} />
      <div className="col-md-6">
        <textarea
          style={{ whiteSpace: 'pre' }}
          className={`form-control ${meta.error ? 'is-invalid' : ''}`}
          rows="5"
          {...input}
          {...passProps}
        />
        <FormInputError error={meta.error} />
      </div>
    </FormGroup>
  );
};

FormTextareaWithGroup.propTypes = {
  ...FormFieldPropTypes,
};

export default FormTextareaWithGroup;
