import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { noop } from 'libs';

const AsideLink = ({ icon, label, onClick = noop, testId = null, to }) => (
  <Link className="list-group-item list-group-item-action" onClick={onClick} data-testid={testId} to={to}>
    <span className="settings-link__icon d-inline-block w-6">{icon}</span>
    {label}
  </Link>
);

AsideLink.propTypes = {
  icon: PropTypes.element.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  testId: PropTypes.string,
  to: PropTypes.string.isRequired,
};

export default AsideLink;
