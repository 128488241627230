import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const Context = ({ children, className = null }) => (
  <div id="context" className={classnames('context', className)}>
    {children}
  </div>
);

/**
 * Prop Types
 * @type {Object}
 */
Context.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string,
};

export default Context;
