import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'components/common';

const SavedFilter = ({ onClick, onRemove, search }) => (
  <div className="saved-filter">
    <button
      className="saved-filter__name btn btn-link"
      data-search={search.search}
      data-testid={`saved-filter-apply-${search.name}`}
      onClick={onClick}
      type="button"
    >
      {search.name}
    </button>
    <button
      aria-label="delete"
      className="saved-filter__delete btn btn-sm btn-light"
      data-testid={`saved-filter-delete-${search.name}`}
      onClick={() => onRemove(search.id)}
      type="button"
    >
      <Icon name="trash-can" />
    </button>
  </div>
);

SavedFilter.propTypes = {
  onClick: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  search: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
};

export default SavedFilter;
