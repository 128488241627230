import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { noop, useUser } from 'libs';

const Picklist = ({
  isPro = false,
  keyPrefix,
  onChange,
  options,
  setRef = noop,
  size = 'default',
  twoColumn = false,
  value,
}) => {
  const user = useUser();
  const isSelected = (key) => (Array.isArray(value) ? value.includes(key) : key === value);

  const disabled = isPro && !user.features.is_pro;

  return (
    <div
      className={classnames('checkboxes', {
        'two-column': twoColumn,
        'mt-n3': size === 'lg',
      })}
    >
      {options.map((term) => (
        <div
          className={classnames('checkbox form-check', {
            'pt-3': size === 'lg',
          })}
          key={`${keyPrefix}-${term.key}`}
        >
          <label htmlFor={`term-${term.key}`} className="form-check-label">
            <input
              data-testid={`${keyPrefix}-${term.key}`}
              ref={setRef}
              type="checkbox"
              className="form-check-input"
              checked={isSelected(term.key)}
              id={`term-${term.key}`}
              name={`term-${term.key}`}
              value={term.key}
              onChange={onChange}
              disabled={disabled}
            />
            {term.value}
          </label>
        </div>
      ))}
    </div>
  );
};

Picklist.propTypes = {
  isPro: PropTypes.bool,
  keyPrefix: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    })
  ).isRequired,
  setRef: PropTypes.func,
  size: PropTypes.oneOf(['default', 'lg']),
  twoColumn: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
    PropTypes.string,
  ]).isRequired,
};

export default Picklist;
