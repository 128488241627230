import React from 'react';
import PropTypes from 'prop-types';

const NavbarCollapse = ({ children, open = false }) => {
  const isOpen = open ? 'open' : '';

  return (
    <div className={`navbar-collapse offcanvas-collapse ${isOpen}`} id="offcanvas">
      {children}
    </div>
  );
};

/**
 * PropTypes Validation
 * @type {Object}
 */
NavbarCollapse.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  open: PropTypes.bool,
};

export default NavbarCollapse;
