import React from 'react';
import PropTypes from 'prop-types';
import { analytics } from 'libs';
import Dropdown from './Dropdown';
import Filter from './Filter';

const options = [
  { key: 'all', value: 'All', filter: { rate_hold: null } },
  { key: '30', value: '30 Days', filter: { rate_hold: '30' } },
  { key: '45', value: '45 Days', filter: { rate_hold: '45' } },
  { key: '60', value: '60 Days', filter: { rate_hold: '60' } },
  { key: '90', value: '90 Days', filter: { rate_hold: '90' } },
  { key: '120', value: '120 Days', filter: { rate_hold: '120' } },
  { key: '180', value: '6 Months', filter: { rate_hold: '180' } },
  { key: '365', value: '12 Months', filter: { rate_hold: '365' } },
];

const RateHoldFilter = ({ filters, isPro = false, onFilter }) => {
  const onChange = (selected) => {
    analytics.productSearch.filter({
      rate_hold: selected.filter.rate_hold || 'all',
    });
    onFilter(selected.filter);
  };

  return (
    <Filter name="Rate Hold" isPro={isPro}>
      <Dropdown
        testId="filter-rate-hold"
        isPro={isPro}
        onChange={onChange}
        options={options}
        value={filters.rate_hold || 'all'}
      />
    </Filter>
  );
};

RateHoldFilter.propTypes = {
  filters: PropTypes.shape({
    rate_hold: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }).isRequired,
  isPro: PropTypes.bool,
  onFilter: PropTypes.func.isRequired,
};

export default RateHoldFilter;
