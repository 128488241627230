import React from 'react';
import { useLocation } from 'react-router-dom';
import { Nav, PhoneNumberBadge, SideNavItem } from 'components/navigation';
import {
  FileTrayFullIcon,
  FinmoLogoIcon,
  LandmarkArrowsIcon,
  LandmarkIcon,
  MicrochipAiIcon,
  QuestionCircleIcon,
  ScaleUnbalancedIcon,
  UserCircleIcon,
  WaveTriangleIcon,
} from 'icons';

const Sidebar = () => {
  const { pathname } = useLocation();
  const altLendingLocation = {
    pathname: '/alternative-lending',
    state: {
      hydratePrevState: true,
    },
  };

  return (
    <Nav className="side-navbar">
      <div className="side-navbar__links">
        <div className="side-navbar__navlinks">
          <SideNavItem
            isActive={pathname.startsWith('/rates')}
            icon={<WaveTriangleIcon />}
            label="Rates"
            name="rates"
            to="/rates"
          />
          <SideNavItem
            isActive={pathname.startsWith('/scenarios')}
            icon={<ScaleUnbalancedIcon />}
            label="Scenarios"
            name="scenarios"
            to="/scenarios/comparisons"
          />
          <SideNavItem
            isActive={pathname.startsWith('/lenders')}
            icon={<LandmarkIcon />}
            label="Lenders"
            name="lenders"
            to="/lenders"
          />
          <SideNavItem
            isActive={pathname.startsWith('/guidelines')}
            icon={<FileTrayFullIcon />}
            label="Policies"
            name="guidelines"
            to="/guidelines"
          />
          <SideNavItem
            isActive={pathname.startsWith('/alternative-lending')}
            icon={<LandmarkArrowsIcon />}
            label="Alternative Lending"
            name="alternative-lending"
            to={altLendingLocation}
          />
          <SideNavItem
            isActive={pathname.startsWith('/ai')}
            icon={<MicrochipAiIcon />}
            label="AI Assistant"
            name="ai"
            to="/ai"
          />
          <SideNavItem
            isExternalRoute
            icon={<QuestionCircleIcon />}
            label="Help Centre"
            name="help-centre"
            shouldOpenNewWindow
            to="https://support.lenderspotlight.ca"
          />
        </div>
        <div className="side-navbar__settings">
          <SideNavItem
            isExternalRoute
            icon={<FinmoLogoIcon />}
            label="Switch To Finmo"
            name="finmo"
            shouldOpenNewWindow
            to="https://app.finmo.ca"
          />
          <PhoneNumberBadge>
            <SideNavItem
              isActive={pathname.startsWith('/settings')}
              icon={<UserCircleIcon />}
              label="Settings"
              name="settings"
              to="/settings"
            />
          </PhoneNumberBadge>
        </div>
      </div>
    </Nav>
  );
};

export default Sidebar;
