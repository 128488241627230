import { createSelector } from 'reselect';

/**
 * Retrieves guidelines from state by ID.
 * @param {*} state
 * @return {Object}
 */
const getAllGuidelines = (state) => state.guidelines.byId;

/**
 * Get the current loading state.
 * @param {*} state
 * @return {Boolean}
 */
export const isLoading = (state) => state.guidelines.isLoading;

/**
 * Returns an array of guidelines.
 * @return {Array}
 */
/* eslint-disable no-param-reassign */
export const getGuidelinesByLenderIdAndPolicy = createSelector([getAllGuidelines], (guidelines) =>
  Object.values(guidelines).reduce((accum, guideline) => {
    if (!accum[guideline.lender_id]) accum[guideline.lender_id] = {};
    accum[guideline.lender_id][guideline.policy] = guideline;
    return accum;
  }, {})
);
