import * as Sentry from '@sentry/react';

export default Sentry.createReduxEnhancer({
  configureScopeWithState: (scope, { users }) => {
    scope.setUser({
      id: users.currentUser.id,
      username: users.currentUser.name,
      email: users.currentUser.email,
    });
  },
});
