import PropTypes from 'prop-types';
import { combineReducers } from 'redux';

const SET_FILTER = 'lenderspotlight/filters/SET_FILTER';

/**
 * Action Types
 * @type {Object}
 */
export const actionTypes = {
  SET_FILTER,
};

/**
 * Hydrate Initial State global LS object.
 * @type {Object}
 */
const initialState = {
  sort: 'rate',
};

/**
 * PropTypes Validation
 * @type {Function}
 */
export const propTypes = PropTypes.shape({
  setFilterOption: PropTypes.func,
});

/**
 * Filters Reducer
 * @param {Object} state
 * @param {Object} action
 * @return {Object}
 */
function filtersReducer(state = initialState, action) {
  if (action.type === SET_FILTER) {
    return {
      ...initialState,
      ...action.payload,
    };
  }

  return state;
}

/**
 * Export Lender Reducer
 * @type {Object}
 */
export default combineReducers({
  options: filtersReducer,
});

/**
 * Set the filter by value.
 * @param  {Object} filters
 * @return {Promise}
 */
export function setFilterOption(options) {
  return (dispatch) =>
    dispatch({
      type: SET_FILTER,
      payload: options,
    });
}
