/* eslint-disable camelcase */

/**
 * Displays the amortization for a product.
 * @param {Number} product.amortization
 * @return {String}
 */
const amortization = (product) => `${product.amortization} Years`;

/**
 * Displays the beacon range for a product.
 * @param {Number} product.beacon_max
 * @param {Number} product.beacon_min
 * @return {String}
 */
const beacon = ({ beacon_max, beacon_min }) => `${beacon_min}-${beacon_max}`;

/**
 * Displays the buy down for a product.
 * @param {Number} product.max_buydown
 * @return {String}
 */
const buyDown = ({ max_buydown }) => (max_buydown ? `${max_buydown} BPS` : '--');

/**
 * Displays the compensation for a product.
 * @param {Number} product.compensation.total
 * @return {String}
 */
const compensation = (product) => (product.compensation ? `${product.compensation.total} BPS` : '--');

/**
 * Displays the LTV range for a product.
 * @param {Number} product.ltv_max
 * @param {Number} product.ltv_min
 * @return {String}
 */
const ltv = ({ ltv_max, ltv_min }) => `${ltv_min}%-${ltv_max}%`;

/**
 * Displays the rate hold for a product.
 * @param {String} product.rate_hold
 * @return {String}
 */
const rateHold = ({ rate_hold }) => rate_hold;

/**
 * Displays the GDS/TDS for a product.
 * @param {Number} product.gds
 * @param {Number} product.tds
 * @return {String}
 */
const gdsTds = ({ gds, tds }) => [...(gds ? [`${gds}%`] : ['--']), ...(tds ? [`${tds}%`] : ['--'])].join('/');

/**
 * Displays the term and type for a product.
 * @param {String} product.term
 * @param {String} product.type
 * @return {String}
 */
const termType = ({ term, type }) => (term === 'HELOC' ? term : `${term} ${type}`);

/**
 * Displays the transaction type for a product.
 * @param {Boolean} product.purchase
 * @param {Boolean} product.refinance
 * @param {Boolean} product.transfer
 * @return {String}
 */
const transactionType = ({ purchase, refinance, transfer }) =>
  [...(purchase ? ['Purchase'] : []), ...(refinance ? ['Refinance'] : []), ...(transfer ? ['Transfer'] : [])].join(
    ', '
  );

export default {
  amortization,
  beacon,
  buyDown,
  compensation,
  ltv,
  rateHold,
  gdsTds,
  termType,
  transactionType,
};
