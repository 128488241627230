import { combineReducers } from 'redux';
import { normalize } from 'normalizr';
import schema from 'state/schema';
import { actionTypes as products } from './products';

/**
 * Action Types
 * @type {Object}
 */
export const actionTypes = {};

/**
 * Hydrate Initial State global LS object.
 * @type {Object}
 */
let initialState = {};

if (window.__INITIALSTATE__ && window.__INITIALSTATE__.regions) {
  const data = normalize(window.__INITIALSTATE__.regions, [schema.region]);
  initialState = data.entities.regions || {};
}

/**
 * ById Region Reducer
 * @param {Object} state
 * @param {Object} action
 * @return {Object}
 */
function byIdReducer(state = initialState, action) {
  switch (action.type) {
    case products.FETCH_MORE_SUCCESS:
    case products.FETCH_SUCCESS:
      return {
        ...state,
        ...action.payload.entities.regions,
      };

    default:
      return state;
  }
}

/**
 * Export Lender Reducer
 * @type {Object}
 */
export default combineReducers({
  byId: byIdReducer,
});
