import PropTypes from 'prop-types';
import { combineReducers } from 'redux';
import { normalize } from 'normalizr';
import uniq from 'lodash/uniq';
import { guidelines } from 'libs';
import schema from 'state/schema';

const FETCH = 'lenderspotlight/guidelines/FETCH';
const FETCH_FAILED = 'lenderspotlight/guidelines/FETCH_FAILED';
const FETCH_SUCCESS = 'lenderspotlight/guidelines/FETCH_SUCCESS';

export const propTypes = PropTypes.shape({
  fetchByComparedLenders: PropTypes.func,
});

/**
 * Guidelines action types
 */
export const actionTypes = {
  FETCH,
  FETCH_FAILED,
  FETCH_SUCCESS,
};

/**
 * Guidelines By ID reducer.
 * @param {*} state
 * @param {*} action
 */
function byIdReducer(state = {}, action) {
  if (action.type === FETCH_SUCCESS) {
    return action.payload.entities.policies;
  }
  return state;
}

/**
 * Guidelines isLoading reducer.
 * @param {Boolean} [state=false]
 * @param {*} action
 */
function isLoadingReducer(state = false, action) {
  switch (action.type) {
    case FETCH:
      return true;
    case FETCH_FAILED:
    case FETCH_SUCCESS:
      return false;
    default:
      return state;
  }
}

/**
 * Guidelines Reducer.
 */
export default combineReducers({
  byId: byIdReducer,
  isLoading: isLoadingReducer,
});

/**
 * Fetch guidelines success action.
 * @param {*} response
 */
function fetchSuccess(response) {
  const payload = normalize(response, [schema.policy]);
  return { type: FETCH_SUCCESS, payload };
}

/**
 * Returns an array of unique lender names from a collection of rates.
 * @param {Array} rates
 * @return {String[]}
 */
function getLenderNames(rates) {
  return uniq(rates.map((rate) => rate.lender.name));
}

/**
 * Fetch guidelines by lenders thunk.
 * @param {Array} policies
 */
export function fetchByComparedLenders(policies) {
  return (dispatch, getState) => {
    const policyNames = policies.map((policy) => policy.name);
    dispatch({ type: FETCH });
    return axios
      .get('/api/products', { params: { id: getState().products.compared } })
      .then((rates) => getLenderNames(rates.data))
      .then((lenderNames) => guidelines.filterByLendersAndPolicies(lenderNames, policyNames))
      .then((response) => dispatch(fetchSuccess(response)))
      .catch((error) => dispatch({ type: FETCH_FAILED, error }));
  };
}
