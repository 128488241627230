import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import FormFieldPropTypes from './FormFieldPropTypes';
import FormGroup from './FormGroup';
import FormInput from './FormInput';
import FormInputError from './FormInputError';
import FormLabel from './FormLabel';

const FormInputWithGroup = ({ label, meta, type, variant = 'row', ...props }) => (
  <FormGroup row={variant === 'row'}>
    <FormLabel name={label} htmlFor={props.input.name} row={variant === 'row'} />
    <div className={classnames({ 'col-md-6': variant === 'row' })}>
      <FormInput meta={meta} type={type} {...props} />
      <FormInputError error={meta.error} />
    </div>
  </FormGroup>
);

FormInputWithGroup.propTypes = {
  ...FormFieldPropTypes,
  type: PropTypes.string,
  variant: PropTypes.oneOf(['column', 'row']),
};

export default FormInputWithGroup;
