import React from 'react';
import PropTypes from 'prop-types';

const ContentBody = React.forwardRef(({ children = undefined, className = '' }, ref) => (
  <div ref={ref} className={`content__body ${className}`} data-testid="content-body">
    {children}
  </div>
));

/**
 * PropTypes Validation
 * @type {Object}
 */
ContentBody.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  className: PropTypes.string,
};

export default ContentBody;
