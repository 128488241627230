import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { noop } from 'libs';

const Upgrade = ({ buttonText = 'Upgrade', className = null, onClick = noop }) => (
  <Link to="/settings/subscriptions" className={className} onClick={onClick}>
    {buttonText}
  </Link>
);

Upgrade.propTypes = {
  buttonText: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default Upgrade;
