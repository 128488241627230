import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const Tag = ({ children, variant = 'default', size = 'default', testId }) => (
  <div
    className={classnames(
      'd-flex align-items-center border border-light-button-bg rounded bg-light-button-bg text-light-button-text',
      {
        'fw-bold': variant === 'bold',
      },
      {
        'py-0_5 px-2': size === 'sm',
        'py-1_5 px-2_5': size === 'default',
      }
    )}
    data-testid={testId}
  >
    <span>{children}</span>
  </div>
);

Tag.propTypes = {
  children: PropTypes.node,
  variant: PropTypes.oneOf(['bold', 'default']),
  size: PropTypes.oneOf(['sm', 'default']),
  testId: PropTypes.string,
};

export default Tag;
