import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import { Icon } from 'components/common';

const LayoutButtonGroup = ({ layout, onLayoutChange }) => (
  <ToggleButtonGroup
    className="btn-group-toggle--border"
    name="view"
    onChange={onLayoutChange}
    type="radio"
    value={layout}
  >
    <ToggleButton
      data-testid="common-layout-button-group-btn-cards"
      id="layout-group-cards"
      value="cards"
      variant={classnames('group-toggle--border__btn', { active: layout === 'cards' })}
    >
      <span className={classnames({ 'text-primary': layout === 'cards' })}>
        <Icon className="align-middle text-1" name="table-cells" />
      </span>
      <span className="align-middle ms-2_5">Cards</span>
    </ToggleButton>
    <ToggleButton
      data-testid="common-layout-button-group-btn-table"
      id="layout-group-table"
      value="table"
      variant={classnames('group-toggle--border__btn', { active: layout === 'table' })}
    >
      <span className={classnames({ 'text-primary': layout === 'table' })}>
        <Icon className="align-middle text-1" name="bars" />
      </span>
      <span className="align-middle ms-2_5">Table</span>
    </ToggleButton>
  </ToggleButtonGroup>
);

LayoutButtonGroup.propTypes = {
  layout: PropTypes.oneOf(['cards', 'table']).isRequired,
  onLayoutChange: PropTypes.func.isRequired,
};

export default LayoutButtonGroup;
