import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import numeral from 'numeral';
import { analytics } from 'libs';
import Filter from './Filter';
import Text from './Text';

const AmortizationFilter = ({ filters, onFilter, placeholder = 'Enter amortization amount' }) => {
  const [value, setValue] = useState(filters.amortization);

  /**
   * The first render will occur before the initial filter state is ready. If
   * the filter state was ready on first render, the state sync below would
   * be unnecessary. To remove this, we would need the parent component to
   * immediately pass down the actual initial filter state.
   */
  useEffect(() => {
    setValue(filters.amortization);
  }, [filters.amortization, setValue]);

  const onDebouncedFilter = useMemo(
    () =>
      debounce((newFilters) => {
        onFilter(newFilters);
      }, 400),
    [onFilter]
  );

  const onChange = (event) => {
    event.preventDefault();

    const newValue = numeral(event.target.value).value();

    analytics.productSearch.filterDebounced({
      amortization: newValue || 'cleared',
    });

    setValue(newValue);
    onDebouncedFilter({ amortization: newValue });
  };

  return (
    <Filter name="Maximum Amortization">
      <Text
        testId="filter-amortization"
        onChange={onChange}
        placeholder={placeholder}
        value={value ? numeral(value).format() : ''}
      />
    </Filter>
  );
};

AmortizationFilter.propTypes = {
  filters: PropTypes.shape({
    amortization: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }).isRequired,
  onFilter: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

export default AmortizationFilter;
